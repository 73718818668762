const normalizeKeyMap : any = {
  'Esc': 'Escape',
  'Left': 'ArrowLeft',
  'Right': 'ArrowRight',
  'Up': 'ArrowUp',
  'Down': 'ArrowDown',
  'Space': ' ',
  'Spacebar': ' '
}

/**
 * Normalizes key values to standard strings. e.g. "Down" to "ArrowDown"
 * @param keyEvent
 * @return {*}
 */
function normalizeKey(keyEvent : KeyboardEvent) {
  let key = keyEvent.key || keyEvent.code;
  if (key) {
    key = normalizeKeyMap[key] || key;
  }
  return key;
}

/**
 * Determines whether or not a key event is the user typing.
 * Returns true if the key length is 1 and the ctrl and meta keys are not pressed
 * @param keyEvent
 */
function isTyping(keyEvent: KeyboardEvent) {
  let key = keyEvent.key || keyEvent.code || '';
  return key.length === 1 && !keyEvent.ctrlKey && !keyEvent.metaKey && !keyEvent.altKey;
}

/**
 * Determines whether or not a key event is the user typing.
 * Returns true if the key length is 1 and the ctrl and meta keys are not pressed
 * @param keyEvent
 */
function isTypingOrDeleting(keyEvent: KeyboardEvent) {
  const key = normalizeKey(keyEvent);
  return key === 'Delete' || key === 'Backspace' || isTyping(keyEvent);
}

export {
  normalizeKey,
  isTyping,
  isTypingOrDeleting
}
