import { TdsFile } from './TdsFile';
/**
 * must have the following states:
 * -none (no messaging) 
 * -general success (no messaging)
 * -general error (upload failed messaging)
 * -file size error (specific messaging)
 * -file type error (specific messaging)
 * -uploading (no messaging)
 */


export const statusCodes = {
  ready: 'uploadReady',
  sizeError: 'sizeError',
  typeError: 'typeError',
  error: 'uploadError',
  success: 'success',
  uploading: 'uploading',
  maxError: 'maxError',
  duplicateError: 'duplicateError',
}


//pass in size and unit as a single string, use regex to get size and unit
//returns size from string containing size and unit
export function getSizeFromString(str: string) {
  const regex = /[0-9]/g;
  const size = str.match(regex).join("");
  return parseInt(size);
}

//gets the unit (EX: MB) from a string containing size and unit
//If string does not contain unit, or unit is not B, KB, MB, or GB, then default to MB
export function getUnitFromString(str: string) {
  const regex = /[A-Z]/ig;
  const charArray = str && str.match(regex);
  const size = !!charArray ? charArray.join("").toUpperCase() : '';
  const units = ['B', 'KB', 'MB', 'GB'];
  return units.includes(size) ? size : "MB";
}

//gets the unit from a size converted on bytes
export function getUnitFromSize(bytes: number) {
  if (bytes === 0) return 'B';
  const units = ['B', 'KB', 'MB', 'GB'];
  return units[Math.floor(Math.log(bytes) / Math.log(1024))];
}

export function validateFile(tdsFile: TdsFile, maxSize?: string, type?: string[], reject: boolean = false): string {
  var resultStatus = statusCodes.ready;
  if (!!maxSize) {
    const size = getSizeFromString(maxSize);
    const unit = getUnitFromString(maxSize);
    resultStatus = checkFileSize(tdsFile.file, size, unit);
  }

  if (!!type) {
    const typeResult = checkFileType(tdsFile.file, type, reject);
    resultStatus = typeResult === statusCodes.ready ? resultStatus : typeResult;
  }

  return resultStatus;
}

export function checkFileSize(file: File, maxSize: number, sizeUnit: string): string {
  const sizeInBytes = getSizeInBytes(maxSize, sizeUnit);
  if (file.size >= sizeInBytes) {
    return statusCodes.sizeError;
  }
  else {
    return statusCodes.ready;
  }
}

export function getSizeInBytes(size: number, unit: string = 'B'): number {
  const unitStr = unit.toUpperCase();
  const units = ['B', 'KB', 'MB', 'GB'];
  const i = units.indexOf(unitStr);
  return size * Math.pow(1024, i);
}

export function convertFromBytes(size: number, unit: string): string {
  const unitStr = unit.toUpperCase();
  const units = ['B', 'KB', 'MB', 'GB'];
  const i = units.indexOf(unitStr);
  const fileSize = size / Math.pow(1024, i);
  return fileSize.toFixed((i == 0) ? 0 : 2);
}

export function checkFileType(file: File, types: string[], reject: boolean): string {
  var matchesTypes = false;
  //file formats can come in the format .ext or type/ext
  //for example png files could be represented as .png or image/png
  for (let i = 0; i < types.length; i++) {
    const type: string = types[i].trim();
    //if file type is in .ext format
    if (type.startsWith(".")) {
      //to catch the case where there is multiple dots, take text after last dot
      const tempArrA = type.split('.');
      const tempArrB = file.name.split(".");
      const fileExtA = tempArrA[tempArrA.length - 1];
      const fileExtB = tempArrB[tempArrB.length - 1];
      matchesTypes = matchesTypes || (fileExtA.toLowerCase() === fileExtB.toLowerCase());
    }
    //follows 'type/ext' pattern, no preprocessing needed just evaluate strings 
    else {
      const regex: RegExp = /.+?(?=\*)/i;
      const matchedArray: RegExpMatchArray = type.match(regex);
      //if type contains *, match everything up until *
      if (type.search(/\*/g) != -1) {
        const strToCheck = !!matchedArray ? matchedArray.join() : '';
        const equalsType: boolean = file.type.toLowerCase().startsWith(strToCheck.toLowerCase());
        matchesTypes = matchesTypes || equalsType;
      }
      else {
        matchesTypes = matchesTypes || (type.toLowerCase() === file.type.toLowerCase());
      }
    }
  }

  return (matchesTypes && !reject) || (!matchesTypes && reject) ? statusCodes.ready : statusCodes.typeError;
}

export function ariaLiveAttributes(ariaLive: string) {
  let attrs = {};
  switch (ariaLive) {
    case "polite":
      attrs = { "role": "status", "aria-live": "polite", "aria-atomic": "true" };
      break;
    case "assertive":
      attrs = { "role": "alert", "aria-live": "assertive", "aria-atomic": "true" };
      break;
  }
  return attrs;
}

export function setAriaLive(el: HTMLElement, ariaLive: string) {
  const attrs: any = ariaLiveAttributes(ariaLive);
  Object.keys(attrs).forEach((attr) => {
    el.setAttribute(attr, attrs[attr]);
  });
}
