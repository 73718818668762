import { store, Store } from "../../utilities/store";

export interface AlertState {
  hidden: boolean,
  transitioning: boolean
}

export function createStore() : Store<AlertState> {
  return store({
    hidden: false,
    transitioning: false
  })
}
