/**
 * ***********************************************************************************
 * ***********************************************************************************
 * NOTE: This is copied directly from legacy. When implemeneted as a web component, 
 * consider whether or not some of the logic needs to be split out into a 
 * resusable 'behavior' module.
 * ***********************************************************************************
 * ***********************************************************************************
 * Looks for instances of tds-data-table-scrollpanel with [data-scroll-shadow-effect="true"] to manage the shadow effect
 * For each scroll panel:
 * - Wraps panel in tds--scrollpanel-shadow-wrapper element if needed
 * - Observes changes to the scrollpanel using MutationObserver
 * - on window.resize events and any observed changes to scrollpanel, positions the shadow
 *
 * The shadow wrapper is position:relative. It contains a tds-scrollpanel-shadow element which is
 * positioned absolute. The shadow uses a before pseudo-element, also absolutely positioned, to create
 * the shadow effect. tds-scrollpanel-shadow has 2 modifiers: tds-scrollpanel-shadow--x to show
 * left and right shadows, and tds-scrollpanel-shadow--y, to show the top/bottom shadows
 *
 * The shadow effect is shown only when there are not scrollbars, and their is overflow.
 *
 * To determine if scrollbars exists, the scroll panel's clientWidth is compared to its offsetWidth.
 * If they are the same, then there are no scrollbars
 *
 * The tds-scrollpanel-shadow--x classe is added if there is horizontal overflow; otherwise it is removed
 * The tds-scrollpanel-shadow--y classe is added if there is vertical overflow; otherwise it is removed
 *
 * The last step is to see if the scrollpanel wraps a table with sticky rows or columns. If it does
 * it adjusts the top, bottom, left, and right values accordingly so the shadow aligns with the sticky elements
 *
 * For all this to work as intended
 * - The scroll panel cannot have any padding or margins. Shadow placement does not account for this
 * - For tables with sticky headers or footers, either must have just one row
 * - For tables with sticky columns, header columns counts must be the same as the column count for rows.
 *
 * To disable this feature, remove the import line from ../data-table.js. Or, remove
 * [data-scroll-shadow-effect="true"]
 *
 */
import onDOMChanges from '../../utilities/onDOMChanges';
import { instances } from '../../utilities/instances';
import { CSS_NS, NAMESPACE } from '../../utilities/constants';
import debounce from '../../utilities/debounce';
import {positionStickySupported, pointerEventsSupported} from '../../utilities/helpers';

const INSTANCE_KEY = `${NAMESPACE}ScrollpanelShadow`;
// const REMOVED_EVENT = 'dataTableScrollpanel:removed';
const SCROLLPANEL_CLASSNAME = `${CSS_NS}data-table-scrollpanel`;
const SHADOW_WRAPPER_CLASSNAME = `${CSS_NS}scrollpanel-shadow-wrapper`;
const SHADOW_EFFECT_CLASSNAME = `${CSS_NS}scrollpanel-shadow`;

const instanceList: ScrollpanelShadow[] = [];
const onResize: any = debounce(() => {
  instanceList.forEach(instance => instance.setShadowEffect());
}, 200);

class ScrollpanelShadow {
  _private: any;

  constructor(scrollpanel: HTMLElement) {
    this._private = {
      scrollpanel,
      shadowX: false,
      shadowY: false
    };

    if (!pointerEventsSupported()) {
      // shadow effect blocks pointer event unless pointer-events: none
      // If not supported, don't enable
      return;
    }

    if (!instanceList.length) {
      window.addEventListener('resize', onResize);
    }
    instanceList.push(this);
    // ensure shadow wrapper exists, before calling instances.set()
    getShadowEffectElements(scrollpanel);
    this.setShadowEffect();
    watch(this);

    // must be called after shadow wrapper is created because repositioning table element causes a 'destroy' event
    instances.set(scrollpanel, INSTANCE_KEY, this);
  }

  setShadowEffect() {
    if (this._private) {
      const { scrollpanel } = this._private;
      const offsets = getShadowOffsets(scrollpanel);
      positionShadowEffect(scrollpanel, offsets);
    }
  }

  destroy() {
    const { scrollpanel, observer } = this._private;
    delete this._private;
    const ix = instanceList.indexOf(this);
    if (ix > -1) {
      instanceList.splice(ix, 1);
      if (!instanceList.length) {
        window.removeEventListener('resize', onResize);
      }
    }
    if (observer) {
      observer.disconnect();
    }
    instances.remove(scrollpanel, INSTANCE_KEY);
  }

  _checkDestroy(element: HTMLElement) {
    if (element === this._private.scrollpanel) {
      this.destroy()
    }
  }

}

function getShadowOffsets(scrollpanel: HTMLElement) : { x: boolean, y: boolean} {
  let x = false, y = false;
  const style = window.getComputedStyle(scrollpanel);
  const borderLeft = style.borderLeftWidth ? parseInt(style.borderLeftWidth) : 0;
  const borderRight = style.borderRightWidth ? parseInt(style.borderRightWidth) : 0;
  const borderTop = style.borderTopWidth ? parseInt(style.borderTopWidth) : 0;
  const borderBottom = style.borderBottomWidth ? parseInt(style.borderBottomWidth) : 0;
  const offsetWidth = scrollpanel.offsetWidth - borderLeft - borderRight;
  const offsetHeight = scrollpanel.offsetHeight - borderTop - borderBottom;

  // only create the shadow effect if no scrollbars are showing but there is overflow
  if (scrollpanel.clientWidth === offsetWidth && scrollpanel.clientHeight === offsetHeight) {
    x = (scrollpanel.clientWidth < scrollpanel.scrollWidth);
    y = (scrollpanel.clientHeight < scrollpanel.scrollHeight);
  }
  return { x, y }
}

function positionShadowEffect(scrollpanel: HTMLElement, offsets: any) {
  const {x , y} = offsets;
  const effectElements = getShadowEffectElements(scrollpanel);
  effectElements[0].classList[x ? 'add' : 'remove'](`${SHADOW_EFFECT_CLASSNAME}--x`);
  effectElements[1].classList[y ? 'add' : 'remove'](`${SHADOW_EFFECT_CLASSNAME}--y`);
}

function getShadowEffectElements(scrollpanel: HTMLElement) : HTMLElement[] {
  const table = scrollpanel.querySelector('table');
  const shadowEffectsNeeded = table && table.className.indexOf('--sticky') > -1 ? 2 : 1;

  let wrapper = scrollpanel.parentElement;
  if (!wrapper.matches(`.${SHADOW_WRAPPER_CLASSNAME}`)) {
    wrapper = document.createElement('div');
    wrapper.classList.add(SHADOW_WRAPPER_CLASSNAME);
    scrollpanel.parentNode.insertBefore(wrapper, scrollpanel);
    wrapper.appendChild(scrollpanel);
  }

  while (wrapper.querySelectorAll(`.${SHADOW_EFFECT_CLASSNAME}`).length < shadowEffectsNeeded) {
    wrapper.insertAdjacentHTML('afterbegin', `<div class="${SHADOW_EFFECT_CLASSNAME}" aria-hidden="true"></div>`);
  }

  const effectElements: HTMLElement[] = Array.from(wrapper.querySelectorAll(`.${SHADOW_EFFECT_CLASSNAME}`));
  if (effectElements.length === 1) {
    effectElements[1] = effectElements[0];
  }

  if (positionStickySupported()) {
    positionForStickyTable(scrollpanel, effectElements);
  }
  return effectElements;
}

function positionForStickyTable(scrollpanel: HTMLElement, shadowEffectElements: HTMLElement[]) {
  let wrapper = scrollpanel.parentElement;
  const table = scrollpanel.querySelector('table');
  let top = 0, bottom = 0, left = 0, right = 0;
  if (table) {
    const className = table.className || '';
    const styleX = shadowEffectElements[0].style;
    const styleY = shadowEffectElements[1].style;
    const currentLeft = styleX.left ? parseInt(styleX.left) : 0;
    const currentRight = styleX.right ? parseInt(styleX.right) : 0;
    const currentTop = styleY.top ? parseInt(styleY.top) : 0;
    const currentBottom = styleY.bottom ? parseInt(styleY.bottom) : 0;


    if (className.indexOf('--sticky-header') > -1) {
      const tbody1: HTMLElement = table.querySelector('tbody:first-of-type');
      top = (tbody1 && tbody1.offsetTop) || 0
    }
    if (className.indexOf('--sticky-footer') > -1) {
      const tbody2: HTMLElement = table.querySelector('tbody:last-of-type');
      const tbodyBottom = tbody2 && (tbody2.offsetTop + tbody2.offsetHeight) || 0;
      bottom = table.clientHeight - tbodyBottom;
    }
    if (className.indexOf('--sticky-firstcol') > -1) {
      const firstCell: HTMLElement = table.querySelector('th:first-child,td:first-child');
      left = (firstCell && firstCell.offsetWidth);
    }
    if (className.indexOf('--sticky-lastcol') > -1) {
      const lastCell: HTMLElement = table.querySelector('th:last-child,td:last-child');
      right = (lastCell && lastCell.offsetWidth);
    }

    if (top !== currentTop) {
      styleY.top = `${top}px`;
    }

    if (bottom !== currentBottom) {
      styleY.bottom = `${bottom}px`;
    }
    if (left !== currentLeft) {
      styleX.left = `${left}px`;
    }
    if (right !== currentRight) {
      styleX.right = `${right}px`;
    }

    // THIS IS FIX FOR WHEN SCREEN IS WIDER THAN THE TABLE WIDTH
    // THIS WILL ADJUST THE WIDTH OF THE WRAPPER AND THE
    // SHADOW EFFECT TO BE EQUAL TO THE TABLE WIDTH
    wrapper.style.maxWidth = table.offsetWidth + "px";
    scrollpanel.style.maxWidth = table.offsetWidth + "px";
  }
}

function watch(scrollpanelShadow: ScrollpanelShadow) {
  const { scrollpanel } = scrollpanelShadow._private;
  const observer = scrollpanelShadow._private.observer =
    new MutationObserver(scrollpanelShadow.setShadowEffect.bind(scrollpanelShadow));
  observer.observe(scrollpanel, { attributes: true, characterData: true, childList: true, subtree: true });
}

onDOMChanges(`.${SCROLLPANEL_CLASSNAME}[data-scroll-shadow-effect="true"]`,
  function onDataTableAdded(scrollpanel: HTMLElement) {
    new ScrollpanelShadow(scrollpanel)
  },
  function onDataTableRemoved(element: HTMLElement) {
    instanceList.forEach(instance => instance._checkDestroy(element));
  });