/**
 * Returns a function, that, as long as it continues to be invoked, will not
 * be triggered. The function will be called after it stops being called for
 * the number of milliseconds specified. If `immediate` is passed, trigger the function on the
 * leading edge, instead of the trailing.
 * (inspired by https://davidwalsh.name/javascript-debounce-function)
 * @param {*} fn The function to call
 * @param {*} delay The number of milliseconds to wait. Defaults to 300
 * @param {*} immediate If true, performs the function on the leading edge of the timeout
 */
const debounce = (fn: Function, delay = 300, immediate: boolean = false) => {
	let timeout: any;
	return function () {
		const context: any = this;
		const args = arguments;
		const callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			timeout = null;
			if (!immediate) fn.apply(context, args);
		}, delay);
		if (callNow) fn.apply(context, args);
	}
}

export default debounce;
