import { store, Store } from "../../utilities/store";

export type DateInputValidity = {
  valid: boolean,
  badInput?: boolean,
  rangeUnderflow?: boolean,
  rangeOverflow?: boolean,
  unavailable?: boolean,
  valueMissing?: boolean,
}

export interface DateInputState {
  monthValue: string,
  dayValue: string,
  yearValue: string,
  validity: DateInputValidity,
  calendarOpenState: 'ready-open' | 'opening' | 'opened' | 'closing' | 'closed'
}

export function createStore(el: HTMLElement): Store<DateInputState> {
  //todo: also check for value= on DateInput
  let monthValue = '', dayValue = '', yearValue = '';
  const inputs = el.querySelectorAll('input[data-date-part]');
  for (let i = 0; i < inputs.length; i++) {
    const input = <HTMLInputElement>inputs[i];
    const value = input.value;
    switch (input.dataset.datePart) {
      case "year":
        yearValue = value;
        break;
      case "month":
        monthValue = value;
        break;
      case "day":
        dayValue = value;
        break;
    }
  }

  if (!yearValue && !monthValue && !dayValue) {
    // see if it it initialized using a hidden element
    const hiddenInput = el.querySelector<HTMLInputElement>('input[type="hidden"]');
    if (hiddenInput && hiddenInput.value) {
      // strip anything that is not a number or dash 
      let value = hiddenInput.value.replace(/[^\d-]/g, '');
      const parts = value.split('-');
      if (parts.length === 3) { // then its in an ISO-like format
        yearValue = parts[0];
        monthValue = parts[1];
        dayValue = parts[2];
      }
    }
  }

  return store({
    yearValue,
    monthValue,
    dayValue,
    validity: { valid: true },
    calendarOpenState: 'closed'
  })
}
