import onDomChanges from '../onDOMChanges';
import getTargetElement from '../getTargetElement';
import { bindClickable, ClickableConfig } from './clickableBehavior';
import { configFromDataAttributes } from '../helpers';


const CLICKABLE_ENHANCED_FLAG = 'enhancedClickable';
const CLICKABLE_TARGET_ATTRIBUTE = 'data-clickable';
const CLICKABLE_SELECTOR = `[${CLICKABLE_TARGET_ATTRIBUTE}]`;

class Clickable {
  container: HTMLElement;
  clickElement: HTMLElement;
  config: ClickableConfig;
  unbind: Function;
  
  constructor(el: HTMLElement, clickableEl?: HTMLElement | string) {
    this.container = el;
    el.dataset[CLICKABLE_ENHANCED_FLAG] = "true";
    if (!!clickableEl) {
      const isString = typeof(clickableEl) === 'string';
      this.clickElement = isString ? this.container.querySelector(clickableEl as string) : clickableEl as HTMLElement;
    }
    else {
      this.clickElement = getTargetElement(this.container, CLICKABLE_TARGET_ATTRIBUTE);
    }
    this.config = configFromDataAttributes(el);
    this.unbind = bindClickable(this.container, this.clickElement, this.config);
  }

  destroy() {
    if (this.unbind) {
      this.unbind();
      delete this.container.dataset[CLICKABLE_ENHANCED_FLAG];
      this.container = this.clickElement = this.unbind = undefined;
    }
  }
}


onDomChanges(CLICKABLE_SELECTOR,
  function onClickableAdded(el: HTMLElement) {
    if (el.dataset[CLICKABLE_ENHANCED_FLAG] !== 'true') {
      const clickable = new Clickable(el);
      (el as any).destroyClickable = clickable.destroy.bind(clickable);
    }
  },

  function onClickableDeleted(el: HTMLElement) {
    if (el.dataset[CLICKABLE_ENHANCED_FLAG] === 'true' && typeof (el as any).destroyClickable === 'function') {
      (el as any).destroyClickable();
      delete (el as any).destroyClickable;
    }
  }
);

export { Clickable };