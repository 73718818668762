import { AlertComponent } from './AlertComponent';
import { EventListeners } from '../../utilities/EventListeners';
import { onTransitionEnd } from '../../utilities/animations';
import { normalizeKey } from '../../utilities/keyboard';
import { CSS_NS } from '../../utilities/constants'
import { getLocale } from '../../utilities/localization';


export const DISMISS_CLASS = `${CSS_NS}alert__dismiss`
export const DISMISS_DATA_ATTR = `[data-toggle="alert"]`

export function bindAlert(alert: AlertComponent) : Function {
  const { root } = alert;

  const eventListeners = new EventListeners();
  eventListeners.addListener(root, 'click', clickHandler(alert));
  eventListeners.addListener(root, 'keydown', onEscape(alert));

  const unbind = () => {
    eventListeners.removeListeners();
  }
  return unbind;
}

export function close(alert: AlertComponent, val: string) {
  const { root, host, store } = alert;
  const button: HTMLElement = root.querySelector(`.${DISMISS_CLASS}`) || root.querySelector(DISMISS_DATA_ATTR);
  const x = window.pageXOffset;
  const y = window.pageYOffset;
  const focusElm: HTMLElement = button && document.querySelector(button.dataset.focus);
  
  if (alert.dispatchEvent('alert.dismissed', { cancelable: true, detail: val })) {
    onTransitionEnd(host, () => {
      store.update(v => {
        return {...v, hidden: true, transitioning: false};
      });  
      host.style.height = '';
      
      // the second event is fired to inform the animation is complete after dissmissal button is closed
      alert.dispatchEvent('alert.dismissComplete', { cancelable: false, detail: val });
    })
    
    host.style.height = `${host.clientHeight}px`;
    window.setTimeout(() => {
      store.update(v => {
        return {...v, hidden: true, transitioning: true};
      });  
    }, 50);
    // manage focus for keyboard users
    if (focusElm && focusElm.focus) {
      focusElm.focus();
    }
    // manage scrolling for screen magnifiers
    window.scroll(x, y);
  }
}

export function getLang(alert: AlertComponent) {
  const locale = getLocale(alert.host);
  return locale && locale.lang;
}

function clickHandler(alert: AlertComponent) {
  return (e: Event) => {
    const target: HTMLElement = e.target as HTMLElement;
    const button: HTMLElement = target.closest(`.${DISMISS_CLASS}, ${DISMISS_DATA_ATTR}`);
    if (button) {
      close(alert, (<HTMLButtonElement> button).value);
    }
  }
}

function onEscape(alert: AlertComponent) {
  return (e: KeyboardEvent) => {
    if (normalizeKey(e) === 'Escape') {
      close(alert, "escape");
    }
  };
}