import { AbstractCellEditor } from "./AbstractCellEditor";
import { CellEditorComponent, CellEditorRenderData } from "./CellEditorComponent";

export class TextAreaCellEditor extends AbstractCellEditor implements CellEditorComponent {
  render({cell, cellValue, initialValue, action, container, columnHeader, validators}: CellEditorRenderData): void {
    const textarea = this.editorElement = cell.ownerDocument.createElement('textarea') as HTMLTextAreaElement;
    textarea.setAttribute('aria-label', columnHeader);
    container.appendChild(textarea);
    textarea.value = typeof initialValue === 'undefined' ? cellValue : action === 'typing' ? '' : initialValue;
    textarea.addEventListener('input', this.reportUpdate);
    if (validators) {
      validators.forEach(validator => {
        const options = validator.options;
        switch (validator.type) {
          case 'required':
            textarea.required = true;
            break;          
          case 'length':
            configureLength(textarea, options);
            break;
        }
      })
    }
}
  
  getValue() {
    const { editorElement: editorElement } = this;
    return editorElement ? (editorElement as HTMLTextAreaElement).value : ''; 
  }

  setValue(value: any = '') {
    const editorElement = this.editorElement as HTMLTextAreaElement;
    if (editorElement) {
      editorElement.value = value.toString();
    }
  }
}

// DRY opportunity: Copied from TextInputCellEditor
function configureLength(textarea: HTMLTextAreaElement, options: any) {
  const { max, min, restrict, noconfigure } = options;
  if (!noconfigure) {
    if (typeof max !== 'undefined' && restrict) {
      textarea.maxLength = max;
    }
    if (typeof min !== 'undefined') {
      textarea.minLength = min;
    }
  }
}
