import onDOMChanges from '../../utilities/onDOMChanges';
import { bindDataTable, sortTable } from './dataTableBehavior';
import { DataTableComponent } from './DataTableComponent';
import { ROW_EXPANDER_DATA_ATTR, EXPANSION_ROW_DATA_ATTR } from './dataTableConstants';
import { instances } from '../../utilities/instances';
import { createCustomEvent } from "../../utilities/customEvent";
import { configFromDataAttributes, convertStringRefs, getLabelledByLabel } from "../../utilities/helpers";
import { translations, getLang } from '../../utilities/i18n';
import { CSS_NS, NAMESPACE } from '../../utilities/constants';
import { ToggleIcon } from '../../utilities/toggle-icon/ToggleIcon';
import './scrollpanelShadow';

const ENHANCED_FLAG = 'enhancedDataTable';
const ENHANCED_DATA_ATTR = 'data-enhanced-data-table';
const INSTANCE_KEY = `${NAMESPACE}DataTable`;
const DTABLE_CLASSNAME = `${CSS_NS}data-table`;
const ROW_SELECTED_CLASS = `${DTABLE_CLASSNAME}__row--selected`;
const COLUMN_SORT_BUTTON_CLASS = `${DTABLE_CLASSNAME}__sort-col-button`
const ROW_EXPANDER_TOGGLE_ICON_CLASS = `${DTABLE_CLASSNAME}__row-expander-toggle-icon`
const ID_PARSER_RX = /\b\S+\b/g

const defaultConfig = {
  announceSortState: true,
  rowSelectedClass: ROW_SELECTED_CLASS
}

class _DataTableInstance implements DataTableComponent {
  host: HTMLElement;
  root: HTMLElement;
  config: any = {};
  sortStateDescriber: HTMLElement;

  onDestroy: Function[] = [];

  constructor(element: HTMLElement) {
    element.dataset[ENHANCED_FLAG] = "true";
    this.root = this.host = element;
    this.setConfig({...defaultConfig, ...configFromDataAttributes(element)});
    const unbind = bindDataTable(this);
    setColumnSortDescribers(element);
    this.onDestroy = [unbind, clearUnusedSortDescribers];
    instances.set(element, INSTANCE_KEY, this);
  }

  setConfig(config: any = {}) {
    const announceSortState = this.config.announceSortState;
    convertStringRefs(config, ['onSortColumn', 'sortComparer'], 'function');
    this.config = { ...this.config, ...config };
    if (announceSortState !== this.config.announceSortState) {
      this.sortStateDescriber = getSortStateDescriberElement(this);
    }
  }

  sortColumn(colIndex: number, ascending: boolean) {
    const { host } = this;
    const th = host && host.querySelectorAll('thead tr:last-child th')[colIndex];
    if (th) {
      if ('undefined' === typeof ascending) {
        ascending = !isSortedAscending(th)
      }
      sortTable(this, colIndex, ascending, <HTMLElement>th);
    }
  }

  getSortedColumn(): any {
    const { host } = this;
    const thead = host && host.querySelector('thead');
    const sortedHeader = thead && thead.querySelector('th[aria-sort="ascending"], th[aria-sort="descending"]')
    if (sortedHeader) {
      return {
        colIndex: Array.from(sortedHeader.parentNode.children).indexOf(sortedHeader),
        ascending: isSortedAscending(sortedHeader),
        th: sortedHeader
      }
    }
    return null
  }

  dispatchEvent(eventType: string, eventInit?: any): boolean {
    return this.host.dispatchEvent(createCustomEvent(eventType, eventInit));
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this.root) {
      delete this.root.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.host, INSTANCE_KEY);
    this.root = this.host = null;
  }
}

function isSortedAscending(th: Element) {
  return th.getAttribute('aria-sort') === 'ascending'
}

let nextSortStateElementId = 1;
function getSortStateDescriberElement(dataTable: DataTableComponent) {
  const { host, config } = dataTable;
  const { announceSortState } = config;
  let sortStateEl: HTMLElement = null;

  if (announceSortState && !!host.querySelector('th[aria-sort]')) {
    if ('string' === typeof announceSortState) {
      sortStateEl = document.getElementById(announceSortState) || document.querySelector(announceSortState);
    } else if (announceSortState instanceof HTMLElement) {
      sortStateEl = announceSortState;
    }

    if (!sortStateEl) {
      const t = translations(getLang(host)).t
      // create descriptor in 2 parts.
      // Part 1 is the table caption followed by "is". e.g. "Table 'Policies' is "
      const part1 = t('htmlTableIs', getTableCaption(host));
      // Part 2 is the sort state. e.g. "sorted by Name, ascending"
      const part2 = sortStateEl = document.createElement('span');
      // Part 2 is associated with the table via aria-describedby, so AT will describe the table's sort state
      // without repeating the table caption
      part2.id = `table-sort-describer-${nextSortStateElementId++}`;
      const describedby = `${host.getAttribute('aria-describedby') || ''} ${sortStateEl.id}`.trim();
      host.setAttribute('aria-describedby', describedby);
      // parts 1&2 are combined as a live region after the table, so AT will read this as:
      // "Table 'Policies' is sorted by Name, ascending"
      const liveRegion = document.createElement('span');
      liveRegion.textContent = part1;
      liveRegion.appendChild(part2);
      liveRegion.classList.add(`${CSS_NS}sr-only`);
      liveRegion.setAttribute('aria-live', 'polite');
      // aria-atomic = true means to read the entire live region when sortStateEl changes, not just the updated text
      liveRegion.setAttribute('aria-atomic', 'true');
      liveRegion.setAttribute('aria-hidden', 'true');
      if (host.parentNode) {
        host.parentNode.insertBefore(liveRegion, host.nextElementSibling);
      }
    }
  }
  return sortStateEl
}

function getTableCaption(table: HTMLElement): string {
  const caption = table.querySelector('caption');
  let text = (caption && caption.textContent && caption.textContent.trim()) || getLabelledByLabel(table);
  if (!text) {
    const figure = table.closest('figure')
    const figcaption = figure && figure.querySelector('figcaption');
    text = (figcaption && figcaption.textContent && figcaption.textContent.trim());
  }
  return text ? `'${text}'` : '';
}

function setColumnSortDescribers(table: HTMLElement) {
  // Only apply to column headers whose label is not configured by aria-label or aria-labelledby.
  // The assumption is if <th> is so labelled, then sort button can have a more meaningful label
  // and would not need aria-describedby
  const sortableHeaders = Array.from(table.querySelectorAll('th[aria-sort]:not([aria-label]):not([aria-labelledby])'))
  sortableHeaders.forEach(th => {
    const SORT_BUTTON_SELECTOR = `.${COLUMN_SORT_BUTTON_CLASS}`
    const sortButton: HTMLElement = <HTMLElement>th.querySelector(SORT_BUTTON_SELECTOR) || (th.matches(SORT_BUTTON_SELECTOR) ? <HTMLElement>th : null);
    if (sortButton) {
      const describer = getColumnSortDescriber(getLang(sortButton));
      const describedbyIds: string[] = (sortButton.getAttribute('aria-describedby') || '').match(ID_PARSER_RX) || [];
      if (describedbyIds.indexOf(describer.id) === -1) {
        describedbyIds.push(describer.id);
      }
      sortButton.setAttribute('aria-describedby', describedbyIds.join(' '));
    }
  })
}

const columnSortDescribers: {[lang: string]: HTMLElement} = {};
let nextElementId = 1;
function getColumnSortDescriber(lang: string) {
  // creates (if needed) and returns a sort column descriptor for the language passed
  // The descriptor reads: Sorts by this column
  let describer = columnSortDescribers[lang]
  if (!describer) {
    const docLang = getLang(document.body);
    const SORT_DESCR_ID_PFX = 'data-column-sort-button-description';
    // check if added by developer
    const selector = `[id^="${SORT_DESCR_ID_PFX}"]${lang !== docLang ? `[lang="${lang}"]` : ':not([lang])'}`
    describer = document.querySelector<HTMLElement>(selector);
    // if not, create it
    if (!describer) {
      describer = document.createElement('span')
      describer.hidden = true
      describer.id = `${SORT_DESCR_ID_PFX}-${nextElementId++}`
      describer.textContent = translations(lang).t('sortColumnDescription')
      if (lang !== docLang) {
        describer.lang = lang
      }
      document.body.appendChild(describer)
      // only track the describers created so we know which to delete
      columnSortDescribers[lang] = describer
    }
  }
  return describer
}

function clearUnusedSortDescribers() {
  // called by destroy. Removes any column sort describers created by this module no longer needed
  Object.keys(columnSortDescribers).forEach(key => {
    const describer = columnSortDescribers[key]
    if (!document.querySelector(`[aria-describedby~="${describer.id}"]`)) {
      describer.parentNode.removeChild(describer)
      delete columnSortDescribers[key]
    }
  })
}





class DataTable {
  _instance: _DataTableInstance;
  constructor(element: HTMLElement, config?: any) {
    this._instance = <_DataTableInstance>instances.get(element, INSTANCE_KEY) || new _DataTableInstance(element);
    if (config) {
      this.setConfig(config);
    }
  }

  /**
     * Sets or resets the configuration
     * When resetting, only properties included in the config object will be updated
     * @param {object} config
     */
  setConfig(config: any) {
    this._instance.setConfig(config)
  }

  /**
   * Sort table by column based on its table-sort configuration: simlple, callback, event.
   * If table-sort is not set, simply toggles the column's sort state
   * @param {number} colIndex - The zero-based column index to sort
   * @param {boolean} ascending (optional) Forces an ascending or decending order. If not provided, reverses the current sort order or sorts ascending if not currently sorted.
   */
  sortColumn(colIndex: number, ascending: boolean) {
    this._instance.sortColumn(colIndex, ascending)
  }

  /**
   * Gets the column index and sort state of the currently sorted column
   * @returns {object} If a column is sorted, returns an object with the properties:
   * - colIndex: The zero-based index of the sorted column
   * - acending: true if sorted ascending; otherwise false
   * Returns null if no column is sorted
   */
  getSortedColumn(): any {
    return this._instance.getSortedColumn()
  }

  /**
   * Detaches from the nav element, removes event listeners, and frees resources.
   */

  destroy() {
    const { _instance } = this;
    delete this._instance;
    return _instance.destroy();
  }
}

const SORTABLE_TABLE_SELECTOR = 'table[data-table-sort]';
const SORTABLE_TABLE_HEADER_SELECTOR = `table.${DTABLE_CLASSNAME} thead th[aria-sort]`;
const ROW_SELECTION_SELECTOR = '[data-row-selector="true"]';
const ROW_EXPANSION_SELECTOR = `[${ROW_EXPANDER_DATA_ATTR}], [${EXPANSION_ROW_DATA_ATTR}]`;
const tableSelectors = [
  SORTABLE_TABLE_SELECTOR,
  SORTABLE_TABLE_HEADER_SELECTOR,
  ROW_SELECTION_SELECTOR,
  ROW_EXPANSION_SELECTOR
].join(',');

// Listen for addition of table child elements as well as table so that a table that starts empty
// is enhanced once an enhanceable element is added. For instance, adding an expandable row 
// to an empty table
onDOMChanges(tableSelectors,
  function onDataTableAdded(element: HTMLElement) {
    const table = element.closest('table');
    if (table && table.dataset[ENHANCED_FLAG] !== "true") {
      new DataTable(table);
    }
  });

onDOMChanges(`table[${ENHANCED_DATA_ATTR}]`,
  null,
  function onDataTableRemove(element: HTMLElement) {
    new DataTable(element).destroy();
  });

ToggleIcon.autoEnhance(`.${ROW_EXPANDER_TOGGLE_ICON_CLASS}`);

export { DataTable }