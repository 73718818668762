import { PaginationComponent } from './PaginationComponent';
import { EventListeners } from '../../utilities/EventListeners';
import doWhileEventing from '../../utilities/doWhileEventing';

export function bindPagination(pagination: PaginationComponent) : Function {
  const eventListeners = new EventListeners();
  eventListeners.addListener(pagination.root, 'click', (e: Event) => {
    const control = (e.target as HTMLElement).closest<HTMLElement>('a[data-page],button[data-page]');
    if (control) {
      const page = parseInt(control.dataset.page);
      const action = control.dataset.action as 'page' | 'next' | 'prev';
      const url = (control as HTMLAnchorElement).href;
      const href = control.getAttribute('href') || undefined;
      const prevent = control.getAttribute('aria-disabled') === 'true' || !pagination.dispatchEvent({ page, url, href, action });
      if (prevent) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  })

  const callback = () => { 
    pagination.roomForPageControls = calculateRoomForPageControls(pagination);
  }
  const undoWhileEventing = doWhileEventing(window, 'resize', callback, false); // do not run immediately, it will cause a rerender because its async.
  callback(); //run it synchronously instead

  return () => {
    eventListeners.removeListeners();
    undoWhileEventing();
  }
}

// exported for unit test 
export const CONTROL_WIDTH = 44;

function calculateRoomForPageControls(pagination: PaginationComponent) {
  const container = pagination.host?.parentElement;
  // to allow for a buffer, use 90% of client width, divide by (average) control width,
  // then subtract 2 for the previous and next controls
  let nextPrevCount = 2;
  return container ? Math.floor((container.clientWidth * .9) / CONTROL_WIDTH) - nextPrevCount : 0;
}
