/**
 * Gets the target element from an element's attribute. Used by elements that 'trigger' another component
 * Target reference can be an id or a selector
 * Checks 3 atttributes
 * - aria-controls
 * - attribute, which defaults to 'data-target'
 * - href
 * @param element with the target or href attribute
 * @param attribute - The attribute to check after aria-controls. Defaults to 'data-target'
 * @return The target element found or null if not found
 */
function getTargetElement(element: Element, attribute?: string) : HTMLElement {
  try {
    const selector = getTargetSelector(element, attribute);
    return document.getElementById(selector) || document.querySelector(selector) || null;
  } catch (err) {
    return null;
  }
}

/**
 * Returns an array of 'target' elements from an element's attribute. 
 * Used by elements that 'trigger' one or more components.
 * Target reference can be an id or a selector
 * Checks 3 atttributes
 * - aria-controls
 * - attribute, which defaults to 'data-target'
 * - href
 * @param element with the target or href attribute
 * @param attribute - The attribute to check after aria-controls. Defaults to 'data-target'
 * @return The target element found or null if not found
 */
function getTargetElements(element: Element, attribute?: string) : HTMLElement[] {
  try {
    const selector = getTargetSelector(element, attribute);
    const elements: HTMLElement[] =  Array.from(document.querySelectorAll(selector));
    if (elements.length === 0) {
      const element = document.getElementById(selector);
      if (element) {
        elements.push(element);
      }
    }
    return elements;
  } catch (err) {
    return [];
  }
}

/**
 * Gets the selector (or id) for a target element from an element's attribute. Used by elements that 'trigger' another component
 * Target reference can be an id or a selector
 * Checks 3 atttributes
 * - aria-controls
 * - attribute, which defaults to 'data-target'
 * - href
 * @param element with the target or href attribute
 * @param attribute - The attribute to check after aria-controls. Defaults to 'data-target'
 * @return The target element found or null if not found
 * @param element 
 * @param attribute 
 */
function getTargetSelector(element: Element, attribute = 'data-target'): string {
  let selector = element.getAttribute('aria-controls') || element.getAttribute(attribute)
  if (!selector || selector === "true") {
    const href = element.getAttribute('href')
    selector = href && href[0]==='#' && href.length > 1 ? href.trim() : ''
  }
  return selector;
}

export {
  getTargetElement as default,
  getTargetElements,
  getTargetSelector
}
