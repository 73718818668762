import { store, Store } from '../../utilities/store';
import { TdsFile } from './TdsFile';


export interface FileUploadState {
  _tdsFileList: TdsFile[];
  _errorMsg?: string;
  _errorList?: any[];
}

export function createStore(files: TdsFile[] = []) : Store<FileUploadState> {  
  return store({
    _tdsFileList: files
  });
}

