interface EventBinding {
  target: EventTarget;
  type: string;
  listener: EventListener;
  option: any
}

export class EventListeners {
  bindings: Array<EventBinding> = [];
  addListener(target: EventTarget, type: string, listener: EventListener, option?: any) : EventBinding {
    const binding = {target, type, listener, option};
    target.addEventListener(type, listener, option);
    this.bindings.push(binding);
    return binding;
  }

  removeListener(binding: EventBinding) : void {
    const index = this.bindings.indexOf(binding);
    if (index > -1) {
      const { target, type, listener, option } = this.bindings[index];
      target.removeEventListener(type, listener, option);
      this.bindings.splice(index, 1);
    }
  }

  removeListeners() : void {
    this.bindings.forEach(({ target, type, listener, option }) => {
      target.removeEventListener(type, listener, option);
    })
    this.bindings = [];
  }
}
