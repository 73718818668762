import { CellValidationData } from "./CellValidationData";
import { CellValidator } from "./CellValidator";
import { LengthValidatorOptions } from "./validatorsFactory";

export class LengthCellValidator implements CellValidator {
  _options: LengthValidatorOptions;

  constructor(options: LengthValidatorOptions) {
    this._options = options;
  }

  validate(cellData: CellValidationData): CellValidationData {
    const { min, max, message } = this._options;
    const newValue = (cellData.newValue || '').toString().trim();
    let errorReason: 'tooShort' | 'tooLong';
    if (min && newValue.length && newValue.length < min) {
      errorReason = 'tooShort';
    } else if (max && newValue.length > max) {
      errorReason = 'tooLong';
    }
    return errorReason ? { ...cellData, errorMessage: message, errorReason } : cellData;
  }

  get type() { return 'length'; }
  get options() { return this._options; }

}