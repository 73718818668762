import { Store } from '../../utilities/store';
import { EventListeners } from '../../utilities/EventListeners';
import { normalizeKey } from '../../utilities/keyboard';
import { getLang, translations } from '../../utilities/i18n';
import { hasShadowDom } from '../../utilities/helpers';
import { MULTISELECT_CLASSES } from '../../utilities/constants';
import { MultiSelectComponent } from './MultiSelectComponent';
import { MultiSelectState } from './MultiSelectState';

function getRoot(host: any, el: any) {
  const selectElement = host.querySelector('select') || <HTMLSelectElement>el;
  return (el === selectElement) ? el.parentNode : el;
}

export function bindMultiSelect(multiselect: MultiSelectComponent) {
  const { host, el } = multiselect;
  const rootElm = hasShadowDom(host) ? host.shadowRoot : getRoot(host, el) as Element;
  const controlElm = rootElm.querySelector("." + MULTISELECT_CLASSES.CONTROL);
  const popupElm = rootElm.querySelector("." + MULTISELECT_CLASSES.POPUP);
  // Add Event Listeners
  const eventListeners = new EventListeners();
  // listeners for managing closing of popup, only in effect when popup is opened
  const closePopupListeners = new EventListeners();
  // control behavior
  eventListeners.addListener(controlElm, 'mousedown', ifEnabled(multiselect, controlClickHandler(rootElm, closePopupListeners)));
  eventListeners.addListener(controlElm, 'keydown', ifEnabled(multiselect, keyDownHandler(rootElm, closePopupListeners)));
  // popup behavior
  eventListeners.addListener(popupElm, 'keydown', ifEnabled(multiselect, popupKeyDownHandler(rootElm, closePopupListeners)));
  // checkbox behavior
  eventListeners.addListener(popupElm, 'change', ifEnabled(multiselect, onCheckBoxChange(multiselect)));


  const unbind = () => {
    eventListeners.removeListeners();
    closePopupListeners.removeListeners();
  }
  return unbind;
}

function ifEnabled(multiselect: MultiSelectComponent, handler: (event: Event) => any) {
  return (event: Event) => {
    if (!multiselect.disabled) {
      handler(event);
    }
  }
}


// Dropdown click handler
function controlClickHandler(rootElm: any, closePopupListeners: EventListeners) {
  return () => {
    if (isMultiselectOpen(rootElm)) hideMultiselect(rootElm, closePopupListeners);
    else showMultiselect(rootElm, closePopupListeners);
  }
}

// Checkbox change handler
function onCheckBoxChange(multiselect: MultiSelectComponent) {
  return (event: Event) => {
    const checkbox = (event.target as HTMLElement).closest('input[type="checkbox"]') as HTMLInputElement;
    if (checkbox) {
      if (!checkbox.dataset.index) {
        performSelectAll(multiselect, checkbox,);
      }
      else {
        performSelectItem(multiselect, checkbox,);
      }
      var options = buildOptionObject(multiselect);
      // setState
      setOptions(options, multiselect.store);
      setIndeterminateState(multiselect);
      updateText(multiselect);
      multiselect.dispatchEvent('change', { detail: { multiselect } });
    }
  }
}

// perform checkbox select item
function performSelectItem(multiselect: MultiSelectComponent, checkbox: HTMLInputElement) {
  const { el, host } = multiselect;
  const selectElement = host.querySelector('select') || <HTMLSelectElement>el;
  const index = parseInt(checkbox.dataset.index);
  const selectedItem = selectElement.options[index]; // selected option
  const selectedRow = checkbox.closest("[role=row]"); // row of checkbox checked
  const checked = checkbox.checked; // sets checkbox true or false,

  // if checkbox checked, increase the count else decrease the count
  selectedItem.selected = checked;
  checked ? selectedRow.classList.add(MULTISELECT_CLASSES.SELECTED) : selectedRow.classList.remove(MULTISELECT_CLASSES.SELECTED);
  checkbox.setAttribute('aria-selected', checked ? "true" : "false");
}

// updates select all checkbox inderminate state
function setIndeterminateState(multiselect: MultiSelectComponent) {
  const { el, host } = multiselect;
  const rootElm = hasShadowDom(host) ? host.shadowRoot : getRoot(host, el);
  const popupElm = rootElm.querySelector("." + MULTISELECT_CLASSES.POPUP);
  var allChkBox = <HTMLInputElement>(popupElm.querySelector('input[type="checkbox"]:not([data-index])'));
  if(allChkBox){
  allChkBox.setAttribute('aria-selected', "false");
  const options = getOptions(multiselect);
  const optionsLength = options.length;
  var count = options.filter(option => option.selected).length;
  if (count !== 0 && count !== optionsLength) {
    allChkBox.indeterminate = true;
  }
  if (count === optionsLength) {
    allChkBox.indeterminate = false;
    allChkBox.checked = true;
    allChkBox.setAttribute('aria-selected', "true");
  }
  else if (count === 0) {
    allChkBox.indeterminate = false;
    allChkBox.checked = false;
   }
  }
}

// performs Select All click event
function performSelectAll(multiselect: MultiSelectComponent, checkbox: HTMLInputElement) {
  const { el, host } = multiselect;
  const rootElm = hasShadowDom(host) ? host.shadowRoot : getRoot(host, el);
  const checked = checkbox.checked;
  const popupElm = rootElm.querySelector("." + MULTISELECT_CLASSES.POPUP);
  const allChkBox = <HTMLInputElement>popupElm.querySelector('input[type="checkbox"]:not([data-index])');

  // select all checkbox state
  allChkBox.indeterminate = false;
  allChkBox.setAttribute('aria-selected', checked ? "true" : "false");

  const options = getOptions(multiselect);
  Array.from(options).forEach(option => updateOptions(multiselect, option, checked));
}

// update Items check and selected state
function updateOptions(multiselect: MultiSelectComponent, option: any, checked: boolean) {
  const { el, host } = multiselect;
  const rootElm = hasShadowDom(host) ? host.shadowRoot : getRoot(host, el);

  // Updating select option
  var selectElement = host.querySelector('select') || <HTMLSelectElement>el;
  const selectedItem = selectElement.querySelectorAll('option')[option.index];
  selectedItem.selected = checked;

  // updating multiselect checkboxes
  const checkbox = rootElm.querySelector(`input[type="checkbox"][data-index="${option.index}"]`);
  if(checkbox){
  checkbox.checked = checked;

  // updating multiselect row
  const selectedRow = checkbox.closest("[role=row]");
  selectedItem.selected = checked;
  checked ? selectedRow.classList.add(MULTISELECT_CLASSES.SELECTED) : selectedRow.classList.remove(MULTISELECT_CLASSES.SELECTED);
  checkbox.setAttribute('aria-selected', checked ? "true" : "false");
 }
}

// once options selected/unselected update text on control div
export function updateText(multiselect: MultiSelectComponent) {
  const { config, host, el } = multiselect;
  const rootElm = hasShadowDom(host) ? host.shadowRoot : getRoot(host, el);
  const controlElm = rootElm.querySelector("." + MULTISELECT_CLASSES.CONTROL);
  const popupElm = rootElm.querySelector('.' + MULTISELECT_CLASSES.POPUP);
  const optionItems = rootElm.querySelectorAll('input[type="checkbox"][data-index]');
  const multiselectRows = popupElm.querySelectorAll('[role=row]');
  const rows = Array.from(multiselectRows); // converting to array
  const activeItems: any = rows.filter((row: any) => row.classList.contains('selected'));
  const translation = translations(getLang(el)).t;
  let selectedText = '';
  if (activeItems && activeItems.length) {
    const totalItems = optionItems.length; // removing selectAll checkbox
    const configGroupLimit = config.groupLimit; // limit set to group the selected checkboxes
    const selectedOptionCount = activeItems.length;
    if (selectedOptionCount <= configGroupLimit) { // adding options as comma delimited text
      selectedText = activeItems.map((item: any) => item.textContent.trim()).join(', ');
    }
    else if (selectedOptionCount < totalItems) { // Hide/Show Badge depending on config
      selectedText = translation("optionsSelected", selectedOptionCount.toString());
    }
    else {// when all items selected   
      selectedText = translation("allOptionsSelected", selectedOptionCount.toString());
    }
  }
  // setting active options text and values
  controlElm.textContent = selectedText;
}

// check if the list is already open
function isMultiselectOpen(rootElm: any): boolean {
  const controlElm = rootElm.querySelector("." + MULTISELECT_CLASSES.CONTROL);
  return controlElm.getAttribute('aria-expanded') === 'true' ? true : false;
}
// Show Items
export function showMultiselect(rootElm: any, closePopupListeners: EventListeners) {
  const controlElm = rootElm.querySelector("." + MULTISELECT_CLASSES.CONTROL);
  const popupElm = rootElm.querySelector("." + MULTISELECT_CLASSES.POPUP);
  popupElm.classList.add("expanded");
  controlElm.setAttribute("aria-expanded", "true");

  // add listeners to manage closing of popup
  closePopupListeners.removeListeners();
  const checkClosePopupHandler = checkClosePopup(rootElm.host || rootElm, rootElm, closePopupListeners);
  closePopupListeners.addListener(document, 'mousedown', checkClosePopupHandler);
  closePopupListeners.addListener(document, 'touchstart', checkClosePopupHandler);
  closePopupListeners.addListener(document, 'focusin', checkClosePopupHandler);
  // this one is needed for web component quirks 
  closePopupListeners.addListener(rootElm, 'keydown', onTabAway(rootElm, closePopupListeners));

}
function checkClosePopup(host: Node, rootElm: ShadowRoot | Element, closePopupListeners: EventListeners) {

  return ({target, type}: Event) => {
    const targetEl = target as Element
    if (type === 'focusin' && targetEl.hasAttribute('tabindex') && targetEl.contains(host)) {
      // when container has tabindex, it receives focusin event on click. 
      // Ignore, other events should come along to close this.
      return;
    }
    
    if (!host.contains(targetEl)) {
      hideMultiselect(rootElm, closePopupListeners);
    }
  }
}

function onTabAway(rootElm: ShadowRoot | Element, closePopupListeners: EventListeners) {
  return (e: KeyboardEvent) => {
    const key = normalizeKey(e);
    if (key === 'Tab') {
      hideMultiselect(rootElm, closePopupListeners);
    }
  }
}

// Hide Items
export function hideMultiselect(rootElm: any, closePopupListeners: EventListeners) {
  const controlElm = rootElm.querySelector("." + MULTISELECT_CLASSES.CONTROL);
  const popupElm = rootElm.querySelector("." + MULTISELECT_CLASSES.POPUP);
  popupElm.classList.remove("expanded");
  controlElm.setAttribute("aria-expanded", "false");
  closePopupListeners.removeListeners();
}

function buildOptionObject(multiselect: MultiSelectComponent) {
  const { host, el } = multiselect;
  var options = [];
  var selectElement = host.querySelector('select') || <HTMLSelectElement>el;
  var selectOptions = selectElement ? selectElement.querySelectorAll('option') : null;
  // create option object, which includes both selectElement and 
  // multiselectElement index, value and selected
  if (selectOptions && selectOptions.length) {
    for (let i = 0; i < selectOptions.length; i++) {
      var option = {
        id: selectOptions[i].value || selectOptions[i].getAttribute('value'), // latter is to make unit test work
        index: i,
        text: selectOptions[i].textContent,
        selected: !!selectOptions[i].selected,
        value: selectOptions[i].value
      }
      options.push(option);
    }
  }

  return multiselect.options = options;
}

// return all options list
// export function getOptions(multiselect: MultiSelectComponent) {
//   return buildOptionObject(multiselect);
// }

// STORE actions - getters and setters
export function getOptions(multiselect: MultiSelectComponent) {
  const { state, store } = multiselect;

  if (state === undefined || state.options === undefined || state.options.length === 0) {
    var options = buildOptionObject(multiselect);
    // setState
    setOptions(options, store)
    return options;
  }
  return state.options;
}
export function setOptions(options: any, store: Store<MultiSelectState>) {
  store.update(v => {
    return { ...v, options }
  });
}

// returns only selected options list
export function getSelectedOptions(multiselect: MultiSelectComponent) {
  var options = buildOptionObject(multiselect);
  return options.filter((option: { selected: any; }) => option.selected)
}

// preselected Items
export function initWithConfig(multiselect: MultiSelectComponent) {
  // get pre-selected options from client configuration
  const selectedOptions = multiselect.config.selectedOptions || getOptions(multiselect)
    .filter(option => option.selected)
    .map(option => option.id);
  setSelectedOptions(selectedOptions, multiselect);
}

export function setSelectedOptions(selectedOptionValues: any, multiselect: MultiSelectComponent) {
  selectedOptionValues = selectedOptionValues ?
    Array.isArray(selectedOptionValues) ?
      selectedOptionValues :
      selectedOptionValues.trim().split(",").map((v: string) => v.trim()).filter((v: string) => !!v)
    : [];
  const options = [...getOptions(multiselect)];
  options.forEach(option => {
    const selected = selectedOptionValues.includes(option.id);
    option.selected = selected;
    updateOptions(multiselect, option, selected);
  });
  setOptions(options, multiselect.store);
  setIndeterminateState(multiselect);
  updateText(multiselect);
}

// Keyboard Events
// Keydown on input
function keyDownHandler(rootElm: any, closePopupListeners: EventListeners): EventListener {
  return ((event: KeyboardEvent) => {
    const key = normalizeKey(event);
    const controlElm = rootElm.querySelector("." + MULTISELECT_CLASSES.CONTROL);
    const popupElm = rootElm.querySelector("." + MULTISELECT_CLASSES.POPUP);
    switch (key) {
      case "Escape":

        if (isMultiselectOpen(rootElm)) {
          hideMultiselect(rootElm, closePopupListeners);
          controlElm.focus();
          event.preventDefault();
        }
        break
      case 'Enter':
        if (isMultiselectOpen(rootElm)) {
          hideMultiselect(rootElm, closePopupListeners);
          setActiveRow(getDefaultOption(popupElm));
        }
        else showMultiselect(rootElm, closePopupListeners);
        event.preventDefault();
        break

      case 'ArrowDown':
        if (!isMultiselectOpen(rootElm)) {
          showMultiselect(rootElm, closePopupListeners)
        }
        setActiveRow(getDefaultOption(popupElm));
        event.preventDefault();
        break;
    }
  })
}
function popupKeyDownHandler(rootElm: any, closePopupListeners: EventListeners): EventListener {
  return ((event: KeyboardEvent) => {
    const key = normalizeKey(event);
    const controlElm = rootElm.querySelector("." + MULTISELECT_CLASSES.CONTROL);
    const popupElm = rootElm.querySelector("." + MULTISELECT_CLASSES.POPUP);

    switch (key) {
      case "Escape":
      case 'Enter':
        if (isMultiselectOpen(rootElm)) {
          hideMultiselect(rootElm, closePopupListeners);
          controlElm.focus();
          event.preventDefault();
        }
        break;

      case " ":
        const option = getActiveRow(popupElm)
        if (option) option.querySelector('input').click();
        event.preventDefault();
        break;

      case "ArrowUp":
        setActiveRow(getPreviousOption(popupElm));
        event.preventDefault();
        break;

      case "ArrowDown":
        setActiveRow(getNextOption(popupElm));
        event.preventDefault();
        break;
    }
  })
}

// set Active Row
function setActiveRow(option: HTMLElement) {
  if (option) {
    option.querySelector('input').focus();
  }
}
// get Active Row
function getActiveRow(popupElm: any): any {
  const focused = popupElm.querySelector('input[type="checkbox"]:focus');
  return focused && focused.closest('[role="row"]');
}

// get default option
function getDefaultOption(popupElm: any) {
  const selectedOptions = popupElm.querySelectorAll('[aria-selected="true"]');
  if (selectedOptions.length > 0) return selectedOptions[0].closest('[role=row]');
  return popupElm.children[0];
}

// returns the index of active row -- alternative for findIndex
function findRowIndex(row: HTMLCollection, activeRow: any): any {
  return Array.from(row).map(element => element.id).indexOf(activeRow.id);
}

// get next active row option
function getNextOption(popupElm: any) {
  const activeRow = getActiveRow(popupElm);
  if (!activeRow) return getDefaultOption(popupElm);
  const row = <HTMLCollection>popupElm.querySelectorAll("[role=row]")
  const row_index = findRowIndex(row, activeRow);
  if (row_index + 1 === row.length) {
    // don't rotate
    return popupElm.children[row.length - 1];
    // this is for rotating selection on key down
    // goes back to select all
    // return listElm.children[0];
  }
  return row[row_index + 1];
}
// get previous Active row option
function getPreviousOption(popupElm: any) {
  const activeRow = getActiveRow(popupElm);
  if (!activeRow) return getDefaultOption(popupElm);
  const row = <HTMLCollection>popupElm.querySelectorAll("[role=row]")
  const row_index = findRowIndex(row, activeRow);
  if (row_index === 0) {
    // don't rotate
    return popupElm.children[0]
    // this is for rotating selection on key up
    // goes back to last child
    // return listElm.children[row.length - 1];
  }
  return row[row_index - 1];
}
