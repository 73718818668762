import { getChildElements, visuallyHide } from "../../../utilities/helpers";
import { EDIT_PANEL_ATTR, EDIT_VALUE_ATTR } from "./editableDataTableConstants";

/**
 * Gets the virtual col index for the cell, which is the sum of the colspans of its previous siblings.
 * For instance, if cell is the third cell, and the first cell spans 2 columns, and the second spans 1,
 * then the column index of the third cell would be 3, not 2
 * @param cell The cell to return the index of
 * @returns The column index of cell
 */
export function getColumnIndex(cell: HTMLTableCellElement): number {
  const row = cell.closest('tr');
  return (getChildElements(row) as HTMLTableCellElement[])
    .reduce((acc: number, sibling: HTMLTableCellElement) => (sibling.cellIndex < cell.cellIndex ? acc + sibling.colSpan : acc), 0);
}

/**
 * Gets the table header for the cell. Manages complex tabls where cells or header cells span multiple columns
 * @param cell The cell to return the header of
 * @returns the column header cell for the cell
 */
export function getColumnHeader(cell: HTMLTableCellElement): HTMLTableCellElement {
  const table = cell.closest('table');
  const headerCells = table && Array.from(table.querySelectorAll('thead > tr > *')).filter(h => h.closest('table') === table) as HTMLTableCellElement[];
  const colIndex = getColumnIndex(cell);
  let headerColIndex = 0;
  return headerCells.find(h => {
    if (headerColIndex <= colIndex && headerColIndex + h.colSpan > colIndex) {
      return true;
    }
    headerColIndex += h.colSpan;
    return false;
  });
}

export function cellToTextValue(cell: HTMLTableCellElement): string {
  let value = cell.getAttribute(EDIT_VALUE_ATTR);
  if (value === null) {
    value = Array.from(cell.childNodes)
      .filter(node => !(node.nodeType === Node.ELEMENT_NODE && (node as Element).matches(`[${EDIT_PANEL_ATTR}]`)))
      .map(node => {
        return node.textContent.trim();
      })
      .filter(Boolean)
      .join(' ').trim();
  }
  return value;
}

export function textToCellNodes(cell: HTMLTableCellElement, value: any, displayValue: any) {
  let controlContainer = cell.querySelector(`[${EDIT_PANEL_ATTR}]`);
  const elements = getChildElements(cell).filter(el => el !== controlContainer && !el.matches('br'));
  const el = elements.length === 1 ? elements[0] : cell;
  // remove all nodes except control container
  Array.from(el.childNodes).forEach(node => {
    if (node !== controlContainer) {
      el.removeChild(node);
    }
  });

  if (value !== displayValue) {
    cell.setAttribute(EDIT_VALUE_ATTR, value);
  }

  controlContainer = el.querySelector(`[${EDIT_PANEL_ATTR}]`);
  const node = cell.ownerDocument.createTextNode(displayValue.toString());
  el.insertBefore(node, controlContainer);
}


export function isEditableRow(row: HTMLTableRowElement): boolean {
  const selectors = [
    `tbody > tr[data-row-expansion]`,
    `tbody > tr.expansion-row`,
  ].join(',');
  return !row.matches(selectors) && !isRowGroupHeaderRow(row);
}

export function isRowGroupHeaderRow(row: HTMLTableRowElement): boolean {
  const rowGroupHeader = row.querySelector('tr:first-child > th:only-child');
  return !!rowGroupHeader && rowGroupHeader.closest('tr') === row;
}

let announceTimeout: any;

/**
 * Announces a message to screen reader users. This is useful to audibly augment a visual cue such as 
 * a loading indicator. If needed, adds a visually hidden aria-live region to the document, 
 * then update its content with the message. Removes the content after 10 seconds
 * 
 * NOTE: This would be a useful utitlity in general and not just for editable table. If made
 * more general, make ARIA_LIVE_REGION_ID a more general id.
 *    
 * @param message The message to announce  
 * @param documentElement an element from which to derive the document used to create the element 
 */
export function announce(message: string, documentElement?: Element) {
  if (announceTimeout) {
    clearTimeout(announceTimeout);
  }
  getAriaLiveRegion(documentElement ? documentElement.ownerDocument : document, (region) => {
    region.textContent = message;
    // clear the message after a few seconds
    announceTimeout = setTimeout(() => {
      region.textContent = '';
      announceTimeout = undefined;
    }, 10000);
  });
}

function getAriaLiveRegion(doc: Document, callback: (el: HTMLElement)=> void) {
  const ARIA_LIVE_REGION_ID = "tds-editable-table__aria-live-region__b3c2ac";
  let region = doc.getElementById(ARIA_LIVE_REGION_ID);
  if (region) {
    callback(region);
  } else {
    region = doc.createElement('div');
    region.id = ARIA_LIVE_REGION_ID;
    region.setAttribute('aria-live', 'polite');
    region.setAttribute('aria-atomic', 'true');
    region.setAttribute('role', 'status');
    visuallyHide(region);
    doc.body.appendChild(region);
    // place element on page before setting content so it will be read by screen reader
    setTimeout(() => callback(region), 50);
  }
}

