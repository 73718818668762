import { CellValidationData } from "./CellValidationData";
import { CellValidator } from "./CellValidator";

export class CellValidatorChain {
  _validators: CellValidator[] = [];
  constructor(validators: CellValidator[]) {
    this._validators = validators;
  }

  validate (cellData: CellValidationData): Promise<CellValidationData> {
    return this.runValidation(cellData, 0);
  }

  runValidation(cellData: CellValidationData, index: number) : Promise<CellValidationData> {
    const validator = this._validators[index];
    if (!validator || cellData.errorMessage) {
      return Promise.resolve(cellData);
    }

    return Promise.resolve(validator.validate(cellData)).then((result: CellValidationData) => {
      return this.runValidation(result, index + 1);
    });
  }
}