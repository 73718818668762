import { NumberFormatter } from "../../formatters/NumberFormatter";
import { getLang } from "../../../../utilities/i18n";
import { CellValidationData } from "./CellValidationData";
import { CellValidator } from "./CellValidator";
import { expressions } from "./patterns";
import { ValidationMessage } from "./validatorsFactory";

export interface NumberCellValidationOptions extends ValidationMessage {
  decimals?: number,
  currency?: string,
  format?: boolean,
  convert?: boolean
}

export class NumberCellValidator implements CellValidator {
  _options: NumberCellValidationOptions;

  constructor(options: NumberCellValidationOptions) {
    this._options = options;
  }

  validate(cellData: CellValidationData): CellValidationData {
    const { format, message, convert } = this._options;
    let newValue = (cellData.newValue === 0 ? 0 : cellData.newValue || '').toString().trim();
    newValue = this.validateCurrency(newValue);
    newValue = this.validateDecimals(newValue);
    if (newValue !== null) {
      const displayValue = format && newValue !== '' ? this.format(newValue, cellData.cell) : cellData.displayValue;
      return { ...cellData, newValue: (convert ? newValue : cellData.newValue), displayValue };
    }
    return { ...cellData, errorMessage: message, errorReason: 'badInput' };
  }

  validateCurrency(value: string): any {
    const { currency } = this._options;
    if (value && currency) {
      switch (currency.toUpperCase()) {
        case 'USD':
          const rx = expressions.uscurrency;
          return rx.test(value) ? value.replace(/\$/g, '') : null;
      }
    }
    return value;
  }

  validateDecimals(value: string): any {
    const { decimals } = this._options;
    let decPos = -1;
    if (value) {
      const rx = expressions.decimal;
      if (!rx.test(value)) {
        return null;
      }
      decPos = value.indexOf('.');
      if (typeof decimals !== 'undefined') {
        if (decPos > -1) {
          const decimalPlaces = value.length - decPos - 1;
          if (decimals > 0) {
            if (decimalPlaces > decimals) {
              return null;
            }
          } else {
            return null;
          }
        }
      }
    }
    let returnValue: any = value;
    if (returnValue) {
      returnValue = returnValue.replace(/[^\d.-]/g, '');
      returnValue = decPos > -1 ? parseFloat(returnValue) : parseInt(returnValue);
    }
    return returnValue;
  }

  format(value: number, cell: HTMLTableCellElement): string {
    const { currency, decimals } = this._options;
    const formatter = new NumberFormatter({ currency: currency ? 'USD' : undefined, decimals })
    return formatter.format(value, getLang(cell));
  }

  get type() { return 'number'; }
  get options() { return this._options; }
}
