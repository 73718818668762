/**
 * Runs a callback function while an event is active. This is most useful 
 * for efficiently monitoring events such as resizing or scrollng and applying 
 * updates to the DOM in without jankiness.  
 */

export interface DoWhileEventingOptions {
  runImmediate?: boolean,
  timeoutDelay?: number  
}

const defaultOptions: DoWhileEventingOptions = {
  runImmediate: true,
  timeoutDelay: 300
}

export default (el: EventTarget, events: string, callback: Function, options: boolean | DoWhileEventingOptions = {}) => {
  if (typeof options === 'boolean') {
    options = { runImmediate: options }
  }
  const { runImmediate, timeoutDelay } = {...defaultOptions, ...options};

  const eventList = events.split(' ').filter( e => e);
  let timeout = 0;

  const requestAnimationFrame = window.requestAnimationFrame ?
    window.requestAnimationFrame.bind(window) : 
    (run: Function) => window.setTimeout(run, 60);
  
  function onEvent() {
    if (timeout) {
      window.clearTimeout(timeout);
    } else {
      requestAnimationFrame(run);
    }
    timeout = window.setTimeout(() => {
      timeout = 0;
    }, timeoutDelay);
  }
  function run() {
    callback();
    if (timeout) {
      requestAnimationFrame(run);
    }
  }
  eventList.forEach(event => {
    el.addEventListener(event, onEvent);
  })

  if (runImmediate) {
    requestAnimationFrame(run);
  }

  return () => {
    eventList.forEach(event => {
      el.removeEventListener(event, onEvent);
    })
  }
}