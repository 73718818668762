import { AbstractCellEditor } from "./AbstractCellEditor";
import { CellEditorComponent, CellEditorRenderData } from "./CellEditorComponent";

export type EditorDropdownOptionsMap = {
  [value: string]: string
}

export type EditorDropdownOption = {
  value: string,
  label: string
}

export class SelectCellEditor extends AbstractCellEditor implements CellEditorComponent {
  _options: any;
  _selectOptions: { value: string, label: string }[];

  constructor(options: any) {
    super();
    this._options = options;
  }

  render(data: CellEditorRenderData): void {
    const { cell, cellValue, container, columnHeader, validators } = data;
    const options = this.getSelectOptions();
    const select = this.editorElement = cell.ownerDocument.createElement('select');
    select.setAttribute('aria-label', columnHeader);
    let initValue = '';
    this.renderOptions(select, options);
    options.forEach(({ value, label }) => {
      if (value === cellValue) {
        initValue = value;
      } else if (label === cellValue && !initValue) {
        initValue = value;
      }
    });
    select.value = initValue;
    select.addEventListener('change', this.reportUpdate);
    container.appendChild(select);
    if (validators) {
      validators.forEach(validator => {
        if (validator.type === 'required') {
          select.required = true;
        }
      });
    }

  }

  setOptions(options: any) {
    this._options = options;
    const editorElement = this.editorElement as HTMLSelectElement;
    if (editorElement) {
      this.renderOptions(editorElement, this.getSelectOptions());
    }
  }

  renderOptions(select: HTMLSelectElement, options: { value: string, label: string }[]) {
    while(select.lastChild) {
      select.removeChild(select.lastChild);
    }
    options.forEach(({ value, label }) => {
      const option = select.ownerDocument.createElement('option') as HTMLOptionElement;
      option.value = value;
      option.textContent = label;
      select.appendChild(option);
    });
  }

  getValue() {
    const { editorElement } = this;
    return editorElement ? (editorElement as HTMLSelectElement).value : '';
  }

  setValue(value: any = '') {
    const editorElement = this.editorElement as HTMLSelectElement;
    if (editorElement) {
      editorElement.value = value.toString();
    }
  }

  getDisplayValue() {
    const { editorElement, _selectOptions } = this;
    let value = editorElement ? (editorElement as HTMLSelectElement).value : '';
    const selectedOption = _selectOptions.find(o => o.value === value);
    return selectedOption ? selectedOption.label : value;
  }

  getSelectOptions(): EditorDropdownOption[] {
    let options = this._options;
    if (typeof options === 'string') {
      options = JSON.parse(options);
    }

    if (options && typeof options === 'object' && !Array.isArray(options)) {
      options = Object.keys(options).map(key => {
        return { value: key, label: options[key].toString() }
      });
    }

    if (Array.isArray(options)) {
      options = options.map(option => {
        return typeof option === 'string' ?
          { value: option, label: option } :
          option;
      })
    }
    return this._selectOptions = options;
  }
}