/*
  Manages internationalization and localization for Dates

  Translations are managed by shared/i18n/index.js. Localization (e.g. Date part order) still managed here

 */
import { getLang, translations } from './i18n';

const dateFormats: any = {
  default: 'YYYY/MM/DD',
  'en-us': 'MM/DD/YYYY',
  'en-gb': 'DD/MM/YYYY'
}

const firstDayOfWeekMap: {[key:string]: any} = {
  Sunday: ['us', 'ar', 'bz', 'bo','br','ca','ch','cn','co','cr','do','ec','sv','gt','hn','hk','il','jm','jp','ke','mo','mx','ni','pa','pe','ph','pr','za','kr','tw','ve','zm'],
  Saturday: ['af','dz','bh','eg','ir','iq','jo','kw','ly','om','qa','sa','sy','ae','zm']
}

/**
 * returns language map and format for the element
 * @param element {HTMLElement} The element to apply localization based on its or an ancestor's lang attribute
 * @returns {object} {lang: object, locale: string}
 */
function getLocale (element: HTMLElement | ShadowRoot) {
  const langAttr = getLang(element).toLowerCase();
  const lang = translations(langAttr).language;
  const country = langAttr.toLowerCase().split('-')[1] ||
    window.navigator.language.toLowerCase().split('-')[1] ||
    ((langAttr || '').toLowerCase() === 'en' ? 'us' : 'iso');
  const format = dateFormats[langAttr] || deriveDateFormat(langAttr) || dateFormats.default;
  const firstDayOfWeek = getFirstDayOfWeek(country);
  const dateFormatLabel = format
    .replace(/MM/i, lang.month)
    .replace(/DD/i, lang.day)
    .replace(/YYYY/i, lang.year)
    .replace(/\//g, ' ')
    .toLowerCase()
  return {lang, format, firstDayOfWeek, dateFormatLabel}
}

function getFirstDayOfWeek(country: string) {
  if (firstDayOfWeekMap.Sunday.indexOf(country) > -1) {
    return 0;
  }
  if (firstDayOfWeekMap.Saturday.indexOf(country) > -1) {
    return 6;
  }
  return 1; // Monday
}

function deriveDateFormat(lang: string) {
  const dateStr = Intl.DateTimeFormat(lang).format(new Date(1987, 11, 30, 0, 0, 0)).replace(/\D/g, '');
  // dateStr is an 8 digit string, convert to MM, DD, YYYY
  return dateFormats[lang] = dateStr.split(/(1987|12|30)/)
    .filter(Boolean)
    .join('/')
    .replace('12', 'MM')
    .replace('30', 'DD')
    .replace('1987', 'YYYY');
} 

export { getLocale }
