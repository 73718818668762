/**
 * Cross-browser (meaning IE) function to create an Event to dispatch. Creates a Custom Event
 * @param eventType {string} - The name of the event
 * @param eventInit {object} - Dictionary with the event initialization values as defined in https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent.
 *        For this function, though, bubbles defaults to true
 * @return {CustomEvent} A CustomEvent object
 */
export function createCustomEvent(eventType: string, eventInit = {}) : CustomEvent {
  eventInit = {...eventInit}
  const additionalProperties = stripNonCustomEventInitProperties(eventInit);
  let event: CustomEvent;
  const init = { ...{ bubbles: true, cancelable: false, detail: undefined }, ...eventInit };
  if ('CustomEvent' in window && typeof window.CustomEvent === 'function') {
    event = new window.CustomEvent(eventType, init)
  } else {
    event = document.createEvent('CustomEvent')
    const bubbles = !!init.bubbles
    const cancelable = !!init.cancelable
    const details = init.detail
    event.initCustomEvent(eventType, bubbles, cancelable, details)
  }

  // add any additional properties as read-only
  Object.keys(additionalProperties)
    .forEach(key => {
      Object.defineProperty(event, key, {
        get() {
          return additionalProperties[key]
        }
      })
    });

  return event
}

function stripNonCustomEventInitProperties (eventInit: any) : any {
  const properties = 'detail bubbles cancelable composed'
  const strippedProperties: any = {}
  Object.keys(eventInit).forEach(key => {
    if (properties.indexOf(key) === -1) {
      strippedProperties[key] = eventInit[key]
      delete eventInit[key]
    }
  })
  return strippedProperties
}
