import { ScrollShadowComponent } from './ScrollShadowComponent';

import debounce from '../debounce';

const instanceList: ScrollShadowBehavior[] = [];

const onResize: any = debounce(() => {
  instanceList.forEach(instance => instance.setShadowEffect())
}, 200);

class ScrollShadowBehavior {
  scrollShadow: ScrollShadowComponent
  observer: MutationObserver;

  constructor(scrollShadow: ScrollShadowComponent) {
    this.scrollShadow = scrollShadow;
    if (!instanceList.length) {
      window.addEventListener('resize', onResize);
    }

    instanceList.push(this);
    this.setShadowEffect();
  }

  setShadowEffect() {
    const { scrollShadow } = this;
    const { scrollpanel } = scrollShadow;
    this.pauseWatch();
    const offsets = getShadowOffsets(scrollpanel);
    scrollShadow.applyShadowEffect(offsets.x, offsets.y);
    window.setTimeout(() => { this.watch() }, 100);
  }

  destroy() {
    this.pauseWatch();
    delete this.scrollShadow;
    delete this.observer;
    const ix = instanceList.indexOf(this);
    if (ix > -1) {
      instanceList.splice(ix, 1);
      if (!instanceList.length) {
        window.removeEventListener('resize', onResize);
      }
    }
  }

  watch() {
    const scrollpanel = this.scrollShadow && this.scrollShadow.scrollpanel;
    if (scrollpanel) {
      const observer = this.observer || (this.observer =
        new MutationObserver(this.setShadowEffect.bind(this)));
      observer.observe(scrollpanel, { attributes: true, characterData: true, childList: true, subtree: true });
    }
  }

  pauseWatch() {
    const { observer } = this;
    if (observer) {
      observer.disconnect();
    }
  }
}

function getShadowOffsets(scrollpanel: HTMLElement) {
  let x = false, y = false;
  const style = window.getComputedStyle(scrollpanel);
  const borderLeft = style.borderLeftWidth ? parseInt(style.borderLeftWidth) : 0;
  const borderRight = style.borderRightWidth ? parseInt(style.borderRightWidth) : 0;
  const borderTop = style.borderTopWidth ? parseInt(style.borderTopWidth) : 0;
  const borderBottom = style.borderBottomWidth ? parseInt(style.borderBottomWidth) : 0;
  const offsetWidth = scrollpanel.offsetWidth - borderLeft - borderRight;
  const offsetHeight = scrollpanel.offsetHeight - borderTop - borderBottom;

  // only create the shadow effect if no scrollbars are showing but there is overflow
  if (scrollpanel.clientWidth === offsetWidth && scrollpanel.clientHeight === offsetHeight) {
    x = (scrollpanel.clientWidth < scrollpanel.scrollWidth);
    y = (scrollpanel.clientHeight < scrollpanel.scrollHeight);
  }
  return { x, y };
}

export function bindScrollShadow(scrollShadow: ScrollShadowComponent): Function {
  const behavior = new ScrollShadowBehavior(scrollShadow);
  return () => {
    behavior.destroy();
  }
}