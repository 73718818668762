import { store, Store } from '../../utilities/store';
import { toISODate, startOfMonth, endOfMonth, toReadableDate, isDateDisabled, parseDate } from '../../utilities/date-utils';
import { getLocale } from '../../utilities/localization';
import { CalendarComponent } from './CalendarComponent';
import { calcGoToDate } from './calcGoToDate';

export interface CalendarState {
  activeDate: Date;
}

export function createStore(calendar: CalendarComponent): Store<CalendarState> {
  const { selectedDate } = calendar;
  let date: Date;
  if (selectedDate) {
    date = ('string' === typeof selectedDate) ? parseDate(selectedDate, 'YYYY-MM-DD') : selectedDate;
  }
  return store({ activeDate: date || new Date() });
}

export function getCalendarData(calendar: CalendarComponent): any {
  return buildMonthData(calendar, getLocale(calendar.el));
}

///////////////////////////////////////////////////////////////////////////////////////

function buildMonthData(calendar: CalendarComponent, locale: any): object {
  const { lang, firstDayOfWeek } = locale;
  const activeDate = calendar.state.activeDate;
  const month = activeDate.getMonth();
  const year = activeDate.getFullYear();
  const monthName = lang.months[month].name;
  const week = [0, 1, 2, 3, 4, 5, 6]
  const weekdays = week.map(ix => {
    const day = (ix + firstDayOfWeek) % 7
    return { name: lang.days[day].name, abbreviation: lang.days[day].initial }
  })
  const calendarLabel = lang.calendar;
  return {
    calendarLabel,
    month,
    year,
    monthName,
    title: `${monthName} ${year}`,
    weekdays,
    weeks: buildCalendarDays(calendar, locale),
    controls: buildControlLabels(calendar, locale)
  }
}

function buildControlLabels(calendar: CalendarComponent, locale: any): object {
  const activeDate = calendar.state.activeDate;
  const { min, max } = calendar.config;
  const { lang } = locale;
  const isMac = /^mac/i.test(window.navigator.platform)
  const metaKeyTitle = isMac ? '&#x2325;' : lang.altKey
  const metaKeyLabel = isMac ? lang.optionKey : lang.altKey
  const prevYearTitle = `${lang.prevYear} (${metaKeyTitle}+${lang.pageUp})`
  const prevYearLabel = `${lang.prevYear} (${metaKeyLabel}+${lang.pageUp})`
  const nextYearTitle = `${lang.nextYear} (${metaKeyTitle}+${lang.pageDown})`
  const nextYearLabel = `${lang.nextYear} (${metaKeyLabel}+${lang.pageDown})`
  const prevMonthLabel = `${lang.prevMonth} (${lang.pageUp})`
  const nextMonthLabel = `${lang.nextMonth} (${lang.pageDown})`
  const todayLabel = lang.today
  const todaysDate = toISODate(new Date());
  const todayAccesskey = lang.today.toLowerCase().substring(0, 1)
  const disabledDates = calendar.config.disabledDates;
  const outOfRange = (min && min > todaysDate) || (max && max < todaysDate)
  const excludeToday = outOfRange || isDateDisabled(<any[]>disabledDates, new Date(), todaysDate);
  
  return {
    prevYear: {
      label: prevYearLabel,
      title: prevYearTitle,
      disabled: actionDisabled(activeDate, '-year', min, max, locale)

    },
    nextYear: {
      label: nextYearLabel,
      title: nextYearTitle,
      disabled: actionDisabled(activeDate, '+year', min, max, locale)
    },
    prevMonth: {
      label: prevMonthLabel,
      title: prevMonthLabel,
      disabled: actionDisabled(activeDate, '-month', min, max, locale)
    },
    nextMonth: {
      label: nextMonthLabel,
      title: nextMonthLabel,
      disabled: actionDisabled(activeDate, '+month', min, max, locale)
    },
    today: {
      label: todayLabel,
      title: `( ${todayAccesskey.toUpperCase()} )`,
      accessKey: todayAccesskey,
      include: !(calendar.config.notoday || excludeToday),
      action: 'select',
      date: todaysDate
    }
  }
}
function buildCalendarDays(calendar: CalendarComponent, locale: any): Array<Array<object>> {
  const { selectedDate } = calendar;
  const activeDate = calendar.state.activeDate;
  const { firstDayOfWeek, lang } = locale;
  const { config } = calendar;
  const min = config.min && ensureDateString(config.min);
  const max = config.max && ensureDateString(config.max);
  const dueDate = config.dueDate && ensureDateString(config.dueDate);
  const disabledDates = config.disabledDates;
  const year = activeDate.getFullYear();
  const month = activeDate.getMonth();
  const activeDateStr = toISODate(activeDate);
  const todayStr = toISODate(new Date());
  const start = startOfMonth(month, year);
  const end = endOfMonth(month, year);
  const startDayOfWeek = (start.getDay() + 7 - firstDayOfWeek) % 7
  const endDate = end.getDate()

  // Builds an array of numbers for as many full weeks needed to display the month
  // Each number represents the number of days into the month
  // if a month starts after 1st day of the week, first numbers will be negative
  const totalDays = 7 * Math.ceil((endDate + startDayOfWeek) / 7)
  const monthDay1 = -startDayOfWeek + 1
  const days = []
  for (let i = 0; i < totalDays; i++) {
    days[i] = monthDay1 + i
  }

  const dayData = days.map((day) => {
    const date = new Date(year, month, day, 0, 0, 0)
    const dateStr = toISODate(date)
    const isCurrentDate = dateStr === todayStr
    const labelDescriptors = []
    if (isCurrentDate) {
      labelDescriptors.push(lang.current)
    }
    if (dateStr === min) {
      labelDescriptors.push(lang.minimum)
    }
    if (dateStr === max) {
      labelDescriptors.push(lang.maximum)
    }
    const labelDescription = labelDescriptors.length ? ` (${labelDescriptors.join()})` : '';
    const label = `${toReadableDate(date, lang)}${labelDescription}`;
    const outOfRange = (min && min > dateStr) || (max && max < dateStr)
    const disabled = outOfRange || isDateDisabled(<any[]>disabledDates, date, dateStr)
    const current = isCurrentDate ? 'date' : null;
    const action = outOfRange ? false : disabled ? 'goto' : 'select';
    const active = activeDateStr === dateStr;
    const selected = selectedDate === dateStr;
    const isOutsideOfMonth = month !== date.getMonth();
    const isDueDate = dateStr === dueDate;
    return {
      date,
      dateStr,
      label,
      isOutsideOfMonth,
      isDueDate,
      isCurrentDate: current,
      selected,
      attributes: {
        role: "button",
        tabindex: action ? active ? '0' : '-1' : undefined,
        'aria-pressed': selected ? 'true' : undefined,
        'aria-label': label,
        'aria-disabled': disabled ? 'true' : undefined,
        'aria-current': current ? 'date' : undefined,
        'data-action': action || undefined,
        'data-date': dateStr
      }
    }
  }).reduce((acc, day, i) => {
    if ((i % 7) === 0) {
      acc.push([]);
    }
    const week = acc[acc.length - 1];
    week.push(day);
    return acc
  }, [])

  return dayData;
}

function actionDisabled(activeDate: Date, action: string, min: any, max: any, locale: any): boolean {
  let disabled = false;
  const minDateStr: string = ensureDateString(min);
  const maxDateStr: string = ensureDateString(max);
  if (min || max) {
    const goToDate = calcGoToDate(activeDate, action, locale);
    switch (action) {
      case '-year':
        disabled = min && (`${goToDate.getFullYear()}-12-31` < minDateStr);
        break;

      case '+year':
        disabled = max && (`${goToDate.getFullYear()}-01-01` > maxDateStr);
        break;

      case '-month':
        const endOfMonth = min && toISODate(new Date(goToDate.getFullYear(), goToDate.getMonth() + 1, 0));
        disabled = min && minDateStr > endOfMonth;
        break;

      case '+month':
        const startOfMonth = max && toISODate(new Date(goToDate.getFullYear(), goToDate.getMonth(), 1));
        disabled = max && maxDateStr < startOfMonth;
        break;
    }
  }
  return !!disabled;
}

function ensureDateString(date: any): string {
  if (typeof date === 'string') {
    return date;
  } else if (date instanceof Date) {
    return toISODate(<Date>date);
  }
  return null;
}
