import whenReady from './when-dom-ready'

let observer: MutationObserver;
let nextId = 1;
interface DomChangeListener {
  id: number,
  selector: string,
  onNodeAdded: Function,
  onNodeRemoved: Function
};
const listeners: DomChangeListener[] = [];
const deferredNodes: { addedNodes: NodeList, removedNodes: NodeList }[] = [];
let deferredNodesRemoved: Node[] = [];

function onDomChanges(selector: string, onNodeAdded: Function, onNodeRemoved?: Function) {
  const id = nextId++
  listeners.push({ id, selector, onNodeAdded, onNodeRemoved })

  whenReady(() => {
    if ('function' === typeof onNodeAdded) {
      const existingNodes = document.body.querySelectorAll(selector)
      onChildListUpdates(existingNodes)
    }

    if (!observer) {
      observer = new MutationObserver(observerCallback)
      observer.observe(document.body, { childList: true, subtree: true })
    }
  })

  const cancel = () => {
    for (let i = 0; i < listeners.length; i++) {
      if (listeners[i].id === id) {
        listeners.splice(i, 1)
        break;
      }
    }
  }
  return cancel
}

function resumeAutoEnhance() {
  if (typeof window !== 'undefined') {
    (window as any).tdsPauseAutoEnhance = false;
    while( deferredNodes.length) {
      const {addedNodes, removedNodes} = deferredNodes.shift();
      onChildListUpdates(addedNodes, removedNodes);
    }
  }
}

function observerCallback(mutationList: MutationRecord[]) {
  ////////////////////////////////////////////////////////////////////////
  // NOTE: The following is used by jest.setup.unit.js to support unit tests. Do not remove.
  // This is the onDomChangesMutationObserver
  ////////////////////////////////////////////////////////////////////////
  mutationList.forEach((mutation) => {
    if (mutation.type === 'childList') {
      onChildListUpdates(mutation.addedNodes, mutation.removedNodes)
    }
  })
}

function onChildListUpdates(addedNodes: NodeList, removedNodes?: NodeList) {
  if (typeof window !== 'undefined' && (window as any).tdsPauseAutoEnhance) {
    deferredNodes.push({addedNodes, removedNodes});
    return;
  }

  const nodesAdded: Node[] = [];
  for (let i = 0; i < addedNodes.length; i++) {
    const node = addedNodes[i];
    const removedIX = deferredNodesRemoved.indexOf(node);
    if (removedIX > -1) {
      deferredNodesRemoved.splice(removedIX, 1);
    } else {
      nodesAdded.push(node);
    }
  }
  if (removedNodes) {
    for (let i = 0; i < removedNodes.length; i++) {
      deferredNodesRemoved.push(removedNodes[i]);
    }
  }

  // handle the adds first, defer the removed a bit to be sure they just weren't moved.
  listeners.forEach(({ selector, onNodeAdded }) => {
    if (('function' === typeof onNodeAdded) && addedNodes && addedNodes.length) {
      dispatchUpdates(selector, nodesAdded, onNodeAdded);
    }
  });

  window.setTimeout(() => {
    const nodesRemoved = [].concat(deferredNodesRemoved);
    deferredNodesRemoved = [];
    listeners.forEach(({ selector, onNodeRemoved }) => {
      if (('function' === typeof onNodeRemoved) && nodesRemoved && nodesRemoved.length) {
        dispatchUpdates(selector, nodesRemoved, onNodeRemoved);
      }
    });
  }, 500);
}

function dispatchUpdates(selector: string, nodeList: Node[], callback: Function) {
  const matchedNodes: Node[] = [];
  for (let i = 0; i < nodeList.length; i++) {
    const node = nodeList[i];
    if (node instanceof HTMLElement) {
      if (node.matches(selector)) {
        matchedNodes.push(node);
      }
      const childNodes = node.querySelectorAll(selector);
      for (let j = 0; j < childNodes.length; j++) {
        matchedNodes.push(childNodes[j]);
      }
    }
  }
  matchedNodes.forEach(node => callback(node));
}

export { onDomChanges as default, resumeAutoEnhance };
