import { EventListeners } from '../../utilities/EventListeners';
import { hasShadowDom } from '../../utilities/helpers';
import { FILE_UPLOAD_CLASSES } from '../../utilities/constants';
import { FileUploadComponent } from './FileUploadComponent';
import { addFilesToList } from './FileListBehavior';

const FILE_INPUT_PROP = "input[type='file']";
const FILE_UPLOAD_BUTTON = `[data-trigger="file-upload"], ${FILE_UPLOAD_CLASSES.BUTTON}`;
const FILE_UPLOAD_DROPAREA = `[data-droparea], .${FILE_UPLOAD_CLASSES.DROPAREA}`;


export function bindFileUpload(fileUpload: FileUploadComponent): Function {
  const { host, el } = fileUpload;
  const root = hasShadowDom(host) ? host.shadowRoot : el;

  // event listeners
  const eventListeners = new EventListeners();

  //file picker events
  bindFilePicker(root, fileUpload, eventListeners);

  const unbind = () => eventListeners.removeListeners();
  return unbind;
}

function bindFilePicker(root: HTMLElement | ShadowRoot, fileUpload: FileUploadComponent, eventListeners: EventListeners) {
  //picker elements
  const fileInput: HTMLElement = root.querySelector(`${FILE_INPUT_PROP}`);
  const fileButton: HTMLElement = root.querySelector(`${FILE_UPLOAD_BUTTON}`);
  const dropArea: HTMLElement = root.querySelector(`${FILE_UPLOAD_DROPAREA}`);

  //button click listener
  if (!!fileButton) {
    eventListeners.addListener(fileButton, 'click', fileButtonListener(fileInput));
  }

  //file input listener
  if (!!fileInput) {
    eventListeners.addListener(fileInput, 'change', inputChangeHandler(fileUpload));
  }
  //drag and drop listeners
  if (!!dropArea) {
    eventListeners.addListener(dropArea, 'dragenter', dragEnterHandler);
    eventListeners.addListener(dropArea, 'dragleave', dragLeaveHandler);
    eventListeners.addListener(dropArea, 'dragover', dragOverHandler);
    eventListeners.addListener(dropArea, 'drop', dropHandler(fileUpload));
  }

  // alert dismissed event handler
  eventListeners.addListener(root, 'alert.dismissed', alertDismisHandler(fileUpload));  
}

//redirects button click to input to open file explorer
function fileButtonListener(fileInput: HTMLElement): EventListener {
  return ((e) => {
    e.preventDefault();
    e.stopPropagation();
    fileInput.click();
  })
}

//retrives file list from file input
function inputChangeHandler(fileUpload: FileUploadComponent): EventListener {
  return ((e: Event) => {
    const targetInput: HTMLInputElement = e.target as HTMLInputElement;
    const files: FileList = targetInput.files;
    addFilesToList(fileUpload, files);
    // clearing the input cache
    targetInput.value = "";
  })
}

//handles behavior when user drags into area
function dragEnterHandler(e: DragEvent) {
  if (e.target === e.currentTarget) {
    this.classList.add('active--drag');
  }
}

//handles behavior when user drags out of area
function dragLeaveHandler(e: Event) {
  if (e.target === e.currentTarget) {
    this.classList.remove('active--drag');
  }
}

//handles behavior when user drags over area
function dragOverHandler(e: DragEvent) {
  e.preventDefault();
  e.stopPropagation();
  this.classList.add('active--drag');
}

//handles behavior when user drops over area
function dropHandler(fileUpload: FileUploadComponent): EventListener {
  return ((e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const dropArea = e.currentTarget as HTMLElement;
    dropArea.classList.remove('active--drag');
    const data = e.dataTransfer;
    const files: FileList = data.files;
    if (files.length) {
      addFilesToList(fileUpload, files);
    }
  })
}

// reset alert error list when alert is dismissed
function alertDismisHandler(fileUpload: FileUploadComponent): EventListener {
  return (() => {
    const { store } = fileUpload;
    store.update((s) => {
      return { ...s, _errorMsg: '', _errorList: []}
    })
  })
}