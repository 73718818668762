import { store, Store } from '../../utilities/store';

export interface MultiSelectState {
  options: any[]
}

export function createStore() : Store<MultiSelectState> {
  return store({
    options: []
  })
}