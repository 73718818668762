import { parseDate } from "../../../utilities/date-utils"

export type DateFormatterOptions = {
  preset?: 'm/d/yy' | 'm/d/yyyy' | 'mm/dd/yy' | 'mm/dd/yyyy' | 'shortmonth' | 'longmonth'
  weekday?: 'narrow' | 'short' | 'long'
  year?: 'numeric' | '2-digit'
  month?: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long'
  day?: 'numeric' | '2-digit'
}

const defaultPreset = 'mm/dd/yyyy';

export class DateFormatter {
  options: DateFormatterOptions;

  constructor(options?: DateFormatterOptions) {
    const defaultOptions: DateFormatterOptions = {
      preset: defaultPreset
    }
    this.options = options || defaultOptions;
  }

  format(value: Date | string | number, locale?: string) {
    if (!value ) {
      return '';
    }
    const { preset } = this.options
    const presetMap: {[key in DateFormatterOptions["preset"]]: any} = {
      'm/d/yy': { month: 'numeric', day: 'numeric', year: '2-digit' },
      'm/d/yyyy': { month: 'numeric', day: 'numeric', year: 'numeric' },
      'mm/dd/yy': { month: '2-digit', day: '2-digit', year: '2-digit' },
      'mm/dd/yyyy': { month: '2-digit', day: '2-digit', year: 'numeric' },
      'shortmonth': { month: 'short', day: 'numeric', year: 'numeric' },
      'longmonth': { month: 'long', day: 'numeric', year: 'numeric' }
    }
    let formatOptions = presetMap[preset] || {};
    ['weekday', 'year', 'month', 'day'].forEach(key => {
      if ((this.options as any)[key]) {
        formatOptions[key] = (this.options as any)[key];
      }
    });
    if (Object.keys(formatOptions).length === 0) {
      formatOptions = presetMap[defaultPreset];
    }

    let date: Date;;
    if (typeof value === 'string') {
      // todo: invalid strings like -44- parse as a valid date, (See validator)
      date = parseDate(value, locale);
    } else if (typeof value === 'number') {
      date = new Date(value);
    } else {
      date = value;
    }

    // if date is null, it's an invalid date. Return value as is.
    return date ? new Intl.DateTimeFormat(locale || 'default', formatOptions).format(date) 
      : value.toString();
  }
}
