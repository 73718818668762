import { AbstractCellEditor } from "./AbstractCellEditor";
import { CellEditorComponent, CellEditorRenderData } from "./CellEditorComponent";

export type YesNoEditorValues = [string, string] | {value: any, label: string}[];

export class CheckboxCellEditor extends AbstractCellEditor implements CellEditorComponent {
  yes: {value: any, label: string};
  no: {value: any, label: string};
  constructor(options: YesNoEditorValues | string) {
    super();
    const [yes, no] = buildCheckboxOptions(options);
    this.yes = yes;
    this.no = no;
  }

  render({ cell, cellValue, initialValue, container, columnHeader }: CellEditorRenderData): void {
    const { yes } = this;
    const label = cell.ownerDocument.createElement('label');
    label.classList.add('tds-data-table__cellEditor--checkbox');
    label.innerHTML = `
      <input type="checkbox"/>
      <span class="tds-data-table__cellEditor--checkbox-icon"></span>
    `
    const input = this.editorElement = label.querySelector('input') as HTMLInputElement;
    let checked = cellValue === yes.value || (!!yes.value && yes.value.toString() === cellValue);
    if (initialValue && yes.label) {
      // NOTE: The intention here is to let user type the first letter of the Yes|true value to set it to true 
      checked = initialValue.toLowerCase()[0] === yes.label.toLowerCase()[0];
    } else if (initialValue === '') {
      checked = false;
    }
    input.checked = checked;
    input.addEventListener('click', this.reportUpdate);
    input.setAttribute('aria-label', columnHeader);
    container.appendChild(label);
  }

  getValue() {
    const { editorElement } = this;
    const checked = !!editorElement && (editorElement as HTMLInputElement).checked;
    return checked ? this.yes.value : this.no.value;
  }

  setValue(value: any) {
    const { editorElement } = this;
    if (editorElement) {
      (editorElement as HTMLInputElement).checked = this.yes.value == value;
    }
  }

  getDisplayValue() {
    const { editorElement, yes, no } = this;
    const checked = editorElement ? (editorElement as HTMLInputElement).checked : false;
    return checked ? yes.label : no.label;
  }
}

function buildCheckboxOptions(options: any): {value: any, label: string}[] {
  if (!options) {
    return [{
      value: true,
      label: 'True'
    },{
      value: false,
      label: 'False'
    }];
  }

  try {
    let yesNoOptions = (typeof options === 'string' ? JSON.parse(options) : options) as any;
    let valid = Array.isArray(yesNoOptions) && yesNoOptions.length == 2;
    if (valid) {
      yesNoOptions = yesNoOptions.map((option: any, index : number) => {
        option = typeof option === 'string' ? {value: index === 0, label: option} : option
        valid = valid && option.hasOwnProperty('value') && option.hasOwnProperty('label');
        return option;
      })
    }
    if (valid) {
      return yesNoOptions;
    }

  } catch { }
  throw new Error(`editor options are not in a valid format for checkbox: ${options}`);
}
