import { enableDropdownMenu } from './dropdownMenuBehavior';

class DropdownMenu {
  _disableDropdownMenu: Function;

  constructor (element: HTMLSelectElement) {
    this._disableDropdownMenu = enableDropdownMenu(element);
  }

  /**
   * Detaches from the element, removes event listeners, and frees resources.
   */
  destroy () {
    if (this._disableDropdownMenu) {
      this._disableDropdownMenu();
    }
    delete this._disableDropdownMenu;
  }
}

const selector = 'select[data-dropdown-menu="true"]'
document.addEventListener('focusin', e => {
  const el = <HTMLSelectElement>e.target
  if (el.matches(selector)) {
    // TODO: Look into this. we never actually call DropdownMenu, which
    // means listeners are never removed. May not be a memory-leak issue though 
    enableDropdownMenu(el);
  }
})

export { DropdownMenu }