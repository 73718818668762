import onDOMChanges from '../../utilities/onDOMChanges';
import { bindCombobox } from './comboboxBehavior';
import { ComboboxComponent } from './ComboboxComponent';
import { ComboboxConfig } from './ComboboxConfig';
import { ComboboxState, createStore } from './ComboboxState';
import { renderOptionText } from './comboboxOptionsRenderer';
import { instances } from '../../utilities/instances';
import { Store } from "../../utilities/store";
import { NAMESPACE, CSS_NS } from '../../utilities/constants';
import {
  configFromDataAttributes,
  convertStringRefs,
  isScrollable,
  maintainScrollVisibility,
  watchDataAttributeChange,
  bestPositionElement,
  getLabelFor
} from '../../utilities/helpers';
import doWhileEventing from '../../utilities/doWhileEventing';
import { createCustomEvent } from '../../utilities/customEvent';
import devConsole from '../../utilities/devConsole';

const ENHANCED_FLAG = 'enhancedCombobox';
const INSTANCE_KEY = `${NAMESPACE}Combobox`;
const COMBOBOX_CLASS = `${CSS_NS}combobox`;
const COMBOBOX_LIST_HIDDEN_CLASS = `${COMBOBOX_CLASS}--list-hidden`;
const COMBOBOX_LIST_CLASS = `${COMBOBOX_CLASS}__list`;
const COMBOBOX_OPTION_CLASS = `${COMBOBOX_CLASS}__option`;
const COMBOBOX_OPTION_STATUS_CLASS = `${COMBOBOX_CLASS}__list-status`
const PATTERN_SELECTOR = `.${COMBOBOX_CLASS}`;

const defaultConfig: ComboboxConfig = {
  optionName: 'name',
  optionValue: 'value'
}

// todo: validation? is active index and/or input.value sufficient?   

let nextId = 0;

class _ComboboxInstance implements ComboboxComponent {
  host: HTMLElement;
  root: HTMLElement;
  store: Store<ComboboxState>;
  state: ComboboxState;
  id: string;
  firstRender = true;
  undoWhileEventing: Function;

  _dataConfig: ComboboxConfig;
  _updateConfig: ComboboxConfig = {};
  _deprecationWarned: { [prop: string]: boolean } = {};

  onDestroy: Function[] = [];
  updateProperty: (property: string, value: any) => void;

  constructor(element: HTMLElement) {
    this.root = this.host = element;
    this.id = element.id || `__tds-combobox-${++nextId}`;
    this.store = createStore();
    element.dataset[ENHANCED_FLAG] = "true";
    const { unbind, updateProperty } = bindCombobox(this);
    const unsubscribe = this.store.subscribe(this.onStateUpdate.bind(this));
    const unwatch = watchDataAttributeChange(element, (configData) => {
      this._dataConfig = undefined;
      this.updateInputAttributes(configData);
      this.render();
    });
    this.onDestroy = [unbind, unsubscribe, unwatch];
    this.updateProperty = updateProperty;
    instances.set(element, INSTANCE_KEY, this);
  }

  onStateUpdate(state: ComboboxState) {
    this.state = state;
    this.render();
  }

  dispatchEvent(eventType: string, detail: any): boolean {
    return this.host.dispatchEvent(createCustomEvent(eventType, { detail: detail }));
  }

  render() {
    const { firstRender, state, input, host } = this;
    const { showList } = state;
    const list = this.renderList();
    const { label, labelledby } = getLabelFor(input);
    if (label) {
      list.setAttribute('aria-label', label);
    } else {
      list.removeAttribute('aria-label');
    }
    if (labelledby) {
      list.setAttribute('aria-labelledby', labelledby);
    } else {
      list.removeAttribute('aria-labelledby');
    }
    const activeOption = this.renderOptions(list);
    this.renderOptionStatus(list);
    this.renderLiveRegion();
    input.setAttribute('aria-expanded', `${showList}`);
    if (activeOption) {
      input.setAttribute('aria-activedescendant', activeOption.id);
    } else {
      input.removeAttribute('aria-activedescendant');
    }
    if (firstRender) {
      this.setupInput();
      this.firstRender = false;
    }
    host.classList[showList ? 'remove' : 'add'](COMBOBOX_LIST_HIDDEN_CLASS);
    if (showList && activeOption && isScrollable(list)) {
      maintainScrollVisibility(activeOption, list);
    }

    if (showList) {
      if (this.undoWhileEventing) {
        this.positionPopover();
      } else {
        this.undoWhileEventing = doWhileEventing(window, 'resize scroll', this.positionPopover.bind(this));
      }
    } else if (this.undoWhileEventing) {
      this.undoWhileEventing();
      this.undoWhileEventing = null;
    }
  }

  renderList(): HTMLElement {
    const { id, host } = this;
    let list: HTMLElement;
    while (!(list = host.querySelector(`.${COMBOBOX_LIST_CLASS}`) as HTMLElement)) {
      host.insertAdjacentHTML('beforeend', `<div id="${id}__listbox" role="listbox" class="${COMBOBOX_LIST_CLASS}"></div>`);
    }
    return list;
  }

  renderOptions(listbox: HTMLElement): HTMLElement | null {
    const { state, id } = this;
    const { options, activeIndex } = state;
    let optionEls = this.optionElements;
    if (optionEls.length !== options.length) {
      listbox.innerHTML = options.map((_, i: number) => `<div role="option" id="${id}__option-${i}" class="${COMBOBOX_OPTION_CLASS}"></div>`).join('');
      optionEls = this.optionElements;
    }

    options.forEach((option: any, i: number) => {
      const optionEl = optionEls[i];
      const isActive = i === activeIndex;
      renderOptionText(optionEl, option, isActive, this, this.state);
      if (isActive) {
        optionEl.setAttribute('aria-selected', 'true');
      } else {
        optionEl.removeAttribute('aria-selected');
      }
    });
    const selectedOption = optionEls[activeIndex];
    return selectedOption;
  }

  renderOptionStatus(listbox: HTMLElement) {
    const { optionsStatus } = this.state;
    let optionsStatusEl = listbox.querySelector(`.${COMBOBOX_OPTION_STATUS_CLASS}`);
    if (optionsStatus) {
      if (!optionsStatusEl) {
        optionsStatusEl = document.createElement('div');
        optionsStatusEl.classList.add(COMBOBOX_OPTION_STATUS_CLASS);
        listbox.appendChild(optionsStatusEl);
      }
      optionsStatusEl.textContent = optionsStatus;
    } else if (optionsStatusEl) {
      optionsStatusEl.parentNode.removeChild(optionsStatusEl);
    }
  }

  renderLiveRegion() {
    const { host, state } = this;
    const { ariaLiveMessage = '' } = state;
    let liveRegion: HTMLElement;
    while (!(liveRegion = host.querySelector('[aria-live]'))) {
      host.insertAdjacentHTML('beforeend', `
      <div aria-live="polite" role="status" class="${CSS_NS}sr-only">
      </div>
      `)
    }
    liveRegion.textContent = ariaLiveMessage;
  }

  positionPopover() {
    const COMBOBOX_LIST_TOP_CLASS = `${COMBOBOX_CLASS}--list-top`;
    const { state, listElement, host } = this;
    const { showList, activeIndex } = state;
    const sizes = ['9', '8', '7', '6', '5'];
    //build an array of classes
    // first part is no class plus the class for each size, then repeat with COMBOBOX_LIST_TOP_CLASS
    // ['', '*--9', '*--8], (etc), '*--top', '*--top *--9', setupInput'*--top *--8', (etc)] 
    const classes = [].concat(
      '',
      sizes.map(s => `${COMBOBOX_CLASS}--list-${s}`),
      COMBOBOX_LIST_TOP_CLASS,
      sizes.map(s => `${COMBOBOX_LIST_TOP_CLASS} ${COMBOBOX_CLASS}--list-${s}`)
    );

    bestPositionElement(listElement, classes, host);

    if (showList && isScrollable(listElement)) {
      const activeOption = this.optionElements[activeIndex];
      if (activeOption) {
        maintainScrollVisibility(activeOption, listElement);
      }
    }
  }

  setupInput() {
    const { host, input } = this;
    const listbox = host.querySelector('[role="listbox"]')
    input.setAttribute('role', 'combobox');
    input.setAttribute('aria-controls', listbox.id);
    input.setAttribute('aria-owns', listbox.id);
    input.setAttribute('aria-haspopup', listbox.getAttribute('role'));
    this.setAriaAutocomplete();
  }

  setAriaAutocomplete() {
    const { config, input } = this;
    const { nofilter, inlineComplete } = config;
    const filter = !nofilter;
    const ariaAutocomplete = filter && inlineComplete ? 'both' : filter ? 'list' : inlineComplete ? 'inline' : 'none';
    input.setAttribute('aria-autocomplete', ariaAutocomplete);
  }

  updateInputAttributes(config: any) {
    const { input } = this;
    if (input) {
      const propsMap: { [propr: string]: string } = {
        label: 'aria-label',
        labelledby: 'aria-labelledby',
        autocomplete: 'autocomplete'
      }
      Object.keys(config).forEach(prop => {
        const attr = propsMap[prop];
        if (attr) {
          const value = config[prop];
          if (!!value) {
            input.setAttribute(attr, value.toString());
          } else if (input.hasAttribute(attr)) {
            input.removeAttribute(attr);
          }
        }
      })
    }
  }

  updateConfig(config: ComboboxConfig) {
    const updateConfig = this._updateConfig = { ...this._updateConfig, ...this.handleDeprecatedProperties(config) };
    this.updateInputAttributes(updateConfig);
    this.render();
    this.setAriaAutocomplete();
  }

  handleDeprecatedProperties(config: any): ComboboxConfig {
    const { _deprecationWarned: deprecationWarned } = this;
    const deprecatedProperties: { [prop: string]: string } = {
      'autoComplete': 'inlineComplete',
      'inputLabel': 'label',
      'inputLabelledby': 'labelledby'
    }
    const toDataAttr = (attr: string) => 'data-' + attr.replace(/[A-Z]/g, (letter: string) => `-${letter.toLowerCase()}`);
    Object.keys(deprecatedProperties).forEach(prop => {
      const dataProp = toDataAttr(prop);
      const useProp = deprecatedProperties[prop];
      const useDataProp = toDataAttr(useProp);

      if (config.hasOwnProperty(prop)) {
        if (!deprecationWarned[prop]) {
          // display as an error because less ignorable than warnings
          devConsole.error(`The configuration property "${prop}" ("${dataProp}") is deprecated. Use "${useProp}" ("${useDataProp}") instead.`)
          deprecationWarned[prop] = true;
        }
        config[useProp] = config[useProp] ?? config[prop];
      }
    })
    return config as ComboboxConfig;
  }

  get config(): ComboboxConfig {
    if (!this._dataConfig) {
      this._dataConfig = this.handleDeprecatedProperties(configFromDataAttributes(this.host) as ComboboxConfig);
      convertStringRefs(this._dataConfig, ['datasource', 'optionRenderer', 'setupInput'], 'function');
    }
    return { ...defaultConfig, ...this._dataConfig, ...this._updateConfig };
  }

  get input(): HTMLInputElement {
    return this.host.querySelector('input') as HTMLInputElement;
  }

  get listElement(): HTMLElement {
    return this.host.querySelector(`.${COMBOBOX_LIST_CLASS}`) as HTMLElement;
  }

  get optionElements(): HTMLElement[] {
    return Array.from(this.host.querySelectorAll(`.${COMBOBOX_LIST_CLASS} [role="option"]`)) as HTMLElement[];
  }

  get inputValue(): string {
    let value = '';
    if (!this.updateProperty) {
      // bind hasn't returned yet, so this call is from bindCombobox
      // check if configured with data attribute
      value = this.config.inputValue
    }
    if (!value) {
      const { input } = this;
      if (input) {
        value = input.value;
      }
    }
    return value;
  }

  set inputValue(value: string) {
    this.updateProperty('inputValue', value);
  }

  get selectedValue(): string {
    let selectedValue = '';
    if (!this.updateProperty) {
      // bind hasn't returned yet, so this call is from bindCombobox
      // check if configured with data attribute
      selectedValue = this.config.selectedValue
    }
    if (!selectedValue) {
      const selectedOption = this.state && this.state.selectedOption;
      if (selectedOption) {
        selectedValue = selectedOption.value;
      }
    }
    return selectedValue;
  }

  set selectedValue(value: string) {
    this.updateProperty('selectedValue', value);
  }

  get selectedItem(): any {
    const selectedOption = this.state && this.state.selectedOption;
    let selectedItem = selectedOption && (selectedOption.item || selectedOption);
    if (!selectedItem && !this.updateProperty) {
      // bind hasn't returned yet, so this call is from bindCombobox
      // check if passed in configuration 
      selectedItem = this.config.selectedItem
    }
    return selectedItem;
  }

  set selectedItem(item: any) {
    this.updateProperty('selectedItem', item);
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }

    if (this.undoWhileEventing) {
      this.undoWhileEventing();
      this.undoWhileEventing = null;
    }

    if (this.root) {
      delete this.root.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.host, INSTANCE_KEY);
    this.root = this.host = this.store = null;
  }

}

class Combobox {
  _instance: _ComboboxInstance;
  constructor(element: HTMLElement, config?: ComboboxConfig) {
    this._instance = <_ComboboxInstance>instances.get(element, INSTANCE_KEY) || new _ComboboxInstance(element);
    if (config) {
      this.updateConfig(config);
    }
  }

  updateConfig(config: ComboboxConfig) {
    return this._instance.updateConfig(config);
  }

  get inputValue() {
    return this._instance.inputValue;
  }

  set inputValue(value: string) {
    this._instance.inputValue = value;
  }

  get selectedValue() {
    return this._instance.selectedValue;
  }

  set selectedValue(value: string) {
    this._instance.selectedValue = value;
  }

  get selectedItem() {
    return this._instance.selectedItem;
  }

  set selectedItem(item: any) {
    this._instance.selectedItem = item;
  }

  destroy() {
    return this._instance.destroy();
  }
}

onDOMChanges(`${PATTERN_SELECTOR}`,
  function onPatternAdded(element: HTMLElement) {
    if (!element.dataset[ENHANCED_FLAG]) {
      new Combobox(element)
    }
  },
  function onPatternRemoved(element: HTMLElement) {
    if (element.dataset[ENHANCED_FLAG] === "true") {
      new Combobox(element).destroy();
    }
  }
);

export { Combobox }