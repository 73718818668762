import { 
  EXPANSION_ROW_DATA_ATTR, 
} from './dataTableConstants';

/**
 * Indicate whether or not the element is a child of a nested table inside of current table
 * @param el The element in question
 * @param root The root of DataTableComponent
 * @returns True if the element is a child of a nested table; otherwise false
 */
export function inNestedTable(el: HTMLElement, root: HTMLElement): boolean {
  // NOTE: This may have to change when used as a Web Component depending on what root is
  // For now, root is a table, so...
  return root.closest('table') !== el.closest('table');
}
/**
 * Returns all elements that match the query selector that are children of the current
 * table and not of a nested tables
 * @param parent The element to call `querySelectorAll` from. Defaults to `root`.
 * @param selector The query selector
 * @returns An array of elements that match the query that are in nested tables
 */
export function getElementsFromThisTable(parent: HTMLElement, selector: string): HTMLElement[] {
  const immediateTable = parent.closest('table');
  const elements = Array.from(parent.querySelectorAll(selector)) as HTMLElement[];
  return elements.filter(element => immediateTable === element.closest('table'));
}

/**
 * Returns an array of all expansion rows adjacent to a row
 * @param tr The expandable row
 */
export function getExpansionRows(tr: HTMLElement): HTMLElement[] {
  const rows: HTMLElement[] = [];
  let sibling = tr.nextElementSibling;
  while (sibling && sibling.matches(`[${EXPANSION_ROW_DATA_ATTR}]`)) {
    rows.push(sibling as HTMLElement);
    sibling = sibling.nextElementSibling;
  }
  return rows;
}

