import { parseDate, toISODate } from '../../utilities/date-utils';
import { getLocale } from '../../utilities/localization';
import { EventListeners } from '../../utilities/EventListeners';
import { createCustomEvent } from '../../utilities/customEvent';
import { normalizeKey } from '../../utilities/keyboard';
import { CalendarComponent } from './CalendarComponent';
import { calcGoToDate } from './calcGoToDate';
import { CalendarState } from './calendarState';

export function bindCalendar(calendar: CalendarComponent) : Function {
  const { el } = calendar;
  const eventListeners = new EventListeners();
  eventListeners.addListener(el, 'keydown', keyHandler(calendar));
  eventListeners.addListener(el, 'click', actionHandler(calendar));

  return () => {
    eventListeners.removeListeners();
  }
}

export function focusOnActiveDate(calendarEl: HTMLElement | ShadowRoot) {
  // use timeout to allow render to complete
  setTimeout(() => {
    const el = ((<HTMLElement>calendarEl).attachShadow && (<HTMLElement>calendarEl).shadowRoot) || calendarEl;
    const focusOn: HTMLElement = el.querySelector(`[data-date][tabindex="0"]`) || el.querySelector(`[data-action]`);
    if (focusOn) {
      focusOn.focus();
    }
  }, 100); //timeout needs to wait for render timeout as well
}

export function setActiveDate(calendar: CalendarComponent, activeDate: Date) {
  calendar.store.update((v:CalendarState) => {
    return {...v,  activeDate: constrainActiveDate(calendar, activeDate) }
  });
}


////////////////////////////////////////////////////////////////////////////////
// - event handlers

function keyHandler(calendar : CalendarComponent) : EventListener {
  return ((event : KeyboardEvent) => {
    let goToDateAction = '';

    const key = normalizeKey(event); 
    switch (key) {
      case ' ':
      case 'Enter':
        const target = <HTMLElement>event.target;
        const action = target.getAttribute('data-action')
        if (action === 'select') {
          const dateStr = target.getAttribute('data-date')
          selectDate(calendar, dateStr)
          event.preventDefault() //ensure no side effects from any change of focus
        }
        return

      case 'PageDown':
        goToDateAction = event.altKey ? '+year' : '+month'
        break

      case 'PageUp':
        goToDateAction = event.altKey ? '-year' : '-month'
        break

      case 'Home':
        goToDateAction = '-eow'
        break

      case 'End':
        goToDateAction = '+eow'
        break

      case 'ArrowRight':
        goToDateAction = event.metaKey ? '+eow' : '+day'
        break

      case 'ArrowLeft':
        goToDateAction = event.metaKey ? '-eow' : '-day'
        break

      case 'ArrowUp':
        goToDateAction = '-week'
        break

      case 'ArrowDown':
        goToDateAction = '+week'
        break

      default:
  if (key.length === 1) {
    const btn : HTMLElement = calendar.el.querySelector(`[accessKey="${key.toLowerCase()}"]`)
    if (btn) {
      btn.click();
    }
  }
  return;
    }

    if (goToDateAction) {
      event.preventDefault();
      goToDate(calendar, goToDateAction);
      focusOnActiveDate(calendar.el);
    }
  })
}

function actionHandler(calendar: CalendarComponent) : EventListener {
  const ACTION_ATTR = 'data-action'
  return ({ target }) => {
    const actionButton = (<HTMLElement>target).closest(`[${ACTION_ATTR}]`)
    if (actionButton) {
      const action = actionButton.getAttribute(ACTION_ATTR);
      const disabled = actionButton.getAttribute('aria-disabled') === 'true';
      switch (action) {
        case 'select':
          if (!disabled) {
            selectDate(calendar, actionButton.getAttribute('data-date'))
          }
          break

        default:
          if (!disabled || action === 'goto') {
            goToDate(calendar, action, action === 'goto' ? actionButton.getAttribute('data-date') : null)
          }
      }
    }
    else {
      focusOnActiveDate(calendar.el);
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////

function goToDate(calendar: CalendarComponent, action: string, dateStr?: string) {
  const { activeDate } = calendar.state;
  const newActiveDate = calcGoToDate(activeDate, action, getLocale(calendar.el), dateStr)
  if (newActiveDate !== activeDate) {
    if (action.substring(1) === 'week' && constrainActiveDate(calendar, newActiveDate) !== newActiveDate) {
      // keyboard navigation to previous or next week bumped into dates not in range
      // ignore action (otherwise, arrow up|down unexpectedly goes to start/end of week)
      return
    }
    setActiveDate(calendar, newActiveDate)
  }
}

function constrainActiveDate(calendar: CalendarComponent, activeDate: Date) {
  const {min, max} = calendar.config
  const locale = getLocale(calendar.el);
  const { format } = locale.format
  const minDateStr: string = ensureDateString(min);
  const maxDateStr: string = ensureDateString(max);
  if (min || max ) {
    const activeDateStr = toISODate(activeDate);
    if (maxDateStr && activeDateStr > maxDateStr) {
      activeDate = parseDate(maxDateStr, format)
    }
    else if (minDateStr && activeDateStr < minDateStr) {
      activeDate = parseDate(minDateStr, format)
    }
  }
  return activeDate
}

function ensureDateString(date: any) : string {
  if (typeof date === 'string') {
    return date;
  } else if (date instanceof Date) {
    return toISODate(<Date>date);
  }
  return null;
}


function selectDate(calendar: CalendarComponent, dateStr: string) {
  const [year, month, day] = dateStr.split('-')
  const selectedDate = new Date(parseInt(year), parseInt(month) -1, parseInt(day))
  calendar.selectedDate = dateStr;
  calendar.el.dispatchEvent(createCustomEvent('selected', {detail: {date: selectedDate }}));
  setActiveDate(calendar, selectedDate);
}
