import { NavigationComponent } from './NavigationComponent';
import { EventListeners } from '../../utilities/EventListeners';

const PACE = 2; //ms per pixel height, increase to make slower
const transitionStyle = {
  overflow: 'hidden',
  height: '',
  opacity: '',
  transitionProperty: 'opacity, height',
  transitionDuration: '',
  transitionTimingFunction: ''
}

export function bindNavigation(navigation: NavigationComponent): Function {
  const { host } = navigation;
  const eventListeners = new EventListeners();

  eventListeners.addListener(host, 'click', onClick(navigation));
  eventListeners.addListener(document, 'keydown', onEscape(navigation));
  eventListeners.addListener(document, 'focusout', onFocusOut(navigation));

  const unbind = () => {
    eventListeners.removeListeners();
  }
  return unbind;
}

function onFocusOut(navigation: NavigationComponent) {
  return (e: FocusEvent) => {
    const { host } = navigation;
    const topToggle = navigation.topToggle;
    const expanded = topToggle && navigation.isToggleExpanded(topToggle);
    if (expanded) {
      const target = <Node>e.target;
      const relatedTarget = <Node>e.relatedTarget;
      if (host.contains(target) && (!relatedTarget || !host.contains(relatedTarget))) {
        doToggle(navigation, topToggle);
      }
    }
  }
}

function onEscape(navigation: NavigationComponent) {
  return (e: KeyboardEvent) => {
    switch (e.key || e.code) {
      case 'Esc':
      case 'Escape':
        const topToggle = navigation.topToggle;
        const expanded = topToggle && navigation.isToggleExpanded(topToggle);
        if (expanded) {
          doToggle(navigation, topToggle);
          topToggle.focus();
        }
    }
  }
}

function onClick(navigation: NavigationComponent): EventListener {
  return (e: MouseEvent) => {
    const toggle = navigation.getToggleElement(e);
    if (toggle) {
      doToggle(navigation, toggle);
    } else {
      const topToggle = navigation.topToggle;
      if (topToggle && navigation.isToggleExpanded(topToggle)) {
        const link = (e.target as HTMLElement).closest('a, tds-nav-item[href]');
        if (link) {
          doToggle(navigation, topToggle);
        }
      }
    }
  }
}

function doToggle(navigation: NavigationComponent, toggle: HTMLElement) {
  const expand = !navigation.isToggleExpanded(toggle);

  if (navigation.isTopLevelDropdownToggle(toggle)) {
    navigation.setToggleExpanded(toggle, expand);
    return;
  }

  const navList: HTMLElement = navigation.getNavList(toggle);
  if (navList && !navList.dataset.isTransitioning) {
    navList.dataset.isTransitioning = 'true';
    navigation.setToggleExpanded(toggle, expand);
    toggleNavList(navList, expand, () => {
      delete navList.dataset.isTransitioning;
      if (!expand) {
        navList.style.display = '';
      }
    })
  }
}

function toggleNavList(navList: HTMLElement, expand: boolean, done: Function) {
  const navItems: HTMLElement[] = <HTMLElement[]>Array.from(navList.childNodes)
    .filter(node => node.nodeType === Node.ELEMENT_NODE)
    .filter(isVisible);

  if (!expand) {
    navItems.reverse();
  }

  navItems.forEach((navitem) => {
    prepareForTransition(navitem, expand);
  });

  window.setTimeout(() => {
    recurseNavItems(navItems, 0, expand, () => {
      if (!expand) {
        navList.style.display = 'none';
      }
      navItems.forEach(navitem => {
        restoreStyles(navitem, transitionStyle);
      });
      done();
    })
  }, 10);
}

function recurseNavItems(navitems: HTMLElement[], index: number, expand: boolean, done: Function) {
  const navitem = navitems[index];
  if (navitem) {
    toggleNavItem(navitem, expand, index === 0, index === navitems.length - 1, () => {
      recurseNavItems(navitems, index + 1, expand, done);
    });
  } else {
    done();
  }
}

function prepareForTransition(navitem: HTMLElement, expand: boolean) {
  const height = expand ? '0' : navitem.scrollHeight + 'px';
  const opacity = expand ? '0' : '1';
  const style = { ...transitionStyle, height, opacity };
  applyStyles(navitem, style);
}

function toggleNavItem(navitem: HTMLElement, expand: boolean, first: boolean, last: boolean, done: Function) {
  const height = expand ? navitem.scrollHeight + 'px' : '0';
  const opacity = expand ? '1' : '0';
  const duration = (PACE * navitem.scrollHeight) + (first ? 60 : last ? 120 : 0);
  const transitionDuration = `${duration * 1.5}ms, ${duration}ms`;
  const transitionTimingFunction = `ease, ${first ? 'ease-in' : last ? 'ease-out' : 'linear'}`;
  const style = { ...transitionStyle, height, opacity, transitionDuration, transitionTimingFunction };
  applyStyles(navitem, style);
  window.setTimeout(done, duration);
}

function applyStyles(el: HTMLElement, style: any) {
  const elStyle: any = el.style;
  for (let prop in style) {
    elStyle[prop] = style[prop];
  }
}

function restoreStyles(el: HTMLElement, style: any) {
  const elStyle: any = el.style;
  for (let prop in style) {
    elStyle[prop] = '';
  }
}

function isVisible(el: HTMLElement) {
  const style = window.getComputedStyle(el)
  return style.display !== 'none' && style.visibility !== 'hidden'
}
