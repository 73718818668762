import { NAMESPACE, CSS_NS } from '../../utilities/constants';
import { instances } from '../../utilities/instances';
import onDOMChanges from '../../utilities/onDOMChanges';
import { EventListeners } from '../../utilities/EventListeners';

const ENHANCED_FLAG = 'enhancedSwitch';
const INSTANCE_KEY = `${NAMESPACE}Switch`;
const PATTERN_SELECTOR = `.${CSS_NS}switch`;

class _SwitchInstance {
  root: HTMLElement;
  onDestroy: Function[] = [];

  constructor(element: HTMLElement) {
    this.root = element;
    element.dataset[ENHANCED_FLAG] = "true";

    const eventListeners = new EventListeners();
    eventListeners.addListener(this.root, 'click', clickHandler(this.root));

    const unbind = () => {
      eventListeners.removeListeners();
    }

    this.onDestroy = [unbind];
    instances.set(element, INSTANCE_KEY, this);
  }

  destroy() {
    if (this.root) {
      while (this.onDestroy && this.onDestroy.length) {
        const fn = this.onDestroy.pop();
        fn();
      }
      delete this.root.dataset[ENHANCED_FLAG];
      instances.remove(this.root, INSTANCE_KEY);
      this.root = null;
    }
  }
}

class Switch {
  _instance: _SwitchInstance;

  constructor(element: HTMLElement) {
    this._instance = <_SwitchInstance>instances.get(element, INSTANCE_KEY) || new _SwitchInstance(element);
  }

  /**
   * Detaches from the element, removes event listeners, and frees resources.
   */
  destroy() {
    return this._instance.destroy();
  }
}

function clickHandler(element: HTMLElement) {
  return (e: any) => {
    const swtchButton: any = element.querySelector('button');
    const swtchCheck: any = element.querySelector('input[type="checkbox"]');
    const target: HTMLElement = e.target as HTMLElement;
    const offOrOn = target.closest(`.${CSS_NS}switch__label`);
    if (offOrOn) {
      e.preventDefault();
      const pressed = offOrOn.matches(`.${CSS_NS}switch__switch ~ .${CSS_NS}switch__label`);
      if (swtchButton) {
        toggleTheSwitch(swtchButton, pressed);
      } else if (swtchCheck) {
        swtchCheck.checked = pressed;
      }
    } else if (swtchButton && swtchButton.contains(target)) {
      toggleTheSwitch(swtchButton);
    }
  }
}

function toggleTheSwitch(swtch: HTMLElement, pressed = swtch.getAttribute("aria-pressed") !== "true") {
  swtch.setAttribute("aria-pressed", `${pressed}`);
}

onDOMChanges(`${PATTERN_SELECTOR}`,
  function onPatternAdded(element: HTMLElement) {
    if (!element.dataset[ENHANCED_FLAG]) {
      new Switch(element)
    }
  },
  function onPatternRemoved(element: HTMLElement) {
    if (element.dataset[ENHANCED_FLAG] === "true") {
      new Switch(element).destroy();
    }
  }
);

export { Switch }