import { ComboboxComponent } from './ComboboxComponent';
import { ComboboxState, SelectOption } from './ComboboxState';
import { splitTextByMatch } from '../../utilities/helpers';

export function renderOptionText(optionEl: HTMLElement, option: SelectOption, isActive: boolean, comboxbox: ComboboxComponent, state: ComboboxState) {
  const { filterText } = state;
  const { highlight, optionRenderer } = comboxbox.config;
  if (typeof optionRenderer === 'function') {
    const item = option.item || option;
    if (optionRenderer({ item, isActive, el: optionEl, filterText }) !== false) {
      return;
    }
  }

  const optionText = option.name;
  if (!highlight || !filterText) {
    optionEl.textContent = optionText;
    return;
  }
  const parts = splitTextByMatch(optionText, filterText);
  const elements: HTMLElement[] = [];
  parts.forEach((part: string, i: number) => {
    if (part) {
      const tag = (i % 2) ? 'mark' : 'span';
      const el = document.createElement(tag);
      el.textContent = part
      elements.push(el);
    }
  });
  optionEl.innerHTML = '';
  elements.forEach(el => optionEl.appendChild(el));
}