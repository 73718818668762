import { DataTableComponent } from './DataTableComponent';
import { getElementsFromThisTable } from './dataTableUtils';
import { EventListeners } from '../../utilities/EventListeners';
import { translations, getLang } from '../../utilities/i18n';
import { getLabelledByLabel } from "../../utilities/helpers";
import debounce from '../../utilities/debounce';
import { 
  EXPANSION_ROW_DATA_ATTR 
} from './dataTableConstants';

export function bindSorting(dataTable: DataTableComponent): Function {
  const { root, config } = dataTable;
  const sortColumn = root.querySelector('thead [aria-sort]');
  if (!sortColumn) return () => {};
  
  const eventListeners = new EventListeners();
  eventListeners.addListener(root, 'click', onTableSortColumn(dataTable));
  const tableSort = (config.tableSort || '').toLowerCase();
  if (tableSort === "simple") {
    const sortedHeader: HTMLElement = root.querySelector('th[aria-sort="ascending"], th[aria-sort="descending"]');
    if (sortedHeader) {
      const ascending = isSortedAscending(sortedHeader);
      const colIndex = Array.from(sortedHeader.parentNode.children).indexOf(sortedHeader);
      sortTableBody(dataTable, colIndex, ascending, sortedHeader);
    }
  }
  if (config.sortControl) {
    bindTableSortControl(dataTable, config.sortControl, eventListeners);
  }
  setSortStateDescriber(dataTable);

  return function unbindSorting() {
    eventListeners.removeListeners();
  }
}

////////////////////////////////////// 
// table sorting 
function onTableSortColumn(dataTable: DataTableComponent): EventListener {
  return (e: Event) => {
    const target: Element = <Element>e.target;
    const th: HTMLElement = target.closest('th[aria-sort]');
    
    if (th) {
      const ascending = !isSortedAscending(th);
      const colIndex = Array.from(th.parentNode.children).indexOf(th);
      sortTable(dataTable, colIndex, ascending, th);
    }
  };
}

export function sortTable(dataTable: DataTableComponent, colIndex: number, ascending: boolean, th: HTMLElement) {
  const { config } = dataTable;
  switch ((config.tableSort || '').toLowerCase()) {
    case 'simple':
      doSimpleSort(dataTable, colIndex, ascending, th);
      break;
    case 'callback':
      doCallbackSort(dataTable, colIndex, ascending, th);
      break;
    default:
      // defaults to 'event';
      fireSortEvent(dataTable, colIndex, ascending, th, true);
  }
}


function doSimpleSort(dataTable: DataTableComponent, colIndex: number, ascending: boolean, th: HTMLElement) {
  sortTableBody(dataTable, colIndex, ascending, th)
  fireSortEvent(dataTable, colIndex, ascending, th, false)
}

function doCallbackSort(dataTable: DataTableComponent, colIndex: number, ascending: boolean, th: HTMLElement) {
  const { config } = dataTable;
  const onSortColumn = config && config.onSortColumn;

  if (onSortColumn) {
    const resp = onSortColumn(colIndex, ascending, th);
    const fireEvent = fireSortEvent.bind(this, dataTable, colIndex, ascending, th, false);
    if (resp && 'function' === typeof resp.then) {
      resp.then(fireEvent).catch(() => { });
    } else if (resp !== false) {
      fireEvent();
    }
  } else {
    // treat like tableSort="event"
    fireSortEvent(dataTable, colIndex, ascending, th, true);
  }
}

function fireSortEvent(dataTable: DataTableComponent, colIndex: number, ascending: boolean, th: HTMLElement, cancelable: boolean) {
  const eventInit = {
    cancelable: cancelable,
    detail: { colIndex, ascending, th }
  };

  if (!cancelable) {
    //when not cancelable, set sort state before event is thrown
    setSortState(dataTable, th, ascending);
  }

  const notPrevented = dataTable.dispatchEvent('sortbycolumn', eventInit);

  if (cancelable && notPrevented) {
    //when cancelable, set sort state only if event was not prevented
    setSortState(dataTable, th, ascending);
  }
}

function setSortState(dataTable: DataTableComponent, th: HTMLElement, ascending: boolean) {
  const headers = th.parentNode.querySelectorAll('th[aria-sort]');
  for (let i = 0; i < headers.length; i++) {
    const hdr = headers[i];
    const state = hdr === th ? (ascending ? 'ascending' : 'descending') : 'none';
    hdr.setAttribute('aria-sort', state);
  }
  setSortStateDescriber(dataTable);
}

function sortTableBody(dataTable: DataTableComponent, colIndex: number, ascending: boolean, th: HTMLElement) {
  const { root, config } = dataTable;
  const sortColDataType = th.dataset.sortDataType || '';

  function getSortValue(tr: HTMLElement) {
    const cells = getElementsFromThisTable(tr, 'td,th');
    const cell: HTMLElement = cells[colIndex];
    let value: any = cell ? cell.dataset.sortValue || (cell.textContent && cell.textContent.trim()) : '';
    if (sortColDataType === 'numeric') {
      const sNumeric = value.replace(/[^\d.-]/g, '');
      const numeric = sNumeric ? parseFloat(sNumeric) : 0;
      /* istanbul ignore else */ 
      if (!isNaN(numeric) && isFinite(numeric)) {
        const inParenthesis = /^\([^\(\)]*\)$/.test(value.trim());
        value = inParenthesis ? -numeric : numeric;
      } else {
        // not sure if this could happen, but...
        value = Number.MAX_VALUE; // sort invalid numerics last
      }
    }
    return value;
  }

  function isRowGroupHeader(tr: HTMLElement): boolean {
    return tr.parentNode.firstElementChild === tr && !!tr.querySelector('th:only-child');
  }

  function isDetailRow(tr: HTMLElement): boolean {
    return tr.matches(`[${EXPANSION_ROW_DATA_ATTR}]`);
  }

  const tbodies = getElementsFromThisTable(root, 'tbody').map(tbody => {
    const trows = getElementsFromThisTable(tbody, 'tr');
    const rows = trows
      // remove row group headings. These will stay at top of tbody
      .filter(tr => !isRowGroupHeader(tr))
      // separate detail (expandable) rows from the rows to be sorted
      .reduce((rowsToSort: any[], tr: HTMLElement) => {
        if (isDetailRow(tr)) {
          // if the row is a detail row, associate it with its summary row above
          if (rowsToSort.length) {
            rowsToSort[rowsToSort.length - 1].detailRows.push(tr);
          }
        } else {
          // add row to sort array
          rowsToSort.push({
            tr,
            sortValue: getSortValue(tr),
            detailRows: []
          });
        }
        return rowsToSort;
      }, []);

    return {
      tbody,
      rows
    }
  })

  tbodies.forEach(tbodyData => {
    const { rows, tbody } = tbodyData;
    rows.sort((row1: any, row2: any) => {
      const rowA = ascending ? row1 : row2;
      const rowB = ascending ? row2 : row1;
      const a = rowA.sortValue;
      const b = rowB.sortValue;

      let order;
      if ('function' === typeof config.sortComparer) {
        order = config.sortComparer(a, b, colIndex, rowA.tr, rowB.tr, ascending);
      }

      if (!order && order !== 0) {
        order = sortColDataType === 'numeric' ? (a - b) :
          a.localeCompare(b, undefined, { sensitivity: 'base', numeric: true });
      }

      return order;
    })

    rows.forEach((row: any) => {
      const { tr, detailRows } = row;
      tbody.appendChild(tr);
      detailRows.forEach((detailRow: HTMLElement) => tbody.appendChild(detailRow))
    });
  })
}

function isSortedAscending(th: Element) {
  return th.getAttribute('aria-sort') === 'ascending'
}

//////////////////////////////////////////////////
// table sorting external control
//
// TODO: This logic is too specific to core implementation in regards to dropdown configured as menu action
//

function bindTableSortControl(dataTable: DataTableComponent, sortControlRef: string | HTMLElement, eventListeners: EventListeners) {
  const { root } = dataTable;
  const sortControl = <HTMLInputElement>getSortControl(sortControlRef);
  if (sortControl) {
    const tableSortColumnHandler = (e: Event) => {
      window.setTimeout(() => {
        if (!e.defaultPrevented) {
          synchControlWithTable(sortControl, dataTable);
        }
      });
    };
    eventListeners.addListener(root, 'sortbycolumn', tableSortColumnHandler);
    const sortControlChangeHandler = getSortControlChangeHandler(sortControl, dataTable)
    const sortHandlerTarget = sortControl.form || sortControl;
    const sortHandlerEventType = sortControl.form ? 'submit' : 'change';
    eventListeners.addListener(sortHandlerTarget, sortHandlerEventType, sortControlChangeHandler);

    if (dataTable.getSortedColumn()) {
      synchControlWithTable(sortControl, dataTable);
    } else if (sortControl.value) {
      synchTableWithControl(sortControl, dataTable);
    }
  }
}

function getSortControlChangeHandler(sortControl: HTMLInputElement, dataTable: DataTableComponent) {
  if (sortControl.form) {
    return (e: Event) => {
      e.preventDefault();
      synchTableWithControl(sortControl, dataTable);
    }
  } else if (sortControl.dataset.dropdownMenu === 'true') {
    return (e: Event) => {
      if ((e as any).isMenuAction) {
        synchTableWithControl(sortControl, dataTable);
      }
    }
  } else {
    return debounce(() => {
      synchTableWithControl(sortControl, dataTable);
    }, 500);
  }
}

function synchControlWithTable(sortControl: HTMLInputElement, dataTable: DataTableComponent) {
  const sortedColumn = dataTable.getSortedColumn();
  if (sortedColumn) {
    sortControl.value = `${sortedColumn.colIndex}${sortedColumn.ascending ? 'a' : 'd'}`
  }
}

function synchTableWithControl(sortControl: HTMLInputElement, dataTable: DataTableComponent) {
  const match = sortControl.value.match(/(^\d+)([ad]$)/);
  if (match) {
    const colIndex = parseInt(match[1]);
    const ascending = match[2] !== 'd';
    dataTable.sortColumn(colIndex, ascending);
  }
}

function getSortControl(sortControl: string | HTMLElement): HTMLElement {
  if ('string' === typeof sortControl) {
    // see if it works as selector
    const controlSelector = sortControl;
    sortControl = <HTMLElement>document.querySelector(controlSelector);
    if (!sortControl) {
      // try by id
      sortControl = document.getElementById(controlSelector);
    }
  }
  return sortControl;
}

////////////////////////////////////////////////////////////////////// 
// Sort Accessibility - Announcing sort state and column labelling

function setSortStateDescriber(dataTable: DataTableComponent) {
  const { sortStateDescriber, config, root } = dataTable;
  if (!sortStateDescriber) return;
  const liveRegion = sortStateDescriber.closest('[aria-live]');
  // A liveRegion should only be "visible" when announcing. At other times, it should be "hidden" so
  // that it is not read as normal page text as the user moves past the table to the next part of the page.
  // Here, if the liveRegion is hidden, show, then return after a brief timeout to let the change take effect
  if (liveRegion && liveRegion.getAttribute('aria-hidden') === 'true') {
    liveRegion.removeAttribute('aria-hidden')
    window.setTimeout(() => {
      setSortStateDescriber(dataTable)
    }, 100)
    return;
  }
  const sortedColumn = dataTable.getSortedColumn();
  if (config.tableSort || sortedColumn) {
    const translation = translations(getLang(root)).t;
    sortStateDescriber.textContent = getSortedStateDescription(sortedColumn, translation);
    if (liveRegion) {
      if ((<any>liveRegion).setHiddenTimeout) {
        window.clearTimeout((<any>liveRegion).setHiddenTimeout);
      }
      // after 5 seconds, hide the live region
      (<any>liveRegion).setHiddenTimeout = window.setTimeout(() => {
        liveRegion.setAttribute('aria-hidden', 'true');
        delete (<any>liveRegion).setHiddenTimeout;
      }, 5000);
    }
  }
}

function getSortedStateDescription(sortedColumn: any, t: any) {
  if (sortedColumn) {
    const { ascending, th } = sortedColumn;
    const columnName = getColumnName(th);
    const DIRECTION_ATTR = ascending ? 'data-ascending-text' : 'data-descending-text';
    const direction = th.getAttribute(DIRECTION_ATTR) || t(ascending ? 'ascending' : 'descending');
    return t('tableSortStateDescription', columnName, direction);
  }
  return t('notSorted');
}

function getColumnName(th: HTMLElement) {
  const columnName = th.getAttribute('data-column-name') ||
    th.getAttribute('aria-label') ||
    getLabelledByLabel(th) ||
    th.textContent || ''
  return columnName.trim();
}

