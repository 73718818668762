/**
 * Calls a function when the DOM is loaded
 * @param callback {function} The function to call when the DOM is loaded
 */
const whenDOMReady = (callback: Function) => {
  if (document.readyState === "loading") {
    document.addEventListener('DOMContentLoaded', <EventListener>callback, {once: true});
  }
  else {
    callback();
  }
}

export default whenDOMReady;
