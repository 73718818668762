import onDOMChanges from '../../utilities/onDOMChanges';
import { bindPagination } from './paginationBehavior';
import { PaginationComponent } from './PaginationComponent';
import { instances } from '../../utilities/instances';
import { configFromDataAttributes, convertStringRefs, watchDataAttributeChange } from '../../utilities/helpers';
import { NAMESPACE, CSS_NS } from '../../utilities/constants';
import { PaginationConfig } from './PaginationConfig';
import { PaginationNavigationEventDetail } from './types';
import { getLang, translations } from '../../utilities/i18n';
import { getPageNumberList, nextLabel, pageHref, pageLabel, paginationLabel, paginationPlaceholderText, previousLabel } from './utils';
import arrowLeft1_18 from '@trv-ebus/tds-icons/icons/arrow-left-1-18';
import arrowRight1_18 from '@trv-ebus/tds-icons/icons/arrow-right-1-18';
import { createCustomEvent } from '../../utilities/customEvent';
import devConsole from '../../utilities/devConsole';

const ENHANCED_FLAG = 'enhancedPagination';
const INSTANCE_KEY = `${NAMESPACE}Pagination`;
const PATTERN_SELECTOR = `.${CSS_NS}pagination`;

class _PaginationInstance implements PaginationComponent {
  host: HTMLElement;
  root: HTMLElement;

  onDestroy: Function[] = [];

  _dataConfig: PaginationConfig;
  _apiConfig: PaginationConfig = {};
  _config: PaginationConfig;
  _roomForPageControls: number;
  _rendered: boolean;

  translate: (key: string, ...replacements: string[]) => string;

  constructor(element: HTMLElement, config: PaginationConfig = {}) {
    this.root = this.host = element;
    element.dataset[ENHANCED_FLAG] = "true";
    instances.set(element, INSTANCE_KEY, this);
    const unwatch = watchDataAttributeChange(element, () => {
      delete this._dataConfig; // config getter will re-generate
      this.render();
    });
    const unbind = bindPagination(this);
    this.onDestroy = [unbind, unwatch];
    this._apiConfig = config;
    this.render();
  }

  dispatchEvent(detail: PaginationNavigationEventDetail): boolean {
    const eventInit = {cancelable: true, bubbles: true, detail};
    return this.host.dispatchEvent(createCustomEvent('tdsNavigate', eventInit));  
  }

  get config(): PaginationConfig {
    if (!this._config || !this._dataConfig) {
      if (!this._dataConfig) {
        const dataConfig = this._dataConfig = configFromDataAttributes(this.host, {}, [
          {
            names: ['maxItems', 'page', 'totalPages'], 
            convert: 'integer'
          }
        ]) as PaginationConfig;
        convertStringRefs(dataConfig, ['hrefTemplate'], 'function');
      }
      this._config = {
        // defaults
        page: 1,
        totalPages: -1,
        maxItems: 10,
        ...this._dataConfig,
        ...this._apiConfig
      };
    }
    return this._config;
  }

  updateConfig(config: PaginationConfig) {
    this._apiConfig = config;
    delete this._config;
    this._rendered && this.render();
  }

  get roomForPageControls(): number { return this._roomForPageControls; }

  set roomForPageControls(n: number) {
    if (n !== this._roomForPageControls) {
      this._roomForPageControls = n;
      this._rendered && this.render();
    }
  }

  render() {
    const { host } = this;
    this.translate = translations(getLang(this.host)).t;
    host.setAttribute('role', this.config.buttons ? 'group' : 'navigation');
    host.setAttribute('aria-label', paginationLabel(this));
    const html = [
      '<ul>',
      this.renderPrevious(),
      this.renderPageItems(),
      this.renderNext(),
      '</ul>'
    ].join('');
    host.innerHTML = html;
    this._rendered = true;
  }

  renderPageItems() {
    const { config } = this;
    const pageList = getPageNumberList(this);
    return pageList.map((page, i) => {
      const ariaCurrent = page === config.page ? config.ariaCurrentType || 'page' : '';
      return `<li${ariaCurrent && ` aria-current="${ariaCurrent}"`}>${this.renderPageItem(page, pageList[i - 1], pageList[i + 1])}</li>`;
    }).join('');
  }

  renderPageItem(page: number, previousPage: number, nextPage: number) {
    if (page === -1) {
      return this.renderPlaceHolder(previousPage, nextPage);
    }
    if (page === this.config.page) {
      return `<span>${page}</span>`;
    }
    return this.renderPageAction(page, previousPage, nextPage);
  }

  renderPlaceHolder(previousPage: number, nextPage: number) {
    const srText = paginationPlaceholderText(previousPage, nextPage, this.translate);
    return `<span class="tds-pagination__placeholder"><span aria-hidden="true">...</span><span class="tds-sr-only">${srText}</span></span>`;
  }

  renderPrevious() {
    const { page } = this.config;
    const prev = Math.max(page - 1, 1);
    const prevDisabled = page === 1 ? 'true' : undefined;
    const lblPrev = previousLabel(prev, this);
    const attributes = [
      `aria-label="${lblPrev}"`,
      `title="${lblPrev}"`
    ];
    if (prevDisabled) {
      attributes.push('aria-disabled="true"');
    }
    const innerHTML = arrowLeft1_18.svg({ "aria-hidden": true, focusable: false })
    return `<li>${this.renderAction(prev, 'prev', attributes, innerHTML)}</li>`;
  }

  renderNext() {
    const { page, totalPages } = this.config;
    const next = totalPages > 0 ? Math.min(page + 1, totalPages) : page + 1;
    const nextDisabled = page === totalPages ? 'true' : undefined;
    const lblNext = nextLabel(next, this);
    const attributes = [
      `aria-label="${lblNext}"`,
      `title="${lblNext}"`
    ];
    if (nextDisabled) {
      attributes.push('aria-disabled="true"');
    }
    const innerHTML = arrowRight1_18.svg({ "aria-hidden": true, focusable: false })
    return `<li>${this.renderAction(next, 'next', attributes, innerHTML)}</li>`;
  }

  renderPageAction(page: number, previousPage: number, nextPage: number) {
    const classes = [];
    if (previousPage === -1) {
      classes.push('tds-pagination__first-item')
    }
    if (nextPage === -1) {
      classes.push('tds-pagination__last-item')
    }
    const attributes = classes.length ? [`class="${classes.join(' ')}"`] : [];
    return this.renderAction(page, 'page', attributes, pageLabel(page, this, 'tds-sr-only'));
  }

  renderAction(page: number, action: 'page' | 'next' | 'prev', attributes: string[], innerHTML: string) {
    const { buttons } = this.config;
    const Tag = buttons ? 'button' : 'a';
    if (!buttons) {
      attributes.push(`href="${pageHref(page, action, this)}"`);
    }
    return `<${Tag} ${attributes.join(' ')} data-page="${page}" data-action="${action}">${innerHTML}</${Tag}>`
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this.root) {
      delete this.root.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.host, INSTANCE_KEY);
  }
}

class Pagination {
  _instance: _PaginationInstance;
  constructor(element: HTMLElement, config?: PaginationConfig) {
    this._instance = <_PaginationInstance>instances.get(element, INSTANCE_KEY);
    if (!this._instance) {
      this._instance = new _PaginationInstance(element, config);
    } else if (config) {
      this._instance.updateConfig(config);
    }
  }

  get config(): PaginationConfig {
    return this._instance?.config;
  }

  updateConfig(config: PaginationConfig) {
    if (this._instance) {
      this._instance.updateConfig(config);
    }
  }

  destroy() {
    return this._instance.destroy();
  }
}
onDOMChanges(`${PATTERN_SELECTOR}`,
  function onPatternAdded(element: HTMLElement) {
    if (!element.dataset[ENHANCED_FLAG]) {
      if (element.children.length || element.textContent.trim()) {
        devConsole.warn(`(development-only-warning) The innerHTML of tds-pagination is overwritten when auto-enhanced. If this is not wanted, set 'data-enhanced-pagination="false"'. If it is intentional, be sure 'data-page', 'data-total-pages' and 'data-href-template' are set appropriately.`);
      }
      new Pagination(element)
    }
  },
  function onPatternRemoved(element: HTMLElement) {
    if (element.dataset[ENHANCED_FLAG] === "true") {
      new Pagination(element).destroy();
    }
  }
);

export { Pagination }