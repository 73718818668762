import { CellValidationData } from "./CellValidationData";
import { CellValidator } from "./CellValidator";
import { RangeValidatorOptions } from "./validatorsFactory";

export class RangeCellValidator implements CellValidator {
  _options: RangeValidatorOptions;

  constructor(options: RangeValidatorOptions) {
    this._options = options;
  }

  validate(cellData: CellValidationData): CellValidationData {
    const { min, max, message } = this._options;
    let errorReason: 'rangeOverflow' | 'rangeUnderflow'
    let newValue: any = cellData.newValue;
    if (typeof newValue === 'string') {
      newValue = newValue.trim();
    }
    if (newValue !== '') {
      if (typeof newValue === 'string') {
        newValue = parseFloat(newValue);
      }
      if (!isNaN(newValue)) {
        if (typeof min !== 'undefined' && newValue < min) {
          errorReason = 'rangeUnderflow';
        } else if (typeof max !== 'undefined' && newValue > max) {
          errorReason = 'rangeOverflow';
        }
      }
    }

    return errorReason ? { ...cellData, errorMessage: message, errorReason } : cellData;
  }

  get type() { return 'range'; }
  get options() { return this._options; }
}