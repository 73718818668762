import { CellEditorComponent } from "./CellEditorComponent";
import { CheckboxCellEditor } from "./CheckboxCellEditor";
import { SelectCellEditor } from "./SelectCellEditor";
import { TextAreaCellEditor } from "./TextAreaCellEditor";
import { TextInputCellEditor } from "./TextInputCellEditor";

export default function cellEditorFactory(config: any): CellEditorComponent {
  const control = config.editorControl;
  const options = config.editorOptions;

  let editor: CellEditorComponent;

  switch (control) {
    case 'select':
      editor = new SelectCellEditor(parseOptions(options, control));
      break;

    case 'integer':
      editor = new TextInputCellEditor('number');
      break;

    case 'checkbox':
      editor = new CheckboxCellEditor(parseOptions(options || ['',''], control));
      break;

    case 'textarea':
      editor = new TextAreaCellEditor();
      break;
  }
  return editor || new TextInputCellEditor();
}

function parseOptions(options: any, control: string): any {
  try {
    const parsed = (typeof options === 'string') ? JSON.parse(options) : options;
    if (typeof parsed === 'object') {
      return parsed
    }
  } catch {}
  throw new Error(`editor options are not in a valid format for ${control}: ${options}` )
}