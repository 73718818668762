/**
 * Returns the value of an object using a dot notation path. Useful for retrieving
 * deep values in an object through configuration values;
 *
 * Example:
 * ```js
 * const person = {
 *   name: {
 *     first: 'Joe',
 *     last: 'Smith'
 *   }
 *   id: 123
 * }
 *
 * const path1 = 'name.last';
 * const path2 = 'id';
 * const lastName = getObjectValue(person, path1); // returns 'Smith'
 * const id = getObjectValue(person, path2); // returns 123
 * ```
 *
 * @param o The object to retrieve the value from
 * @param path dot delimited property path of the value to retrieve
 * @returns The value if found; otherwise null;
 */
export function getObjectValue(o: any, path: string) {
  const props = path.split('.');
  const value = props.reduce((obj, prop) => {
    return obj ? obj[prop] : null;
  }, o);
  return value;
}

/**
 * Sets an object's property value using a dot notation path. Useful for setting
 * deep values in an object through properties.
 *
 * Example:
 * ```js
 * const person = {
 *   name: {
 *     first: 'Joe',
 *     last: 'Smith'
 *   }
 *   id: 123
 * }
 * const path = 'name.last';
 * setObjectValue(person, path, 'Jones');
 * console.log(person.name.last); // outputs 'Jones'
 * ```
 *
 * @param o The object to update
 * @param path dot delimited property path of the value to retrieve
 * @param value The vlue to set the property to
 */
export function setObjectValue(o: any, path: string, value: any) {
  const props = path.split('.');
  while (props.length) {
    const prop = props.shift();
    if (props.length) {
      o = o[prop] || (o[prop] = {});
    } else {
      o[prop] = value;
    }
  }
}

/**
 * Returns a string constructed with values from an object as configured in a template.
 * Object paths delimited by `{ }` are replaced by the object's value for that path.
 *
 * Example:
 * ```js
 * const person = {
 *   name: {
 *     first: 'Joe',
 *     last: 'Smith'
 *   }
 *   id: 123
 * }
 * console.log(interpolate(person, 'Hello, {name.first} {name.last}')); //outputs 'Hello, Joe Smith'
 * ```
 * @param o
 * @param template
 * @returns
 */
export function interpolate(o: any, template: string) {
  const rx = /{([^{}]+)}/g; // looks for text inside { } delimiters
  return template.replace(rx, (_, path) => {
    return getObjectValue(o, path);
  });
}
