import { EventListeners } from "../../utilities/EventListeners";
import { CSS_NS } from '../../utilities/constants';
import { normalizeKey } from "../../utilities/keyboard";
import doWhileEventing from "../../utilities/doWhileEventing";
import debounce from '../../utilities/debounce';
import { enableDropdownMenu } from "../../components/dropdown/dropdownMenuBehavior";
import { TablePaginationComponent } from "./TablePaginationComponent";

const PATTERN_SELECTOR = `${CSS_NS}table-pagination`;
export const PAGINATION = {
  SIZE: `${PATTERN_SELECTOR}__size`,
  COUNT: `${PATTERN_SELECTOR}__count`,
  PAGE: `${PATTERN_SELECTOR}__page`,
  PAGE_NUMBER: `${PATTERN_SELECTOR}__page-num`,
  LAST_PAGE: `${PATTERN_SELECTOR}__page-num--last`,
  PAGE_NAV: `${PATTERN_SELECTOR}__page-nav`,
  PREVIOUS_BTN: `${PATTERN_SELECTOR}__previous-page`,
  NEXT_BTN: `${PATTERN_SELECTOR}__next-page`,
}

export function bindTablePagination(pagination: TablePaginationComponent): Function {
  const { root } = pagination;
  const previousBtn = root.querySelector(`.${PAGINATION.PREVIOUS_BTN}`);
  const nextBtn = root.querySelector(`.${PAGINATION.NEXT_BTN}`);
  const pageSizeOptions: HTMLSelectElement = root.querySelector(`.${PAGINATION.SIZE} select`);
  const input: HTMLInputElement = root.querySelector(`.${PAGINATION.PAGE_NUMBER} input`);

  // Add Event Listeners
  const eventListeners = new EventListeners();

  const keyHandler = keyDownHandler(pagination);
  eventListeners.addListener(previousBtn, 'keydown', keyHandler);
  eventListeners.addListener(nextBtn, 'keydown', keyHandler);
  eventListeners.addListener(previousBtn, 'click', handlePrevious(pagination));
  eventListeners.addListener(nextBtn, 'click', handleNext(pagination));

  if (input) {
    eventListeners.addListener(input, 'change', handlePageNumberChange(pagination));
  }
  if (pageSizeOptions) {
    eventListeners.addListener(pageSizeOptions, 'change', handlePageSizeChange(pagination));
    enableDropdownMenu(pageSizeOptions);
  }

  const undoWhile = doWhileEventing(window, 'resize', () => {
    checkStacked(pagination);
  });

  const unbind = () => {
    eventListeners.removeListeners();
    undoWhile();
  }
  return unbind;
}

const previousButtonHandler = (pagination: TablePaginationComponent) => {
  const { page } = pagination; // page getter
  if (page !== 1) {
    handleSelect(page - 1, pagination);
  }
};

const nextButtonHandler = (pagination: TablePaginationComponent) => {
  const { page } = pagination;
  if (page !== getTotalPages(pagination)) {
    handleSelect(page + 1, pagination);
  }
}

function keyDownHandler(pagination: TablePaginationComponent): EventListener {
  return ((event: KeyboardEvent) => {
    const key = normalizeKey(event);
    switch (key) {
      case 'ArrowLeft':
        event.preventDefault();
        previousButtonHandler(pagination);
        break;
      case 'ArrowRight':
        event.preventDefault();
        nextButtonHandler(pagination);
        break;
    }
  })
}

// Previous button click event
function handlePrevious(pagination: TablePaginationComponent) {
  return (() => {
    previousButtonHandler(pagination)
  })
}

// Next button click event
function handleNext(pagination: TablePaginationComponent) {
  return (() => {
    nextButtonHandler(pagination)
  })
}

function handleSelect(page: number, pagination: TablePaginationComponent): number {
  const totalPages = getTotalPages(pagination);
  let newPage = Math.max(page, 1);
  if (totalPages !== -1) newPage = Math.min(newPage, totalPages);

  if (pagination.page !== newPage) {
    const { noAutoUpdate } = pagination.config;
    if (!noAutoUpdate) pagination.page = newPage;
    pageEvent('tdsPageChanged', pagination, { page: newPage });
  }
  return newPage;
}

// Page number value change event
function handlePageNumberChange(pagination: TablePaginationComponent) {
  const debounceHandler = debounce((PAGE_NUMBER_INPUT: HTMLInputElement) => {
    const page = parseInt(PAGE_NUMBER_INPUT.value);
    const edittedPage = handleSelect(isNaN(page) ? 1 : page, pagination).toString();
    if (PAGE_NUMBER_INPUT.value !== edittedPage) {
      PAGE_NUMBER_INPUT.value = edittedPage;
    }
  }) as (input: HTMLInputElement) => void;

  return ((event: Event) => {
    debounceHandler(event.target as HTMLInputElement);
  })
}

// Page size value change event
function handlePageSizeChange(pagination: TablePaginationComponent) {
  return ((event: Event) => {
    if (!(<any>event).isMenuAction) {
      const pageOptionSelect = event.currentTarget as HTMLSelectElement;
      let newPageSize = parseInt(pageOptionSelect.value);

      if (pagination.pageSize !== newPageSize) {
        const { noAutoUpdate } = pagination.config;
        if (!noAutoUpdate) {
          pagination.pageSize = newPageSize;
          pagination.page = 1;
        }
      }
      pageEvent('tdsPageSizeChanged', pagination, { page: 1, pageSize: newPageSize });
    }
  })
}

// Emits page change event
function pageEvent(eventName: string, pagination: TablePaginationComponent, detail: any) {
  const { page, pageSize } = pagination;
  pagination.dispatchEvent(eventName, {
    detail: {
      page: page,
      pageSize: pageSize,
      ...detail
    }
  });
}

function checkStacked(pagination: TablePaginationComponent) {
  const { root } = pagination;
  if (root) {
    // filtering root.childNodes because web component IE polyfill breaks root.children
    const totalWidth = Array.from(root.childNodes)
      .filter((node: Node) => node.nodeType === Node.ELEMENT_NODE)
      .reduce((acc: number, node: HTMLElement) => {
        return acc + node.offsetWidth;
      }, 0)
    const stacked = totalWidth > root.clientWidth;
    pagination.stacked = stacked;
  }
}

// export functions
export function getTotalPages(pagination: TablePaginationComponent) {
  const { config } = pagination;
  const { totalItems, pageSize } = config;
  return totalItems === -1 ? -1 : pageSize ? Math.ceil(totalItems / pageSize) : 1;
}

export function calculatePageInputWidth(pagination: TablePaginationComponent) {
  const { root, config } = pagination;
  const totalPages = getTotalPages(pagination);
  const PAGE_NUMBER_INPUT: HTMLInputElement = root.querySelector(`.${PAGINATION.PAGE_NUMBER} > input`);
  if (PAGE_NUMBER_INPUT) {
    // if total items are unknown, set width for 3 digits by default
    PAGE_NUMBER_INPUT.style.width = 2 + ((config.totalItems === -1 ? 3 : totalPages.toString().length) * 0.7) + "rem";
  }
}