import { EventListeners } from '../../utilities/EventListeners';
import { NAMESPACE } from '../../utilities/constants';
import { instances } from '../../utilities/instances';

export const INSTANCE_KEY = `${NAMESPACE}ToggleIcon`;
const ENHANCED_FLAG = 'toggleHasSvg';
const pathML = '<path fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round"/>'

// This craziness is to ensure the svg stays put. React applications that are rendered
// server-side will clear the SVG when the icon is "hydrated"
// array of icon/svg pairs to watch and timestamp added
// Didn't want to clutter browser with multiple timeouts per icon
//
// NOTE: MutationObserver (per icon) would be easier to code. Just listen for the removal of
// the SVG. But not sure of the size of MO's. Would creating an MO for each icon add a lot to
// memory? Could create one MO for all icons, but no way (it seems) to disconnect 
// an observer for a specific target when removed. Worth further research.
const watching: { icon: Element, svg: Element, count: 0 }[] = [];
let watchingTimeout = 0;
const checkFrequency = 100;
function watch(icon: Element, svg: Element) {
  //start the timer when needed
  if (!watchingTimeout) {
    watchingTimeout = window.setTimeout(check, checkFrequency);
  }
  watching.push({ icon, svg, count: 0 });
}

function check() {
  // check each icon/svg pair in array
  for (let i = watching.length - 1; i > -1; i--) {
    watching[i].count++;
    const { icon, svg, count } = watching[i];
    if (svg.parentNode !== icon) {
      icon.appendChild(svg);
      watching.splice(i, 1);
    } else if (count >= 10) {
      // remove after 10 tries
      watching.splice(i, 1);
    }
  }
  // continue if items still in the array
  watchingTimeout = watching.length ? window.setTimeout(check, checkFrequency) : 0;
}

export interface ToggleIconConfig {
  svgClass?: string
}

class ToggleIconInstance {
  icon: HTMLElement;
  _eventListeners = new EventListeners();
  _transitioning: boolean = false;
  _size: number;

  static _watchTimeout = 0;

  constructor(icon: HTMLElement, config?: ToggleIconConfig) {
    instances.set(icon, INSTANCE_KEY, this);
    icon.dataset[ENHANCED_FLAG] = 'pending';
    this.icon = icon;
    this.renderSVGPath = this.renderSVGPath.bind(this);
    this.init(1, config);
  }

  init(count: number, config: ToggleIconConfig) {
    const icon = this.icon;
    if (!icon || count > 10) {
      //destroyed or timedout
      return;
    }
    let svg: Element;
    while (!(svg = icon.querySelector('svg'))) {
      icon.insertAdjacentHTML('beforeend', `<svg>${pathML}</svg>`);
    }
    const lineHeight = parseInt(window.getComputedStyle(svg).lineHeight);
    const size = this._size = parseInt(window.getComputedStyle(svg).width);
    if (isNaN(lineHeight) || isNaN(size)) {
      // CSS has not yet kicked in, try again
      window.setTimeout(() => this.init(count + 1, config), 100);
      return;
    }

    svg.setAttribute('viewBox', `0 0 ${size} ${size}`);
    svg.setAttribute('focusable', 'false');
    svg.setAttribute('aria-hidden', 'true');
    const svgClass = config && config.svgClass;
    if (svgClass) {
      svg.classList.add(svgClass);
    }

    const listeners = this._eventListeners;
    listeners.addListener(svg, 'transitionstart', () => {
      this._transitioning = true;
      this.renderSVGPath();
    });
    listeners.addListener(svg, 'transitionend', () => {
      this._transitioning = false;
      this.renderSVGPath();
    });
    icon.dataset[ENHANCED_FLAG] = 'true';
    this.renderSVGPath();

    if (ToggleIconInstance._watchTimeout) {
      watch(icon, svg);
    }
  }

  renderSVGPath() {
    const { icon, _size } = this;
    const svg = icon.querySelector('svg');
    let path: Element
    while (!(path = icon.querySelector('svg path'))) {
      // IE does not support inner html on SVGElement
      const domParser = new DOMParser();
      const pathDoc = domParser.parseFromString(pathML, 'image/svg+xml');
      svg.appendChild(icon.ownerDocument.importNode(pathDoc.firstChild, true));
    }
    // line-height is between 1px and 101px; see @mixin nav-toggle-icon() in _navigation.scss for explanation
    const pct = (parseFloat(window.getComputedStyle(svg).lineHeight) - 1) / 100;
    const w = _size - 2;//width
    const h = w / 2; //height
    const l = 1; // left
    const c = _size / 2; //center
    const r = l + w; //right
    const t = (_size - h) / 2; // top
    const b = t + h; // bottom
    const coordinates = [ // x, y1, y2
      [l, t, b],
      [c, b, t],
      [r, t, b]
    ]
    const d = coordinates.map(([x, y1, y2]) => {
      return { x, y: y1 + ((y2 - y1) * pct) }
    }).reduce((acc, { x, y }, i) => {
      return acc += `${i ? 'L' : 'M'}${x},${y}`
    }, '');
    path.setAttribute('d', d);
    if (this._transitioning) {
      window.requestAnimationFrame(this.renderSVGPath);
    }
  }

  destroy() {
    const { icon, _eventListeners } = this;
    if (icon) {
      instances.remove(icon, INSTANCE_KEY);
      delete icon.dataset[ENHANCED_FLAG];
      delete this.icon;
    }
    _eventListeners.removeListeners();
    this._transitioning = false;
  }

  static watch() {
    // using this approach so it is can be unit tested
    if (ToggleIconInstance._watchTimeout) {
      window.clearTimeout(ToggleIconInstance._watchTimeout);
    }
    // wait 5 seconds for hydration to complete, then stop adding to watch queue
    ToggleIconInstance._watchTimeout = window.setTimeout(() => {
      ToggleIconInstance._watchTimeout = 0;
    }, 5000);
  }
}

ToggleIconInstance.watch();

export { ToggleIconInstance };