export function enableDropdownMenu (dropdown: HTMLSelectElement) {
  if (dropdown.dataset.enhancedDropdownMenu === 'true')  return null
  dropdown.dataset.enhancedDropdownMenu = 'true';
  let state = {
    lastKeyboardTimeout: 0,
    keyboardChange: false,
    restoreValue: ''
  };

  function onChange (event: Event) {
    if (!(<any>event).isMenuAction) {
      state.keyboardChange = !!state.lastKeyboardTimeout;
      if (!state.keyboardChange) {
        fireMenuActionEvent();
      }
    }
  }

  function onKeyDown (event: KeyboardEvent) {
    const key = event.key || event.code
    switch (key) {
      case 'Enter':
        if (state.keyboardChange) {
          event.preventDefault();
          fireMenuActionEvent();
        }
        break;

      case 'Esc':
      case 'Escape':
        if (state.keyboardChange) {
          event.preventDefault();
          dropdown.value = state.restoreValue;
          initState();
        }
        break;

      case 'ArrowUp':
      case 'Up':
      case 'ArrowDown':
      case 'Down':
      case 'ArrowLeft':
      case 'Left':
      case 'ArrowRight':
      case 'Right':
        setLastKeyBoardTimeout();
        break;

      default:
        if (key.length === 1) {
          // character pressed
          setLastKeyBoardTimeout();
        }
    }
  }

  function onFocus () {
    initState();
  }

  function onBlur () {
    if (state.keyboardChange) {
      fireMenuActionEvent();
    }
  }

  function fireMenuActionEvent () {
    window.setTimeout(() => {
      const eventType = 'change';
      let event;
      if ('Event' in window && typeof window.Event === 'function') {
        event = new Event(eventType, { bubbles: true, cancelable: false });
      } else 
      /* istanbul ignore next */
      {
        event = document.createEvent('Event');
        event.initEvent(eventType, true, false);
      }
      (<any>event).isMenuAction = true;
      dropdown.dispatchEvent(event);
      initState();
    })
  }

  function initState () {
    if (state.lastKeyboardTimeout) {
      window.clearTimeout(state.lastKeyboardTimeout);
    }
    state.lastKeyboardTimeout = 0;
    state.keyboardChange = false;
    state.restoreValue = dropdown.value;
  }

  function setLastKeyBoardTimeout() {
    if (state.lastKeyboardTimeout) {
      window.clearTimeout(state.lastKeyboardTimeout);
    }
    state.lastKeyboardTimeout = window.setTimeout(() => {
      state.lastKeyboardTimeout = 0;
    }, 100);
  }

  initState()

  dropdown.addEventListener('change', onChange)
  dropdown.addEventListener('keydown', onKeyDown)
  dropdown.addEventListener('focus', onFocus)
  dropdown.addEventListener('blur', onBlur)

  return function disableDropdownMenu () {
    dropdown.removeEventListener('change', onChange)
    dropdown.removeEventListener('keydown', onKeyDown)
    dropdown.removeEventListener('focus', onFocus)
    dropdown.removeEventListener('blur', onBlur)
    delete dropdown.dataset.enhancedDropdownMenu
  }
}
