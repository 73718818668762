const isDevelopment = checkIsDevelopment();
const noop = () => { };

const devConsole = {
  log: isDevelopment ? writeToConsole('log') : noop,
  info: isDevelopment ? writeToConsole('info') : noop,
  warn: isDevelopment ? writeToConsole('warn') : noop,
  error: isDevelopment ? writeToConsole('error') : noop,
}

function writeToConsole(level: 'log' | 'info' | 'warn' | 'error') {
  return (...msgs: any) => {
    console[level](
      '%cTDS',
      'color: white;background:#004257;font-weight: bold; font-size:10px; padding:2px 6px; border-radius: 6px',
      ...msgs
    )
  }
}

function checkIsDevelopment(): boolean {
  const hostname = window?.location?.hostname;
  return !hostname || /(^localhost|^127\.0\.0\.1$|^0\.0\.0\.0$)/.test(hostname);
}

export { devConsole as default, isDevelopment }

