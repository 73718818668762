import { getChildElements } from "../../../utilities/helpers";
import { normalizeKey } from "../../../utilities/keyboard";
import { CELL_EDITABLE_ATTR } from "./editableDataTableConstants";
import { getColumnIndex } from "./utils";

function makeGrid(table: HTMLTableElement) {
  table.setAttribute('role', 'grid');
  // if cell already tabbable, start with that
  const startingCell = table.querySelector<HTMLTableCellElement>('tbody > tr > [tabindex="0"]') || 
    table.querySelector<HTMLTableCellElement>('tbody > tr > *');

  start(startingCell);

  function start(activeCell?: HTMLTableCellElement, focus?: boolean, navigateTo?: 'up' | 'down' | 'previous' | 'next') {
    table.addEventListener('keydown', onKeydown);
    table.addEventListener('mousedown', onMousedown);
    if (activeCell) {
      let startingCell: HTMLElement;
      switch (navigateTo) {
        case 'previous':
        case 'next':
          startingCell = getNextEditableCell(activeCell, navigateTo === 'next') || activeCell;
          break;
        case 'up':
        case 'down':
          startingCell = getAdjacentColumnCell(activeCell, navigateTo === 'up') || activeCell;
          break;
        default:
          startingCell = activeCell;
      }
      makeFocusable(startingCell, table);
      if (focus) {
        startingCell.focus();
      }
    }
  }

  function stop() {
    clearFocusable(table);
    table.removeEventListener('keydown', onKeydown);
    table.removeEventListener('mousedown', onMousedown);
  }

  function onKeydown(event: KeyboardEvent) {
    const targetEl = event.target as Element;
    const control = targetEl.closest('input, select, textarea'); // refine as needed
    if (control) {
      return;
    }
    const cell = targetEl.closest('td,th') as HTMLTableCellElement;
    if (cell && cell.closest('table') === table) {
      let nextCell: HTMLElement;
      const key = normalizeKey(event);
      switch (key) {
        case 'ArrowLeft':
        case 'ArrowRight':
          nextCell = getAdjacentRowCell(cell, key === 'ArrowRight');
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          nextCell = getAdjacentColumnCell(cell, key === 'ArrowUp');
          break;
        case 'Home':
        case 'End':
          nextCell = event.ctrlKey ? getEndCellEndRow(table, key === 'Home') : nextCell = getEndCell(cell.closest('tr'), key === 'Home');
          break;
        // case 'Tab':
          // Tabbbing to editable cells prevents tabbing into row action buttons or any other focusable element not in an editable cell 
          // But, it sure feels like it tab should navigate editable cells. Until we figure it out...
          // nextCell = getNextEditableCell(cell, !event.shiftKey);
          // break;
      }

      if (nextCell && nextCell !== cell) {
        event.preventDefault();
        makeFocusable(nextCell, table);
        nextCell.focus();
      }
    }
  }

  function onMousedown(event: MouseEvent) {
    if (event.button == 0) {
      const targetEl = event.target as Element;
      // todo: May need to ensure interactive element in cell not being touched. 
      // However, seems focus and click event continues to the interactive element anyway,
      // so ignore for now  
      const cell = targetEl.closest('tbody td,tbody th') as HTMLTableCellElement;
      if (cell && cell.closest('table') === table && !cell.matches(':focus')) {
        makeFocusable(cell, table);
        cell.focus();
      }
    }
  }

  return {
    resume: start,
    stop
  }
}
/**
 * Gets to the first or last visible cell in the table
 * @param table The table to check
 * @param first If true, gets the first cell; otherwise the last 
 * @returns The first or last visible cell in the table
 */
function getEndCellEndRow(table: HTMLTableElement, first: boolean): HTMLElement {
  let endCell: HTMLElement;
  let endRow = table.querySelector(`tbody > tr${first ? ':first-child' : ':last-child'}`);
  while (endRow && !endCell) {
    endCell = getEndCell(endRow, first);
  }
  return endCell;
}

/**
 * Gets the first or last visible cell in a row
 * @param row The row
 * @param first If true, gets the first cell; otherwise the last 
 * @returns The first or last visible cell in the row
 */
function getEndCell(row: Element, first: boolean): HTMLElement {
  let endCell = (first ? row.firstElementChild : row.lastElementChild) as HTMLElement;
  while (endCell && !isVisible(endCell)) {
    endCell = (first ? endCell.nextElementSibling : endCell.previousElementSibling) as HTMLElement;
  }
  return endCell;
}

/**
 *  Gets the next or previous visible cell in a row for a given cell
 * @param cell The cell to start from
 * @param forward If true, gets the next visible cell; otherwise the previous
 * @returns The next or previous visible cell in a row
 */
function getAdjacentRowCell(cell: HTMLElement, forward: boolean): HTMLElement {
  let nextCell = cell;
  while (nextCell) {
    nextCell = (forward ? nextCell.nextElementSibling : nextCell.previousElementSibling) as HTMLElement;
    if (nextCell && isVisible(nextCell)) {
      break;
    }
  }
  return nextCell;
}

/**
 * Gets the next visible cell above or below a given cell 
 * @param cell The cell to start from
 * @param above If true, gets the cell above; otherwise gets the cell below
 * @returns  the next visible cell above or below a given cell 
 */
function getAdjacentColumnCell(cell: HTMLTableCellElement, above: boolean) {
  const cellColIndex = getColumnIndex(cell);
  let row = cell.closest('tr') as HTMLElement;
  let nextCell: HTMLElement;
  while (row && !nextCell) {
    let colIndex = 0;
    row = (above ? row.previousElementSibling : row.nextElementSibling) as HTMLElement;
    if (row) {
      const rowCells = getChildElements(row) as HTMLTableCellElement[];
      // find the first cell in the row that overlaps the given cell
      nextCell = rowCells.find(rowCell => {
        if (colIndex <= cellColIndex && (colIndex + rowCell.colSpan) > cellColIndex && isVisible(rowCell)) {
          return true;
        }
        colIndex += rowCell.colSpan;
        return false;
      });
    }
  }
  return nextCell;
}

function getNextEditableCell(cell: HTMLTableCellElement, forward: boolean) {
  let nextEditableCell: HTMLElement;
  let row = cell.closest('tr') as HTMLElement;
  let referenceCell: HTMLElement = cell;
  while (referenceCell && !nextEditableCell) {
    let nextCell = (forward ? referenceCell.nextElementSibling : referenceCell.previousElementSibling) as HTMLElement;
    if (!nextCell) {
      row = (forward ? row.nextElementSibling : row.previousElementSibling) as HTMLElement;
      nextCell = row && (forward ? row.firstElementChild : row.lastElementChild) as HTMLElement;
    }
    if (nextCell && nextCell.getAttribute(CELL_EDITABLE_ATTR) === 'true') {
      nextEditableCell = nextCell;
    }
    referenceCell = nextCell;
  }
  return nextEditableCell;
}

/**
 * Clears existing focusable cell(s) and makes current cell focusable
 * @param cell 
 * @param table 
 */
function makeFocusable(cell: HTMLElement, table: HTMLElement) {
  if (cell.tabIndex !== 0) {
    clearFocusable(table);
    cell.tabIndex = 0;
  }
}

/**
 * Clears existing focusable cell(s) 
 * @param table 
 */
function clearFocusable(table: HTMLElement) {
  Array.from(table.querySelectorAll('tbody > tr > [tabindex="0"]'))
    .filter((focusableCell: HTMLElement) => focusableCell.closest('table') === table) // ignore nested tables
    .forEach((focusableCell: HTMLElement) => focusableCell.tabIndex = -1);
}

function isVisible(el: HTMLElement) {
  return el.offsetHeight && el.offsetWidth;
}


export default makeGrid;