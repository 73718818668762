import { CellValidationData } from "./CellValidationData";
import { CellValidator } from "./CellValidator";
import { ValidationMessage } from "./validatorsFactory";

export class RequiredCellValidator implements CellValidator {
  _options: ValidationMessage;

  constructor(options: string | ValidationMessage) {
    this._options = (typeof options === 'string') ? { message: options, noconfigure: false } : {...options};
  }

  validate(cellData: CellValidationData): CellValidationData {
    const newValue = (cellData.newValue === 0 ? 0 : cellData.newValue || '').toString().trim();
    return newValue.length ? cellData : { ...cellData, errorMessage: this._options.message, errorReason: 'valueMissing' };
  };

  get type() { return 'required'; }
  get options() { return this._options; }
}