import x1_18 from '@trv-ebus/tds-icons/icons/x-1-18';
import { htmlEncode } from '../../utilities/helpers';
import { CSS_NS, FILE_UPLOAD_CLASSES } from "../../utilities/constants";
import { translations, getLang } from "../../utilities/i18n";
import { FileUploadComponent } from "./FileUploadComponent";
import { setAriaLive, statusCodes } from "./FileUtility";
import { TdsFileInstance } from "./TdsFile";

// creates class name for each error alert by error type
export function getClassNameByStatusCode(statusCode: string) {
  if (statusCode == statusCodes.error || statusCode == statusCodes.sizeError || statusCode == statusCodes.typeError) {
    return `${CSS_NS}file--error`;
  }
  else if (statusCode == statusCodes.success) {
    return `${CSS_NS}file--success`;
  } 
  else if (statusCode == statusCodes.uploading) {
    return `${CSS_NS}file--uploading`;
  }
  return `${CSS_NS}file--ready`;
}

// display error message alert
export function displayErrorAlert(fileUpload: FileUploadComponent) {
  const { el, config, state } = fileUpload;
  const translateHTML = translations(getLang(el)).html;
  var alert = el.querySelector('.tds-alert--error');

  if(alert) {
    const alerts = alert.querySelector('ul');
    if(!alerts) {
      alert.setAttribute('hidden', 'true');
      alert.setAttribute('aria-hidden', 'true');
    }
  }
  // if alert div is present or show alert message is set true 
  // and also error list is present
  if((state._errorList && state._errorList.length > 0) && (config.showErrorAlert || alert)) {
    const dataFocusId = el.querySelector('input').getAttribute('id');
    const newAlert = document.createElement('div');
    newAlert.className = 'tds-alert--error tds-alert--dismissible';
    newAlert.setAttribute('tabindex', "-1");

    // aria-live = assertive | polite | off, default is polite
    setAriaLive(newAlert, config.alertMessageArialive);
    
    newAlert.innerHTML = `
      <div class="tds-alert__title">${htmlEncode(state._errorMsg)}</div>
      <ul class="alert-list"></ul>
      <button hidden aria-label="${translateHTML('dismissAlert')}" class="tds-alert__dismiss" data-focus="#${dataFocusId}">
        ${x1_18.svg({focusable: false})}
      </button>`;
    
    if(!!alert) el.replaceChild(newAlert, alert);
    else {
      const filePicker = el.querySelector(`.${FILE_UPLOAD_CLASSES.DROPAREA}`);
      el.insertBefore(newAlert, filePicker);
    }

    var errorText = '';
    state._errorList.forEach((error: any) => {
      errorText += `<li class="alert-list__item">
        ${error.filename ? `<strong>${htmlEncode(error.filename)}</strong>` : ''}
        <span>${htmlEncode(error.message)}</span>
      </li>`;
    });
    
    const alertArea = newAlert.querySelector('.alert-list');
    alertArea.innerHTML = `${errorText}`;
  }
}

// populating error alert
export function populateErrorAlerts(fileUpload: FileUploadComponent, newFile: TdsFileInstance, errorCode: String, ...args: any[]) {
  const { el, state, config } = fileUpload;
  const { name } = newFile.file;
  const code = errorCode.toString();
  var errorList = (code === 'maxError') ? [] : state._errorList || [];
  const translate = translations(getLang(el)).t;

  switch(code) {
    case "sizeError":
      errorList.push({"type": code, "filename": `${name}`, 'message' : translate('fileSizeAlert', `${config.maxSize}`) });
      break;
    case "typeError":
      errorList.push({"type": code, "filename": `${name}`, 'message' : translate('fileTypeAlert') });
      break;
    case "error":
      errorList.push({"type": code, "filename": `${name}`, 'message' : translate('uploadError') });
      break;
    case "duplicateError":
      errorList.push({"type": code, "filename": `${name}`, 'message': translate('duplicateError') });
      break;
    case "maxError":
      errorList.push({"type": code, 'message': translate('fileMaxSizeAlert', `${args[0]}`, `${config.maxCount}`) });
      break;
  }
  return errorList;
}