import { ModalComponent } from './ModalComponent';
import { EventListeners } from '../../utilities/EventListeners';
import { onTransitionEnd } from '../../utilities/animations';
import { CSS_NS } from '../../utilities/constants'
import { containTabFocus, getConfigElement, getFocusableChildren, focus } from '../../utilities/dialogs';

const MODAL_OPEN_BODY_CLASS = `${CSS_NS}body--modal-open`;
const MODAL_DISMISS_SELECTOR = `[data-dismiss="modal"]`

export const EVENTS = {
  OPEN: 'modal.open',
  OPENED: 'modal.opened',
  CLOSE: 'modal.close',
  CLOSED: 'modal.closed'
}

let nextId = 1;

function getTargetParent(moveToEnd: boolean | string | Element): Element {
  let result: Element;
  if ((moveToEnd as string) === "" || (moveToEnd as string) === "true") {
    result = document.body;
  }
  if (!!moveToEnd && !result) {
    if ((typeof moveToEnd) === 'boolean') {
      result = document.body;
    }
    else if ((typeof moveToEnd) === 'string') {
      const resultAsId = document.getElementById(moveToEnd as string);
      result = !!resultAsId ? resultAsId : document.querySelector(moveToEnd as string);
    }
    else {
      result = moveToEnd as Element;
    }
  }
  return result;
}

export function open(modal: ModalComponent): Function {
  const { config, host, root, store, state } = modal;
  const eventListeners = new EventListeners();
  const { moveToEnd } = config;

  let uncontainTabFocus: Function = null

  if (!state.hidden) {
    return modal.cleanUp;
  }

  //target parent element, where the modal will be moved to when opened  
  const moveToParent = getTargetParent(moveToEnd);

  if (!!moveToParent && host.parentNode !== moveToParent) {
    const placeholder = document.createElement('div');
    const placeholderId = `__tds-modal-placeholder-${++nextId}`;
    placeholder.id = placeholderId;
    placeholder.innerHTML = "<!--- place holder --->";
    host.parentNode.insertBefore(placeholder, host);

    //move element to the end of the target Element for z index layering    
    moveToParent.appendChild(host);

    let deferredCleanUp: Function;
    window.setTimeout(function () {
      deferredCleanUp = open(modal);
    }, 50) // use timeout after move so animation will work

    return () => {
      if (deferredCleanUp) {
        deferredCleanUp();
        const pH = document.getElementById(placeholderId);
        if (pH && pH.parentNode) {
          pH.parentNode.replaceChild(host, pH);
        }
      }
    }
  }

  eventListeners.addListener(document, 'focus', _onFocusAway(modal), true);
  eventListeners.addListener(root, 'mousedown', onBackdropClick(modal));
  eventListeners.addListener(root, 'touchstart', onBackdropClick(modal));
  eventListeners.addListener(host, 'click', onDismissModalClick(modal));
  if (host !== root) {
    eventListeners.addListener(root, 'click', onDismissModalClick(modal));
  }
  eventListeners.addListener(document, 'keydown', onEscape(modal));

  window.setTimeout(function () {
    uncontainTabFocus = containTabFocus(host);
    _initialFocus(modal);
  }, 100)

  setBodyModalOpenState(true, modal)

  const eventInit: any = {};
  if (config.trigger) {
    eventInit.detail = { relatedTarget: config.trigger };
  }


  onTransitionEnd(host, () => {
    const { state } = modal;
    if (state.hidden) {
      return  // in case closed before transition ended
    }

    modal.dispatchEvent(EVENTS.OPENED, eventInit);
    store.update(v => {
      return { ...v, transitioning: false }
    })
  });

  store.update(v => {
    return { ...v, hidden: false, transitioning: true }
  })
  modal.dispatchEvent(EVENTS.OPEN, eventInit);

  const clearUpEverything = () => {
    eventListeners.removeListeners();
    if (uncontainTabFocus) {
      uncontainTabFocus();
    }
    setBodyModalOpenState(false, modal);
  }
  return clearUpEverything;
}

export function close(modal: ModalComponent, val = '') {
  const { host, store, state } = modal;
  if (!state.hidden) {
    const eventInit = {
      cancelable: true,
      detail: val
    };
    if (modal.dispatchEvent(EVENTS.CLOSE, eventInit)) {
      const { config } = modal;

      onTransitionEnd(host, () => {
        eventInit.cancelable = false;
        modal.dispatchEvent(EVENTS.CLOSED, eventInit);
        modal.cleanUp();
        store.update(v => {
          return { ...v, transitioning: false }
        })

      });

      modal.returnValue = val;
      store.update(v => {
        return { ...v, hidden: true, transitioning: true }
      })

      const returnTo = getConfigElement(config.returnTo || config.trigger, document);
      if (returnTo && returnTo.focus) {
        returnTo.focus();
      }
    }
  }
}

//////////////////////////////////////////////////////////////
// event handlers

function onBackdropClick(modal: ModalComponent) {
  return (e: Event) => { 
    const { config, root } = modal;
    if (e.type !== 'mousedown' || (e as MouseEvent).button === 0 ) {
      if (e.target === root && config.backdropClose) {
        e.preventDefault();
        close(modal, 'backdrop');
      }
    }
  }
}

function onDismissModalClick(modal: ModalComponent) {
  return (e: Event) => {
    const target: Element = <Element>e.target;
    const dismiss: any = target.closest(`${modal.dismissButtonSelector}, ${MODAL_DISMISS_SELECTOR}`);
    if (dismiss) {
      close(modal, dismiss.value);
    }
  }
}

function onEscape(modal: ModalComponent) {
  return (e: KeyboardEvent) => {
    const { config } = modal;
    if (config.escapeClose) {
      switch (e.key || `${e.which}`) {
        case 'Escape':
        case 'Esc':
        case '27':
          close(modal, 'escape');
      }
    }
  }
}

function _onFocusAway(modal: ModalComponent) {
  return (e: Event) => {
    const target: Element = e.target as Element;
    const { state } = modal;
    if (!state.hidden && !state.transitioning) {
      // if focus leaves the dialog, for instance if clicked on address bar then tab in,
      // return to 1st element
      const { host, config } = modal;
      if (host && target !== host && !host.contains(target) && !config.modeless) {
        _initialFocus(modal, true);
      }
    }
  }
}

//////////////////////////////////////////////////////

function _initialFocus(modal: ModalComponent, forceFirst = false) {
  const { config, host } = modal;
  if (!forceFirst && config.initialFocus === "false") {
    return
  }
  let initialFocus: any = !forceFirst && config.initialFocus && getConfigElement(config.initialFocus, host);
  if (!initialFocus) {
    const [firstFocusable, secondFocusable] = getFocusableChildren(host);
    if (firstFocusable) {
      initialFocus = firstFocusable;
      if (firstFocusable.matches(modal.dismissButtonSelector) && secondFocusable) {
        initialFocus = secondFocusable;
      }
    } else {
      initialFocus = host;
    }
  }
  focus(initialFocus, host);
}

function setBodyModalOpenState(open: boolean, modal: ModalComponent) {
  const body = document.body;
  if (open) {
    createStyleSheet();
    const vScrollBarWidth = window.innerWidth - body.clientWidth;
    if (vScrollBarWidth) {
      body.style.paddingRight = `${vScrollBarWidth}px`;
    }
    document.body.classList.add(MODAL_OPEN_BODY_CLASS);
  }
  else {
    body.style.removeProperty('padding-right');
    body.classList.remove(MODAL_OPEN_BODY_CLASS);
  }
  preventIOSScroll(open, modal);
}

function preventIOSScroll(open: boolean, modal: ModalComponent) {
  const { host } = modal;
  if (open) {
    if (overflowScrollingTouchSupported()) {
      const scrollTop = (host as any).stored_scrollTop = window.pageYOffset || document.body.scrollTop;
      setTimeout(() => {
        document.body.style.top = `-${scrollTop}px`;
      }, 1000);
    }
  } else if (overflowScrollingTouchSupported()) {
    document.body.style.removeProperty("top");
    const scrollToY = (host as any).stored_scrollTop;
    delete (host as any).stored_scrollTop;
    window.scrollTo(window.pageXOffset, scrollToY);
  }
}

/**
 * Creates a stylesheet (first time only) that contains classes to apply to the body element when the modal is open
 * The styles ae intended to prevent scrolling of the body while the modal is open
 */
function createStyleSheet() {
  const id = `__${MODAL_OPEN_BODY_CLASS}`; // use class name as id
  let style = document.getElementById(id);
  if (!style) {
    try {
      style = document.createElement('style');
      style.id = id;
      // WebKit hack :(
      style.appendChild(document.createTextNode(""));
      // need to append element for it to become a CSSStyleSheet
      document.head.appendChild(style);

      const sheet = (<HTMLStyleElement>style).sheet as CSSStyleSheet;
      const preventIIOScroll = overflowScrollingTouchSupported() ? `
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    ` : '';
      const rule = `.${MODAL_OPEN_BODY_CLASS} {
      overflow: hidden;
      -webkit-overflow-scrolling: touch;
      ${preventIIOScroll}
    }`;
      sheet.insertRule(rule, 0);
    } catch (e) { }
  }
}

function overflowScrollingTouchSupported(): boolean {
  return (window.CSS && CSS.supports && CSS.supports("-webkit-overflow-scrolling: touch"));
}
