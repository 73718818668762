
interface ClickableConfig {
  clickableFocus?: string;
}

export function bindClickable(container: HTMLElement, clickableEl: HTMLElement, config: ClickableConfig = {}) : Function {

  let start: number, finish: number;
  const cardFocus = 'tds-clickable-focus';
  const activeClass = config.clickableFocus || cardFocus;

  const onMousedown = () => {
    start = new Date().getTime();
  } 
  const onMouseup = (e: MouseEvent) => {
    if (clickableEl && (!clickableEl.contains(e.target as Node)) && (e.button === 0)) {
      finish = new Date().getTime();
      if (((finish - start) < 200)) {
        clickableEl.click();
      }
    }
  } 

  const onFocusBlur = (e: Event) => {
    const method = e.type === 'focus' ? 'add' : 'remove';
    container.classList[method](activeClass);
  } 

  container.addEventListener('mousedown', onMousedown);
  container.addEventListener('mouseup', onMouseup);
  clickableEl.addEventListener('focus', onFocusBlur);
  clickableEl.addEventListener('blur', onFocusBlur);

  return () => {
    container.removeEventListener('mousedown', onMousedown);
    container.removeEventListener('mouseup', onMouseup);
    clickableEl.removeEventListener('focus', onFocusBlur);
    clickableEl.removeEventListener('blur', onFocusBlur);
    container = undefined;
    clickableEl = undefined;
  }
}

export { ClickableConfig }
