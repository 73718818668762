export function calcGoToDate( activeDate: Date, action: string, locale: any, dateStr?: string) : Date {
  const {firstDayOfWeek} = locale;
  let goToYear = activeDate.getFullYear()
  let goToMonth  = activeDate.getMonth()
  let goToDay = activeDate.getDate()
  let change = 1
  action = action.replace(/^([+-])/, ($) => {
    if ($ === '-') {
      change = -1
    }
    return ''
  })

  switch (action) {
    case 'year':
      goToYear += change
      break

    case 'month':
      goToMonth += change
      break

    case 'day':
      goToDay += change
      break

    case 'week':
      goToDay += (change * 7)
      break

    case 'eow':
      const relativeDayOfWeek = ( activeDate.getDay() + 7 - firstDayOfWeek) % 7
      goToDay += (change === -1 ? 0 : 6) - relativeDayOfWeek
      break

    case 'today':
      const today = new Date()
      goToYear = today.getFullYear()
      goToMonth = today.getMonth()
      goToDay = today.getDate()
      break;

    case 'goto':
      const dateParts = dateStr.split('-')
      goToYear = parseInt(dateParts[0])
      goToMonth = parseInt(dateParts[1]) - 1
      goToDay = parseInt(dateParts[2])
  }

  if (goToYear !== activeDate.getFullYear() ||
    goToMonth !== activeDate.getMonth() ||
    goToDay !== activeDate.getDate())
  {
    const newActiveDate = new Date(goToYear, goToMonth, goToDay)
    if (goToDay === activeDate.getDate() && newActiveDate.getMonth() !== (( goToMonth + 12) % 12)) {
      // moved from one month to another month without that day
      // move to end of month
      newActiveDate.setDate(0)
    }
    return newActiveDate
  }
  else {
    return activeDate
  }
}
