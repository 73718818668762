
const TRANSITION_END = 'transitionend'
const onTransitionEnd = (el: EventTarget, callback: Function, propertyName?: string) => {
  let timeoutId: number;
  function eventHandler(event: TransitionEvent) {
    if (event.target === el && (!propertyName || event.propertyName === propertyName)) {
      run();
    }
  }
  function run() {
    clearTimeout(timeoutId);
    el.removeEventListener(TRANSITION_END, eventHandler);
    callback();
  }
  timeoutId = window.setTimeout(run, 1000); //in case event never fires
  el.addEventListener(TRANSITION_END, eventHandler);
}

/**
 * Drives basic steps of an animation: prep, set, complete. 
 * @param el Drives the basic steps of running an animation: Prep, start, complete 
 * @param steps Implementation of each animation step
 * @param propertyName The name of the CSS property to listen for the transitionend event of. 
 * If not set, calls `complete` on the first tranistionend event received.
 */
function runAnimation(el: Element, steps: {
  /**
   * Prepare the element for animation. Ensure `display` is not none and set initial CSS state before
   * animation begins. For instance, `height: 0` when expanding. After this function completes,
   * runs a quick `setTimeout` to allow changes to set in before calling `set`.
   * @param el The element animated
   */
  prep?(el: Element): void

  /**
   * Set the CSS state to transition to. For instance, `height: 60px` when expanding. Called after
   * a brief timeout after `prep` is called.  
   * @param el 
   */
  set?(el: Element): void

  /**
   * Called when animation completes. Set the final state for the element. 
   * Update the element's class, reset or clear its styles.
   * @param el 
   */
  complete?(el: Element): void

}, propertyName?: string) {
  const { prep, set, complete } = steps;
  if (prep) {
    prep(el);
  }

  window.setTimeout(() => {
    onTransitionEnd(el, () => {
      if (complete) {
        complete(el);
      }
    }, propertyName);

    if (set) {
      set(el);
    }
  }, 50);
}

export {
  onTransitionEnd,
  runAnimation
}