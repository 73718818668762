import { CellValidationData } from "./CellValidationData";
import { CellValidator } from "./CellValidator";
import { expressions } from "./patterns";
import { PatternValidatorOptions } from "./validatorsFactory";

export class PatternCellValidator implements CellValidator {
  _options: PatternValidatorOptions;

  constructor(options: PatternValidatorOptions) {
    this._options = options;
  }

  validate(cellData: CellValidationData): CellValidationData {
    const { pattern, message } = this._options;
    const regExp = typeof pattern === 'string' ? expressions[pattern] || new RegExp(pattern) : pattern;

    const newValue = (cellData.newValue === 0 ? 0 : cellData.newValue || '').toString().trim();
    if (newValue && !regExp.test(newValue)) {
      return  { ...cellData, errorMessage: message, errorReason: 'patternMismatch' }
    }
    return cellData;
  }

  get type() { return 'pattern'; }
  get options() { return this._options; }
}
