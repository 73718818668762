import { instances } from '../../utilities/instances';
import { NAMESPACE, CSS_NS } from '../../utilities/constants';
import { configFromDataAttributes, htmlEncode, watchDataAttributeChange } from '../../utilities/helpers';
import { createCustomEvent } from '../../utilities/customEvent';
import onDOMChanges from '../../utilities/onDOMChanges';
import { TablePaginationComponent } from './TablePaginationComponent';
import { TablePaginationConfig } from './TablePaginationConfig';
import { bindTablePagination, calculatePageInputWidth, getTotalPages, PAGINATION } from './TablePaginationBehavior';
import arrowLeft1_18 from '@trv-ebus/tds-icons/icons/arrow-left-1-18';
import arrowRight1_18 from '@trv-ebus/tds-icons/icons/arrow-right-1-18';
import { translations } from '../../utilities/i18n';

const ENHANCED_FLAG = 'enhancedTablePagination';
const INSTANCE_KEY = `${NAMESPACE}table-pagination`;
const PATTERN_SELECTOR = `${CSS_NS}table-pagination`;
const STACKED_CLASS = `${CSS_NS}table-pagination--stacked`;

let nextId = 0;

// Default configuration for multiselect
const defaultConfig = {
  page: 1,
  pageSize: 10,
  showRecordsDetail: false,
  totalItems: 0
};

class _TablePaginationInstance implements TablePaginationComponent {
  root: HTMLElement;
  host: HTMLElement;
  config: TablePaginationConfig;
  id: string;
  onDestroy: Function[] = [];
  _stacked = false;
  _translation: Function;
  _setAriaLabel: boolean;
  _renderedPage: boolean;
  _renderedPageCount: boolean;

  constructor(element: HTMLElement) {
    this.root = this.host = element;
    this.id = element.id || `__tds-table-pagination-${++nextId}`;
    element.dataset[ENHANCED_FLAG] = "true";
    this._translation = translations(element).t;
    this._setAriaLabel = !element.hasAttribute('aria-label');
    if (!element.hasAttribute('role')) {
      element.setAttribute('role', 'group');
    }

    // ---- Initialize Config ----
    // extract configuration from data attributes on element
    const dataConfig: any = configFromDataAttributes(this.root);
    this.setConfig({ ...defaultConfig, ...dataConfig });

    this.render();
    this.updatePagination();
    const unbind = bindTablePagination(this);

    const unwatch = watchDataAttributeChange(element, this.setConfig.bind(this));
    this.onDestroy = [unbind, unwatch];

    instances.set(element, INSTANCE_KEY, this);
  }

  setConfig(config: any = {}) {
    config = this.processConfig(config);
    if (this.config) {
      this.config = { ...this.config, ...config };
      this.updatePagination(config);
    } else {
      this.config = config
    }
  }

  // getters and setters
  // get and set page
  get page() {
    return this.config.page;
  }
  set page(page: number) {
    this.setConfig({ page })
  }

  // get and set pageSize
  get pageSize() {
    return this.config.pageSize;
  }
  set pageSize(pageSize: number) {
    this.setConfig({ pageSize })
  }

  // get and set pageSizeOptions
  get pageSizeOptions() {
    return this.config.pageSizeOptions;
  }
  set pageSizeOptions(pageSizeOptions: string | (string | number)[]) {
    this.setConfig({ pageSizeOptions })
  }

  // get and set showRecordsDetail
  get showRecordsDetail() {
    return this.config.showRecordsDetail;
  }
  set showRecordsDetail(showRecordsDetail: boolean) {
    this.setConfig({ showRecordsDetail })
  }

  // get and set totalItems
  get totalItems() {
    return this.config.totalItems;
  }
  set totalItems(totalItems: number) {
    this.setConfig({ totalItems })
  }

  // get and set page template
  get pageTemplate() {
    return this.config.pageTemplate;
  }
  set pageTemplate(pageTemplate: string) {
    this.setConfig({ pageTemplate })
  }

  // get and set page size template
  get pageSizeTemplate() {
    return this.config.pageSizeTemplate;
  }
  set pageSizeTemplate(pageSizeTemplate: string) {
    this.setConfig({ pageSizeTemplate })
  }

  // get and set records template
  get recordsTemplate() {
    return this.config.recordsTemplate;
  }
  set recordsTemplate(recordsTemplate: string) {
    this.setConfig({ recordsTemplate })
  }

  get noAutoUpdate() {
    return this.config.noAutoUpdate;
  }

  get stacked() {
    return this._stacked;
  }

  set stacked(stacked: boolean) {
    if (this._stacked !== stacked) {
      this._stacked = stacked;
      this.root.classList[stacked ? 'add' : 'remove'](STACKED_CLASS);
    }
  }

  get _pageTpl() {
    return this.config.pageTemplate || this._translation(this.totalItems === 0 ? 'pageX' : 'pageXofY');
  }

  get _pageSizeTpl() {
    return this.config.pageSizeTemplate || this._translation('rowPerPageText');
  }

  get _recordsTpl() {
    return this.config.recordsTemplate ||
      this._translation(this.totalItems === 0 ? "showingRecordsTextWithoutTotal" : "showingRecordsText");
  }

  dispatchEvent(eventType: string, eventInit?: any): boolean {
    return this.host.dispatchEvent(createCustomEvent(eventType, eventInit));
  }

  destroy() {
    while (this.onDestroy && this.onDestroy.length) {
      const fn = this.onDestroy.pop();
      fn();
    }
    if (this.root) {
      delete this.root.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.host, INSTANCE_KEY);
    this.root = this.host = null;
  }

  //handles getting config and preProcessing of values
  processConfig(config: any) {
    const newConfig: any = {};

    Object.keys(config).forEach((key) => {
      var value = config[key];
      switch (key) {
        case 'page':
        case 'pageSize':
        case 'totalItems':
          if (typeof value == 'string') value = value !== "" ? parseInt(value) : 0;
          if (typeof value == 'boolean') value = 0;
          break;

        case 'pageSizeOptions':
          if (typeof value == 'string') value = value.split(',');
          value = value.map((arr: any) => {
            if (typeof arr === 'string') {
              arr = arr.trim();
              const result = /\D/.test(arr);
              arr = result ? arr : parseInt(arr)
            }
            return arr;
          });
          break;

        case 'showRecordsDetail':
          if (typeof value == 'string') {
            value = value === 'true' || value === "" ? true : value === 'false' ? false : value;
          }
      }
      newConfig[key] = value;
    });

    return newConfig;
  }

  //=================================
  // RENDER SECTION
  //================================
  render() {
    this.root.setAttribute('id', this.id); // setting up id
    this.initPageSize();
    this.renderPageSize();
    this.renderPageCount();
    this.renderPage();
  }

  initPageSize() {
    const { root, config, pageSize } = this;
    const { pageSizeOptions } = config;

    let newPageSize = pageSize;
    const PAGE_SIZE_SELECT: HTMLSelectElement = root.querySelector(`.${PAGINATION.SIZE} select`);
    if (PAGE_SIZE_SELECT && !isNaN(parseInt(PAGE_SIZE_SELECT.value))) {
      newPageSize = parseInt(PAGE_SIZE_SELECT.value);
    } 
    // pageSizeOptions: string | (string | number)[], newPageSize: number
    // check for typeof pageSizeOptions is not string is required 
    // to check [Array].include(number)
    else if (pageSizeOptions && typeof pageSizeOptions !== 'string' && !pageSizeOptions.includes(newPageSize)) {
      newPageSize = pageSizeOptions[0] as number;
    }
    if (this.pageSize !== newPageSize) this.pageSize = newPageSize;
  }

  /* 
   * <span class="page-size"></span>
  */
  renderPageSize() {
    const { root, config } = this;
    let PAGE_SIZE = root.querySelector(`.${PAGINATION.SIZE}`);

    // if page_size element doesn't exist but the data config is setup
    const { pageSizeOptions } = config;

    if (pageSizeOptions && pageSizeOptions.length > 1) {

      if (!PAGE_SIZE) {
        PAGE_SIZE = document.createElement('label');
        PAGE_SIZE.setAttribute('class', PAGINATION.SIZE);
      }

      const html = (PAGE_SIZE.innerHTML).trim();
      PAGE_SIZE.innerHTML = (html !== "" ? html : htmlEncode(this._pageSizeTpl))
        .replace("{size}", `<select></select>`);

      root.insertBefore(PAGE_SIZE, root.firstChild);
    }
  }

  /* 
   * <span class="page-count"></span>
  */
  renderPageCount() {
    const { config, root } = this;

    let PAGE_COUNT = root.querySelector(`.${PAGINATION.COUNT}`);
    // if page_count doesn't exist or the config to display label is not setup
    if (!PAGE_COUNT && config.showRecordsDetail) {
      PAGE_COUNT = document.createElement('span');
      PAGE_COUNT.setAttribute('class', `${PAGINATION.COUNT}`);

      // if page_size exists insert after that else treat as first element
      const PAGE_SIZE = root.querySelector(`.${PAGINATION.SIZE}`);
      if (PAGE_SIZE) {
        root.insertBefore(PAGE_COUNT, PAGE_SIZE.nextElementSibling);
      } else {
        root.insertBefore(PAGE_COUNT, root.firstChild);
      }
    }

    if (config.showRecordsDetail) {
      const html = (PAGE_COUNT.innerHTML).trim();
      this._renderedPageCount = !html;
      PAGE_COUNT.innerHTML = this.renderPageCountHTML(html)
    }
  }

  renderPageCountHTML(html?: string): string {
    return (html ? html : htmlEncode(this._recordsTpl))
      .replace("{start}", `<span data-items-start></span>`)
      .replace("{end}", `<span data-items-end></span>`)
      .replace("{total}", `<span data-items-total></span>`)
  }

  /* 
   * <span class="page">
   *  <span class="page-numbers"></span>
   *  <span class="page-nav"></span>
   * </span>
  */
  renderPage() {
    const { page, root, totalItems, _translation: translation } = this;
    const totalPages = getTotalPages(this);

    // Pagination -- Main
    let PAGE = root.querySelector(`.${PAGINATION.PAGE}`);
    // if page element is not exists create it, add to root as last child
    if (!PAGE) {
      PAGE = document.createElement('span');
      PAGE.setAttribute('class', PAGINATION.PAGE);
      root.appendChild(PAGE);
    }

    // Pagination -- Page number select dropdown
    let PAGE_NUMBER = PAGE.querySelector(`.${PAGINATION.PAGE_NUMBER}`);
    // if page_number element doesn't exits create it and add to page element
    // then add page number select dropdown, if exists just add it
    if (!PAGE_NUMBER) {
      PAGE_NUMBER = document.createElement('label');
      PAGE_NUMBER.setAttribute('class', PAGINATION.PAGE_NUMBER);
      PAGE.insertBefore(PAGE_NUMBER, PAGE.lastChild);
    }

    const html = (PAGE_NUMBER.innerHTML).trim();
    this._renderedPage = !html;
    PAGE_NUMBER.innerHTML = (html !== "" ? html : htmlEncode(this._pageTpl))
      .replace('{page}', `<input type="number" min="1" />`)
      .replace("{total}", `<span data-total-pages></span>`);

    // Pagination -- Navigation
    let PAGE_NAV = PAGE.querySelector(`.${PAGINATION.PAGE_NAV}`);
    const prevState = (page === 1) ? 'true' : 'false';
    const nextState = (totalItems !== 0 && page > totalPages - 1) ? 'true' : 'false';
    // if page navigation doesn't exist create it and add it else do nothing
    if (!PAGE_NAV) {
      PAGE_NAV = document.createElement('span');
      PAGE_NAV.setAttribute('class', PAGINATION.PAGE_NAV);

      const previousLabel = htmlEncode(translation('previousLabel'));
      const nextLabel = htmlEncode(translation('nextLabel'));

      PAGE_NAV.innerHTML = `
        <button aria-label="${previousLabel}" 
          type="button" class="${PAGINATION.PREVIOUS_BTN}" aria-disabled="${prevState}">
          ${arrowLeft1_18.svg()}
        </button>
        <button aria-label="${nextLabel}" 
          type="button" class="${PAGINATION.NEXT_BTN}" aria-disabled="${nextState}">
          ${arrowRight1_18.svg()}
        </button>
      `;
      PAGE.insertBefore(PAGE_NAV, PAGE_NUMBER.nextElementSibling)
    }

    // calculate the width of page number with initial page number value
    calculatePageInputWidth(this);
  }

  // =================================
  // UPDATE SECTION
  // =================================
  updatePagination(config: any = this.config) {
    this.updatePageSizeSelect(config);
    this.updatePageCount(config);
    this.updatePageNumber();
    this.updateAriaLabel();
  }

  updatePageSizeSelect(config: any) {
    const { root, pageSize } = this;
    const PAGE_SIZE_SELECT: HTMLSelectElement = root.querySelector(`.${PAGINATION.SIZE} select`);
    if (PAGE_SIZE_SELECT) {
      const { pageSizeOptions } = config;
      if (typeof pageSizeOptions !== 'undefined') {
        // Either initializing or options have changed. (Re)Render the option elements here.  
        let options = "";
        for (let index = 0; index < pageSizeOptions.length; index++) {
          const option = pageSizeOptions[index];
          // for pageSizeOptions: [100, 500, 1000, "All"];
          const value =  typeof option === "string" ? 0 : option;
          options += `<option value="${value}" ${pageSize === value ? 'selected' : ''}>${htmlEncode(option.toString())}</option>`;
        }

        PAGE_SIZE_SELECT.innerHTML = options;
      }
    }
  }
  updatePageCount(config: any) {
    const { page, pageSize, totalItems, root, _translation: translation, _renderedPageCount } = this;

    // updating showing Records Label
    let PAGE_COUNT = root.querySelector(`.${PAGINATION.COUNT}`);
    if (config.showRecordsDetail === true && !PAGE_COUNT) {
      // add count element if it doesn't exists 
      this.renderPageCount();
      PAGE_COUNT = root.querySelector(`.${PAGINATION.COUNT}`);
    } else if (config.showRecordsDetail === false && PAGE_COUNT) {
      // remove count element if it exists 
      PAGE_COUNT.remove();
      PAGE_COUNT = null;
    }

    if (PAGE_COUNT) {
      let itemsStart: HTMLElement = PAGE_COUNT.querySelector('[data-items-start]');
      let itemsEnd: HTMLElement = PAGE_COUNT.querySelector('[data-items-end]');
      let itemsTotal: HTMLElement = PAGE_COUNT.querySelector('[data-items-total]');

      const start = (page - 1) * pageSize + 1;
      const end = Math.min(start + pageSize - 1, totalItems === 0 ? (start + pageSize - 1) : totalItems);

      if (!itemsTotal && totalItems > 0 && _renderedPageCount) {
        PAGE_COUNT.innerHTML = this.renderPageCountHTML();
        itemsStart = PAGE_COUNT.querySelector('[data-items-start]');
        itemsEnd = PAGE_COUNT.querySelector('[data-items-end]');
        itemsTotal = PAGE_COUNT.querySelector('[data-items-total]');
      }

      if (itemsStart) {
        itemsStart.textContent = start.toString();
      }
      if (itemsEnd) {
        itemsEnd.textContent = end === 0 ? translation("all") : end.toString();
      }
      if (itemsTotal) {
        itemsTotal.textContent = totalItems === 0 ? translation("manyText") : totalItems.toString();
      }
    }
  }
  updatePageNumber() {
    const { root, page, totalItems, _translation: translation, _renderedPage } = this;
    const totalPages = getTotalPages(this);

    // Page Number Input
    const PAGE_NUMBER_INPUT: HTMLInputElement = root.querySelector(`.${PAGINATION.PAGE_NUMBER} input`);
    if (PAGE_NUMBER_INPUT) {
      if (PAGE_NUMBER_INPUT.value !== page.toString()) {
        PAGE_NUMBER_INPUT.value = page.toString();
      }
      const maxValue = parseInt(PAGE_NUMBER_INPUT.max);
      if (maxValue !== totalPages) {
        if (totalItems !== 0) {
          PAGE_NUMBER_INPUT.max = totalPages.toString();
        }
      }

      calculatePageInputWidth(this);
    }

    const PAGE_TOTAL = root.querySelector(`.${PAGINATION.PAGE_NUMBER} [data-total-pages]`);
    if (PAGE_TOTAL) {
      PAGE_TOTAL.textContent = totalPages === 0 ? translation("manyText") : totalPages.toString();
    } else if (totalPages > 0 && _renderedPage) {
      this.reRenderTotalPages(totalPages);
    }

    // update navigation buttons
    const previousBtn = root.querySelector(`.${PAGINATION.PREVIOUS_BTN}`);
    const nextBtn = root.querySelector(`.${PAGINATION.NEXT_BTN}`);

    // previous button - first page or second page and above
    if (previousBtn) {
      page > 1 ?
        previousBtn.removeAttribute('aria-disabled') :
        previousBtn.setAttribute('aria-disabled', 'true');
    }

    // next button - last page and below or last page
    if (nextBtn) {
      page < totalPages || totalPages === 0 ?
        nextBtn.removeAttribute('aria-disabled') :
        nextBtn.setAttribute('aria-disabled', 'true');
    }
  }

  reRenderTotalPages(totalPages: number) {
    // re-renders the page section to include total pages. Here, we need to maintain the exising input element because
    // listeners have already been added to it in bindPagination
    const { root, _pageTpl } = this;
    const PAGE_NUMBER: HTMLInputElement = root.querySelector(`.${PAGINATION.PAGE_NUMBER}`);
    const PAGE_NUMBER_INPUT: HTMLInputElement = PAGE_NUMBER && PAGE_NUMBER.querySelector('input');

    if (PAGE_NUMBER_INPUT && _pageTpl && _pageTpl.indexOf('{total}') > -1) {
      const html = htmlEncode(_pageTpl)
        .replace("{total}", `<span data-total-pages>${totalPages}</span>`);
      const parts = html.split(/({page})/).map((s: string) => {
        if (s === '{page}') {
          return PAGE_NUMBER_INPUT;
        }
        const span = document.createElement('span');
        span.innerHTML = s;
        return span;
      });
      PAGE_NUMBER.innerHTML = '';
      parts.forEach((part: Element) => PAGE_NUMBER.appendChild(part));
    }
  }

  updateAriaLabel() {
    if (this._setAriaLabel) {
      const { page, pageSize, showRecordsDetail, pageSizeOptions, _translation: translation, totalItems, host } = this;
      const totalPages = getTotalPages(this);
      const start = (page - 1) * pageSize + 1;
      const end = Math.min(start + pageSize - 1, totalItems === 0 ? (start + pageSize - 1) : totalItems);
      const parts = [this._pageTpl
        .replace('{page}', page.toString())
        .replace('{total}', totalPages === 0 ? translation("manyText") : totalPages.toString())
      ];

      if (showRecordsDetail) {
        parts.push(this._recordsTpl
          .replace('{start}', start.toString())
          .replace('{end}', end.toString())
          .replace('{total}', totalItems === 0 ? translation("manyText") : totalItems.toString())
        )
      }

      if (pageSizeOptions && pageSizeOptions.length) {
        parts.push(this._pageSizeTpl.replace('{size}', pageSize.toString()))
      }

      host.setAttribute('aria-label', parts.join(', '));
    }
  }

}

class TablePagination {
  _instance: _TablePaginationInstance;
  constructor(element: HTMLElement, config?: any) {
    this._instance = <_TablePaginationInstance>instances.get(element, INSTANCE_KEY) || new _TablePaginationInstance(element);
    if (config) this.setConfig(config);
  }

  setConfig(config: any) {
    this._instance.setConfig(config)
  }

  get page() {
    return this._instance.page;
  }
  set page(value: number) {
    this._instance.page = value;
  }

  get pageSize() {
    return this._instance.pageSize;
  }
  set pageSize(value: number) {
    this._instance.pageSize = value;
  }

  get pageSizeOptions() {
    return this._instance.pageSizeOptions;
  }
  set pageSizeOptions(value: string | (string | number)[]) {
    this._instance.pageSizeOptions = value;
  }

  get showRecordsDetail() {
    return this._instance.showRecordsDetail;
  }
  set showRecordsDetail(value: boolean) {
    this._instance.showRecordsDetail = value;
  }

  get totalItems() {
    return this._instance.totalItems;
  }
  set totalItems(value: number) {
    this._instance.totalItems = value;
  }

  get pageTemplate() {
    return this._instance.pageTemplate;
  }
  set pageTemplate(value: string) {
    this._instance.pageTemplate = value;
  }

  get pageSizeTemplate() {
    return this._instance.pageSizeTemplate;
  }
  set pageSizeTemplate(value: string) {
    this._instance.pageSizeTemplate = value;
  }

  get recordsTemplate() {
    return this._instance.recordsTemplate;
  }
  set recordsTemplate(value: string) {
    this._instance.recordsTemplate = value;
  }

  get noAutoUpdate() {
    return this._instance.noAutoUpdate;
  }

  destroy() {
    const { _instance } = this;
    delete this._instance;
    return _instance.destroy();
  }
}

onDOMChanges(`.${PATTERN_SELECTOR}`,
  function onPatternAdded(element: HTMLElement) {
    if (!element.dataset[ENHANCED_FLAG]) {
      new TablePagination(element)
    }
  },
  function onPatternRemoved(element: HTMLElement) {
    if (element.dataset[ENHANCED_FLAG] === "true") {
      new TablePagination(element).destroy();
    }
  }
);

export { TablePagination }
