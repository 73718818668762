const storeInstances: { [id: string] : any } = {}; 
let id = 1

const instances = {
  set(element: HTMLElement, key: string, instance: any) {
    const el: any = element;
    if (typeof el.tdsEnhancers === 'undefined') {
      el.tdsEnhancers = {}
    }

    if (typeof el.tdsEnhancers[key] === 'undefined') {
      el.tdsEnhancers[key] = {id}
      id++
    }

    storeInstances[el.tdsEnhancers[key].id] = instance
  },

  get(element: HTMLElement, key: string) : any {
    const el: any = element;
    if (!el || typeof el.tdsEnhancers === 'undefined' || typeof el.tdsEnhancers[key] === 'undefined') {
      return null
    }

    return storeInstances[el.tdsEnhancers[key].id] || null
  },

  remove(element: HTMLElement, key: string) {
    const el: any = element;
    if (!el || typeof el.tdsEnhancers === 'undefined' || typeof el.tdsEnhancers[key] === 'undefined') {
      return
    }

    delete storeInstances[el.tdsEnhancers[key].id]
    delete el.tdsEnhancers[key]
    if (Object.keys(el.tdsEnhancers).length === 0) {
      delete el.tdsEnhancers
    }
  }
}

export { instances }
