import { expressions } from "../validators/patterns";
import { AbstractCellEditor } from "./AbstractCellEditor";
import { CellEditorComponent, CellEditorRenderData } from "./CellEditorComponent";

export class TextInputCellEditor extends AbstractCellEditor implements CellEditorComponent {
  _type: string
  constructor(type: 'text' | 'number' = 'text') {
    super();
    this._type = type;
  }

  render({cell, cellValue, initialValue, action, container, columnHeader, validators}: CellEditorRenderData): void {
    const input = this.editorElement = cell.ownerDocument.createElement('input') as HTMLInputElement;
    input.type = this._type;
    input.setAttribute('aria-label', columnHeader);
    container.appendChild(input);
    input.value = typeof initialValue === 'undefined' ? cellValue : action === 'typing' ? '' : initialValue;
    input.addEventListener('input', this.reportUpdate);
    if (validators) {
      validators.forEach(validator => {
        const options = validator.options;
        switch (validator.type) {
          case 'required':
            input.required = true;
            break;          
          case 'range':
            configureRange(input, options);
            break;
          case 'pattern':
            configurePattern(input, options);
            break;
          case 'length':
            configureLength(input, options);
            break;
        }
      })
    }
  }
  
  getValue() {
    const { editorElement: editorElement } = this;
    return editorElement ? (editorElement as HTMLInputElement).value : ''; 
  }

  setValue(value: any = '') {
    const editorElement = this.editorElement as HTMLInputElement;
    if (editorElement) {
      editorElement.value = value.toString();
    }
  }
}

function configureRange(input: HTMLInputElement, options: any) {
  const { min, max, noconfigure } = options;
  if (!noconfigure) {
      if (typeof min !== 'undefined' || typeof max !== 'undefined') {
        const type = typeof min === 'number' || typeof max === 'number' ? 'number' : 'date';
        input.type = type;
      }

      if (typeof min !== 'undefined') {
        input.min = min;
      }

      if (typeof max !== 'undefined') {
        input.max = max;
      }
  }
}

function configurePattern(input: HTMLInputElement, options: any) {
  const { pattern, noconfigure } = options;
  if (!noconfigure ) {
    let inputPattern: string | RegExp =   typeof pattern === 'string' ? expressions[pattern] || pattern : pattern;
    if (inputPattern instanceof RegExp) {
      inputPattern = inputPattern.toString();
      inputPattern = inputPattern.substring(1, inputPattern.length - 1);
    }
    if (inputPattern) {
      input.pattern = inputPattern;
    }
  }
}

function configureLength(input: HTMLInputElement, options: any) {
  const { max, min, restrict, noconfigure } = options;
  if (!noconfigure) {
    if (typeof max !== 'undefined' && restrict) {
      input.maxLength = max;
    }
    if (typeof min !== 'undefined') {
      input.minLength = min;
    }
  }
}
