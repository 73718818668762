import devConsole from "../../utilities/devConsole";
import { htmlEncode } from "../../utilities/helpers";
import { PaginationComponent } from "./PaginationComponent";

/**
 * Returns an array of page numbers to render as pagination items including placeholders for gaps in the page list.
 * Works with these properties: page, totalPages, fixedFirstPages, fixedLastPages, maxItems, roomForPageControls
 * @param pagination The pagination component
 * @returns an array pf pages numbers to render with -1 representing placeholders
 */
export function getPageNumberList(pagination: PaginationComponent): number[] {
  const { config, roomForPageControls } = pagination;
  const { page, totalPages } = config;
  const minItems = 5; 
  const maxItems = Math.max(Math.min(config.maxItems, roomForPageControls), minItems);
  const pageListSize = totalPages > 0 ? Math.min(maxItems, totalPages) : maxItems;
  const halfOfListSize = Math.floor((pageListSize - 1) / 2);
  let startingPage = Math.max((page - halfOfListSize), 1);
  let endingPage = startingPage + pageListSize - 1;
  if (totalPages > 0 && endingPage > totalPages) {
    endingPage = totalPages;
    startingPage = Math.max((totalPages - pageListSize + 1), 1);
  }

  //build array with startingPage to endingPage
  const pages: number[] = []
  for (let i = startingPage; i <= endingPage; i++) {
    pages.push(i);
  }

  // if list does not start at 1 replace beginning of list with fixed 1st page and placeholder
  if (pages[0] !== 1) {
    pages[0] = 1;
    pages[1] = -1; //use -1 to indicate placeholder
  }

  // if list does end with lastPage, or lastPage is unknown replace end of list with fixed last page and placeholder
  if (totalPages < 1) {
    pages[pages.length - 1] = -1;  //use -1 to indicate placeholder
  } else if (pages[pages.length - 1] !== totalPages) {
    pages[pages.length - 1] = totalPages;
    pages[pages.length - 2] = -1; //use -1 to indicate placeholder
  }

  return pages;
}

/**
 * Derives an accessible label for pagination: it's label property and a description in parenthesis  
 * to indicate current page and total pages if known. Interpolates '{page}' to page number and '{total}' 
 * to total pages or "many" if total pages is not known. 
 * @param pagination The pagination component
 * @returns String label
 */
export function paginationLabel(pagination: PaginationComponent): string {
  const { config, translate: t } = pagination;
  const { page, totalPages, label, descriptionTemplate } = config;
  const descrTpl = descriptionTemplate || t('paginationDescr');
  const description = descrTpl.split(/({page}|{total})/)
    .map((s: any) => {
      switch (s) {
        case '{page}':
          return page;
        case '{total}':
          return totalPages > 0 ? totalPages : t("manyText");
        default:
          return s;
      }
    }).join('');
  return htmlEncode(`${label || t('paginationLabel')} (${description})`);
}

/**
 * Derives accessible text for placeholder between to numbers (previous and next)
 * @param previousPage previous page number, defaults to 0
 * @param nextPage next page number, defaults to zero
 * @param translate i18n translation
 * @returns 
 */
export function paginationPlaceholderText(previousPage: number, nextPage: number, translate: Function) {
  const key = previousPage && nextPage ? 'paginationPlaceholderRange' : 'paginationPlaceholderMany';
  const tpl = htmlEncode(translate(key));
  return tpl.split(/({start}|{end})/)
    .map(s => {
      switch (s) {
        case '{start}':
          return (previousPage || 0) + 1;
        case '{end}':
          return nextPage - 1;
        default:
          return s;
      }
    }).join('');
}

/**
 * Derives page link's accessible label. Interpolates '{page}' to the page number. All other
 * text is wraped in an sr-only span element
 * @param page 
 * @param pagination 
 * @param srOnlyClass 
 * @returns 
 */
export function pageLabel(page: number, pagination: PaginationComponent, srOnlyClass: string) {
  const { config, translate: t } = pagination;
  const pageTpl = htmlEncode(config.pageTemplate || t('paginationPageLabel'));
  return pageTpl.split(/({page})/)
    .map((s: any) => {
      switch (s) {
        case '{page}':
          return page;
        default:
          return s.trim() && `<span class="${srOnlyClass}">${s}</span>`;
      }
    }).join('');
}

/**
 * Gets the href link attribute for page using hrefTemplate. If template is a function, calls the function
 * with the page number and action and uses the string returned as the template 
 * @param page 
 * @param pagination 
 * @returns 
 */
export function pageHref(page: number, action: string, pagination: PaginationComponent) {
  const { config, host } = pagination;
  let { hrefTemplate } = config;
  if (!hrefTemplate) {
    devConsole.warn('the property \'href-template\' must be set for the pagination component', host)
    hrefTemplate = '';
  }

  if (typeof hrefTemplate === 'function') {
    hrefTemplate = hrefTemplate(page, action);
  }

  return hrefTemplate.split(/({page})/)
    .map((s: any) => {
      switch (s) {
        case '{page}':
          return page;
        default:
          return s;
      }
    }).join('');
}

/**
 * Derives the previous control's accessible label using config.prevTemplate if set, otherwise the
 * default template from i18n
 * @param page 
 * @param pagination 
 * @returns 
 */
export function previousLabel(page: number, pagination: PaginationComponent) {
  const { config, translate: t } = pagination;
  return paginationText(page, config.prevTemplate || t('paginationPrevLabel'));
}

/**
 * Derives the next control's accessible label using config.nextTemplate if set, otherwise the
 * default template from i18n
 * @param page 
 * @param pagination 
 * @returns 
 */
export function nextLabel(page: number, pagination: PaginationComponent) {
  const { config, translate: t } = pagination;
  return paginationText(page, config.nextTemplate || t('paginationNextLabel'));
}

function paginationText(page: number, tpl: string) {
  return htmlEncode(tpl).split(/({page})/)
    .map((s: any) => {
      switch (s) {
        case '{page}':
          return page;
        default:
          return s;
      }
    }).join('');
}
