import { DataTableComponent } from './DataTableComponent';
import { getExpansionRows, getElementsFromThisTable, inNestedTable } from './dataTableUtils';
import { ROW_CHECKBOX_SELECTOR, ROW_RADIO_SELECTOR} from './dataTableConstants';

export function bindRowSelection(dataTable: DataTableComponent): Function {
  const { root } = dataTable;
  const clickHandler = onRowSelection(dataTable);
  root.addEventListener('click', clickHandler);
  setRowSelectionState(dataTable);
  return function unbindRowSelection() {
    root.removeEventListener('click', clickHandler);
  }
}

function onRowSelection(dataTable: DataTableComponent) {
  return (e: Event) => {
    const target = e.target as HTMLElement;
    if (target.matches('input[type="checkbox"], input[type="radio"]') && target.closest("[data-row-selector='true']") && !inNestedTable(target, dataTable.root)) {
      if (target.closest('thead')) {
        selectAllRows(dataTable, (target as HTMLInputElement).checked);
      }
      if (target.matches('input[type="radio"]')) {
        clearCurrentSelection(dataTable, target);
      }
      setRowSelectionState(dataTable);
    }
  }
}

function selectAllRows(dataTable: DataTableComponent, selectAll: boolean) {
  const root = dataTable.root;
  const checkGroup = getElementsFromThisTable(root, ROW_CHECKBOX_SELECTOR);
  checkGroup.forEach((item: HTMLInputElement) => item.checked = selectAll);
}

function setRowSelectionState(dataTable: DataTableComponent) {
  const { root, config } = dataTable;
  const checkGroup = getElementsFromThisTable(root, [ROW_CHECKBOX_SELECTOR, ROW_RADIO_SELECTOR].join(","));
  let selectedCount = 0;
  checkGroup.forEach((item: HTMLInputElement) => {
    const tr = item.closest('tr') as HTMLElement;
    if (tr) {
      [tr].concat(getExpansionRows(tr)).forEach(row => {
        row.classList[item.checked ? 'add' : 'remove'](config.rowSelectedClass);
      });
    }
    if (item.checked) {
      selectedCount++;
    }
  })
  const selectAllCheck: HTMLInputElement = root.querySelector('thead [data-row-selector="true"] input[type="checkbox"], thead input[type="checkbox"][data-row-selector="true"]');
  if (selectAllCheck && !inNestedTable(selectAllCheck, root)) {
    selectAllCheck.indeterminate = selectedCount && selectedCount !== checkGroup.length;
    selectAllCheck.checked = selectedCount && selectedCount === checkGroup.length;
  }
}

function clearCurrentSelection(dataTable: DataTableComponent, target: HTMLElement) {
  const root = dataTable.root;
  const radioGroup = getElementsFromThisTable(root, ROW_RADIO_SELECTOR);
  radioGroup.forEach((item: HTMLInputElement) => {
    if (item !== target) {
      item.checked = false
    }
  });
}
