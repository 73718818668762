import { store, Store } from "../../utilities/store";

export interface SelectOption {
  value?: string
  name: string,
  item?: any
}

export interface ComboboxState {
  showList: boolean,
  options: SelectOption[],
  selectedOption?: SelectOption;
  activeIndex: number,
  loading: boolean,
  filterText?: string,
  optionsStatus?: string,
  ariaLiveMessage?: string
}

// initialize the pattern state. May have to pass in main element to determine initial state
// Alternatively, this can be implemented in the pattern behavior module if it shares common logic. 
export function createStore() : Store<ComboboxState> {
  return store({
    showList: false,
    options: [],
    loading: false,
    activeIndex: -1
  });
}