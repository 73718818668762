import { DateFormatter, DateFormatterOptions } from "../../formatters/DateFormatter";
import { getLang } from "../../../../utilities/i18n";
import { CellValidationData } from "./CellValidationData";
import { CellValidator } from "./CellValidator";
import { ValidationMessage } from "./validatorsFactory";
import { parseDate, toISODate, configureDates, getDateValidity } from "../../../../utilities/date-utils";

export interface DateCellValidationOptions extends ValidationMessage, DateFormatterOptions {
  min?: string | Date,
  max?: string | Date,
  disabledDates?: string | (string | Date | { from: string | Date, to: string | Date })[],
  convert?: boolean,
  format?: boolean
}

export class DateCellValidator implements CellValidator {
  _options: DateCellValidationOptions;

  constructor(options: DateCellValidationOptions) {
    this._options = options;
  }

  validate(cellData: CellValidationData): CellValidationData {
    const { convert, format, message } = this._options;
    const locale = getLang(cellData.cell);
    const config = configureDates(this._options, locale);
    let newValue = (cellData.newValue === 0 ? 0 : cellData.newValue || '').toString().trim();
    if (newValue === '') {
      return cellData;
    }
    if (typeof cellData.newValue === 'string') {
      const parts = newValue.split('-');
      if (parts.length > 1) {
        let validISO = parts.length === 3;
        validISO = validISO && /^\d{4}$/.test(parts[0] as string);
        validISO = validISO && /^\d{1,2}$/.test(parts[1] as string);
        validISO = validISO && /^\d{1,2}$/.test(parts[2] as string);
        if (validISO) {
          // parseDate should recognize 1 digit numbers but it does not at the moment
          newValue = parts.map((part: string, i: number) => {
            return (i === 0) ? part : `00${part}`.slice(-2);
          }).join('-');
        } else {
          return { ...cellData, errorMessage: message, errorReason: 'badInput' };
        }
      }
    }
    const date = parseDate(newValue, locale);
    const validity = getDateValidity(config, date, date ? toISODate(date) : newValue);
    if (validity.valid) {
      const displayValue = format ? this.format(date, cellData.cell) : cellData.displayValue;
      return { ...cellData, newValue: (convert ? toISODate(date) : cellData.newValue), displayValue };
    }

    const errorReason =
      validity.rangeOverflow ? 'rangeOverflow' :
        validity.rangeUnderflow ? 'rangeUnderflow' :
          validity.unavailable ? 'unavailable' : 'badInput'

    return { ...cellData, errorMessage: message, errorReason };
  }

  format(value: Date, cell: HTMLTableCellElement): string {
    const { preset, weekday, month, day, year } = this._options;
    const formatter = new DateFormatter({ preset, weekday, month, day, year })
    return formatter.format(value, getLang(cell));
  }

  get type() { return 'date'; }
  get options() { return this._options; }

}
