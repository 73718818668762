import { ComboboxComponent } from './ComboboxComponent';
import { EventListeners } from '../../utilities/EventListeners';
import { ComboboxState, SelectOption } from './ComboboxState';
import { normalizeKey, isTypingOrDeleting } from '../../utilities/keyboard';
import debounce from '../../utilities/debounce';
import { translations } from '../../utilities/i18n';
import { getObjectValue } from '../../utilities/objectHelpers';
import devConsole from '../../utilities/devConsole';

interface ComboboxStateUpdate {
  showList?: boolean,
  options?: SelectOption[],
  selectedOption?: SelectOption,
  activeIndex?: number,
  loading?: boolean,
  optionsStatus?: string
}

const ATTR_AUTOCOMPLETE = 'autocomplete'

export function bindCombobox(combobox: ComboboxComponent): { unbind: Function, updateProperty: (property: string, value: any) => void } {
  const { store, host, config } = combobox;
  let lastFilterApplied: string;
  // used as workaround for bug in IE where input event fires on focus/blur when there is a placeholder
  // See onInput()
  let lastInputValue: string;
  let lastCommittedValue: string;
  let ignoreInlineComplete = false;
  let state: ComboboxState;
  let debounceInput: Function;
  let loadingMessageTimeout: any;
  const unsubscribe = store.subscribe(s => state = s);
  const { input, select, datalist } = setupCombobox(combobox);
  lastInputValue = input.value;
  let savedAutocomplete = input.getAttribute(ATTR_AUTOCOMPLETE);

  const t = translations(input).t;
  initializeFromComponentProperties();

  const eventListeners = new EventListeners();
  eventListeners.addListener(input, 'keydown', ifEnabled(onKeydown));
  eventListeners.addListener(input, 'input', ifEnabled(onInput));
  eventListeners.addListener(input, 'paste', ifEnabled(onPaste));
  eventListeners.addListener(input, 'focus', manageInputAutocomplete);
  eventListeners.addListener(input, 'blur', manageInputAutocomplete);
  eventListeners.addListener(host, 'click', ifEnabled(onClick));
  eventListeners.addListener(document, 'click', onClickOutside);

  if (config.autoFocus) {
    setTimeout(input.focus.bind(input), 100);
  }

  // a function that "unbinds". Remove event listeners, unsubscribe from store, etc.
  const unbind = () => {
    unsubscribe();
    eventListeners.removeListeners();
  }

  ///////////////////////////////////////////////
  // apply component values
  // These functions initialize from and apply updates to the component values: 
  // inputValue, selectedValue, - selectedItem

  function initializeFromComponentProperties() {
    const { inputValue, selectedValue, selectedItem } = combobox;
    const initInputValue = inputValue || input.value
    if (initInputValue) {
      applyInputValueProperty(initInputValue);
    }

    const initSelectedValue = selectedValue || (select && select.value)
    if (initSelectedValue) {
      applySelectedValueProperty(initSelectedValue);
    }

    if (selectedItem) {
      applySelectedItemProperty(selectedItem);
    }

    lastCommittedValue = input.value;
  }

  function updateProperty(property: string, value: any) {
    if (!value) {
      input.value = lastInputValue = '';
      if (select) {
        select.value = '';
      }
      updateState({ selectedOption: undefined });
      return;
    }

    switch (property) {
      case 'inputValue':
        applyInputValueProperty(value as string);
        break;

      case 'selectedValue':
        applySelectedValueProperty(value as string);
        break;

      case 'selectedItem':
        applySelectedItemProperty(value);
        break;
    }
    lastInputValue = input.value
  }

  function applyInputValueProperty(value: string) {
    applyFilter(value, (options: SelectOption[]) => {
      const index = getExactMatchIndex(options, value);
      const option = options[index];
      const codeValue = option ? option.value : '';
      input.value = value;
      if (select) {
        select.value = codeValue || '';
      }
      updateState({ selectedOption: option });
    }, false);

  }

  function applySelectedValueProperty(value: string) {
    applyFilter('', (options: SelectOption[]) => {
      const option = options.find(o => o.value == value); // == is purposeful. Want to match string to number if applicable
      input.value = option ? option.name : '';
      const codeValue = option ? option.value : '';
      if (select) {
        select.value = codeValue || '';
      }
      updateState({ selectedOption: option });
    }, false);
  }

  function applySelectedItemProperty(item: any) {
    const { optionValue, optionName } = combobox.config;
    const itemOption = item && toSelectOption(item, optionName, optionValue);
    applyFilter('', (options: SelectOption[]) => {
      const option = options.find(o => {
        if (itemOption.value) {
          return itemOption.value === o.value;
        }
        return itemOption.name === o.name;
      });
      input.value = option ? option.name : '';
      const codeValue = option ? option.value : '';
      if (select) {
        select.value = codeValue || '';
      }
      updateState({ selectedOption: option });
    }, false);
  }

  ///////////////////////////////////////////////
  // event handlers

  function onInput() {
    // this was trigger in IE by bug that fires input event when placeholder disappears on focus
    // or appears on blur
    // https://stackoverflow.com/questions/48138865/ie-10-11-how-to-prevent-the-triggering-of-input-events-on-focus-from-text-inpu
    // If the input's value is not blank, or it is blank and the lastInputValue is not blank, then this is not caused by the placeholder coming and going   
    if (input.value || lastInputValue) {
      lastInputValue = input.value;
      const { nofilter, filterDelay } = config;
      const filter = !nofilter;
      let handler: Function;
      if (filter && filterDelay) {
        handler = debounceInput || (debounceInput = debounce(onInputHandler, filterDelay));
      } else {
        debounceInput = null;
        handler = onInputHandler;
      }
      handler();
    }
  }

  function onInputHandler() {
    const filter = !combobox.config.nofilter;
    if (filter) {
      filterByInputValue();
    } else {
      updateState({ activeIndex: getExactMatchIndex() });
      doInlineComplete();
    }
  }

  function onKeydown(event: KeyboardEvent) {
    const key = normalizeKey(event);

    onTypingKey(event);

    const { showList } = state;
    switch (key) {
      case 'Backspace':
      case 'Delete':
        const { inlineComplete } = combobox.config;
        if (inlineComplete && input.value) {
          ignoreInlineComplete = true;
        }
        return;

      case 'Enter':
      case 'Tab':
        commitCurrentValue();
        if (showList && key === 'Enter') {
          event.preventDefault();
        }
        return;
    }

    if (showList) {
      onKeydownList(event);
    } else {
      onKeydownCombobox(event);
    }
  }

  function onKeydownCombobox(event: KeyboardEvent) {
    const key = normalizeKey(event);
    switch (key) {
      case 'ArrowDown':
        event.preventDefault();
        showOptionsList(event);
        break;
    }
  }

  function onKeydownList(event: KeyboardEvent) {
    const { altKey } = event;
    const { activeIndex, options } = state;
    const key = normalizeKey(event);
    let updateActiveIndex = activeIndex;
    const max = options ? options.length : 0;

    switch (key) {
      case 'ArrowDown':
        if (!altKey) {
          updateActiveIndex = Math.min(activeIndex + 1, max - 1);
          event.preventDefault();
        }
        break;

      case 'ArrowUp':
        if (altKey) {
          selectOption(activeIndex);
        } else if (activeIndex > 0) {
          // not using Math.max() because we don't want ArrowUp to increment -1 to 0
          updateActiveIndex = activeIndex - 1;
        }
        event.preventDefault();
        break;

      case 'Home':
        event.preventDefault();
        updateActiveIndex = 0;
        break;

      case 'End':
        event.preventDefault();
        updateActiveIndex = max - 1;
        break;

      case 'Escape':
        updateInputValue(lastCommittedValue);
        dismissOptionsList();
        event.preventDefault();
        return;
    }

    if (updateActiveIndex !== activeIndex) {
      updateState({ activeIndex: updateActiveIndex });
      const option = state.options[updateActiveIndex];
      if (option) {
        updateInputValue(option.name);
      }
    }
  }

  function onTypingKey(event: KeyboardEvent) {
    if (combobox.config.restrict) {
      const key = normalizeKey(event);
      if (isTypingOrDeleting(event)) {
        const newText = key.length === 1 ? key : '';
        if (!allowPotentialInput(newText, key)) {
          event.preventDefault();
        }
      }
    }
  }

  function onPaste(event: any) {
    if (combobox.config.restrict) {
      let text = (event.clipboardData || (window as any).clipboardData).getData('text');
      if (text) {
        if (!allowPotentialInput(text)) {
          event.preventDefault();
        }
      }
    }
  }

  function manageInputAutocomplete(event: FocusEvent) {
    switch(event.type) {
      case 'focus':
        // prevent Chrome from trying to guess autocomplete by setting to an arbitrary value
        savedAutocomplete = input.getAttribute(ATTR_AUTOCOMPLETE);
        input.setAttribute(ATTR_AUTOCOMPLETE, 'none'); 
        break;
      case 'blur':
        input.setAttribute(ATTR_AUTOCOMPLETE, savedAutocomplete);
        break;
    }
  }

  function allowPotentialInput(newText: string, key?: string): boolean {
    const { datasource, optionName, optionValue } = combobox.config;
    if (typeof datasource === 'function') {
      // we only support 'restrict' when datasource is array or derived 
      return true;
    }
    const { selectionStart, selectionEnd, value } = input;
    const textSelected = selectionEnd > selectionStart;
    const beforeTextEnd = key === 'Backspace' && !textSelected && selectionStart ? selectionStart - 1 : selectionStart;
    const afterTextStart = key === 'Delete' && !textSelected && selectionEnd < value.length - 1 ? selectionEnd + 1 : selectionEnd;
    const beforeText = value.substring(0, beforeTextEnd);
    const afterText = value.substring(afterTextStart);
    const newValue = beforeText + newText + afterText;
    const allOptions = toSelectOptions((datasource as any[]) || deriveOptionsList(), optionName, optionValue);
    const index = getStartsWithIndex(allOptions, newValue.toLowerCase());
    return index > -1;
  }

  function onClick(event: Event) {
    if (state.showList) {
      if (!handleSelectOption(event)) {
        selectOption(state.activeIndex);
      }
    } else {
      showOptionsList(event);
    }
    if (event.target !== input) {
      input.focus();
    }
  }

  function onClickOutside(event: Event) {
    const target = event.target as Element;
    const { host } = combobox;
    const internalClick = target === host || host.contains(target);
    if (!internalClick) {
      commitCurrentValue();
    }
  }

  function handleSelectOption(event: Event): boolean {
    const { optionElements = [] } = combobox;
    const target = event.target as HTMLElement;
    for (let i = 0; i < optionElements.length; i++) {
      if (optionElements[i].contains(target)) {
        selectOption(i);
        return true;
      }
    }
    return false;
  }

  function commitCurrentValue() {
    const { showList, activeIndex } = state;
    const filter = !combobox.config.nofilter;
    if (showList && activeIndex > -1) {
      selectOption(state.activeIndex);
    } else if (input.value !== lastCommittedValue) {
      // update selected value based on current input
      applyFilter(filter ? input.value : '', (options: SelectOption[]) => {
        updateState({ options });
        // update with exact match or no option 
        selectOption(getExactMatchIndex(options, input.value));
      });
    } else if (showList) {
      dismissOptionsList();
    }

  }

  ///////////////////////////////////////////////////////////////////////////////////

  let filterByInputValueToken = 0;
  function filterByInputValue() {
    const filterText = input.value;
    const thisToken = ++filterByInputValueToken;
    applyFilter(filterText, (options: SelectOption[] = []) => {
      // ensures thet we are handling the latest request in case filter is applied by a service call
      if (thisToken === filterByInputValueToken) {
        // possible input changed when not focused due to browser autocomplete. Only show list when focused
        const showList = (options.length > 0 || !!filterText) && input.matches(':focus');
        updateState({ options, showList });
        doInlineComplete();
      }
    });
  }

  function showOptionsList({ type: eventType }: Event) {
    if (!state.showList) {
      const wrapUp = (options: SelectOption[]) => {
        let activeIndex = getExactMatchIndex(options);
        if (eventType === 'keydown') {
          if (activeIndex < 0) {
            activeIndex = Math.max(getStartsWithIndex(options), 0);
          }
          const activeOption = options[activeIndex];
          if (activeOption) {
            updateInputValue(activeOption.name);
          }
        }
        updateState({ showList: true, options, activeIndex });
      }

      const filter = !combobox.config.nofilter;
      const inputValue = input.value.trim();
      const filterBy = filter ? inputValue : '';
      // start by applying the current input value as a filter, if applicable
      applyFilter(filterBy, (options: SelectOption[]) => {
        if (filterBy && (options.length === 0 || getExactMatchIndex(options) > -1)) {
          // if filtering and no options returned, or there is an exact match, then get the full list
          // otherwise, the only way see the full list is by clearing the input.
          applyFilter('', wrapUp);
        } else {
          wrapUp(options)
        }
      });
    }
  }

  function dismissOptionsList() {
    if (state.showList) {
      updateState({ showList: false, activeIndex: -1 });
    }
  }

  function getExactMatchIndex(options = state.options, value = input.value.trim()): number {
    // returns the index of the option that matches exactly (ignoring case) or -1 if not found
    let activeIndex = -1;
    value = value.toLowerCase();

    options.forEach((option: SelectOption, index: number) => {
      const optionText = option.name.toLowerCase().trim();
      if (activeIndex === -1 && optionText === value) {
        activeIndex = index;
      }
    });

    return activeIndex;
  }

  function getStartsWithIndex(options = state.options, value = input.value): number {
    // returns the index of the option that starts with the input value (ignoring case), giving precedence to an exact match.
    let index = getExactMatchIndex(options, value);
    if (index < 0) {
      value = value.toLowerCase();
      options.forEach((option: SelectOption, i: number) => {
        const optionText = option.name.toLowerCase().trim();
        if (index === -1 && optionText.indexOf(value) === 0) {
          index = i;
        }
      });
    }
    return index;
  }

  function applyFilter(filter: string = '', callback: (options: SelectOption[]) => any, showLoading = true) {
    const { datasource, optionName, optionValue } = combobox.config;
    function returnResults(results: any[]) {
      if (loadingMessageTimeout) {
        clearTimeout(loadingMessageTimeout);
        loadingMessageTimeout = undefined;
      }
      callback(toSelectOptions(results, optionName, optionValue) || []);
    }

    lastFilterApplied = filter;
    let datasourceFn = datasource;
    if (typeof datasourceFn !== 'function') {
      const allOptions = datasource ? toSelectOptions(datasource as any[], optionName, optionValue) : deriveOptionsList();
      datasourceFn = simpleOptionsListFilter(allOptions);
    }
    const result = datasourceFn(filter);
    if (result && typeof (result as Promise<any[]>).then === 'function') {
      if (showLoading) {
        if (loadingMessageTimeout) {
          clearTimeout(loadingMessageTimeout);
        }
        loadingMessageTimeout = setTimeout(() => {
          loadingMessageTimeout = undefined;
          updateState({ loading: true });
        }, 300);
      }
      (result as Promise<any[]>)
        .then(returnResults)
        .catch(() => returnResults([]));
    } else {
      returnResults(result as any[]);
    }
  }

  function doInlineComplete() {
    if (ignoreInlineComplete) {
      ignoreInlineComplete = false;
      return;
    }

    const { inlineComplete, nofilter } = combobox.config;
    if (inlineComplete) {
      const options = state.options;

      if (nofilter && (!options || options.length === 0)) {
        // options not loaded yet
        // (not sure if this would be the case now that initilization logic is in place, so)
        /* istanbul ignore next */
        applyFilter('', (options) => {
          updateState({ options });
          doInlineComplete();
        });
        /* istanbul ignore next */
        return;
      }
      const startsWithIndex = getStartsWithIndex();
      const option = state.options && state.options[startsWithIndex];
      if (option) {
        const currSelStart = input.selectionStart;
        updateInputValue(option.name);
        input.setSelectionRange(currSelStart, input.value.length);
        if (startsWithIndex !== state.activeIndex) {
          updateState({ activeIndex: startsWithIndex });
        }
      }
    }
  }

  function selectOption(index: number) {
    const { options } = state;
    const option = options[index];
    if (option) {
      updateInputValue(option.name);
    }
    updateSelectValue(option);
    updateState({ activeIndex: index, showList: false, selectedOption: option });

    if (lastCommittedValue !== input.value) {
      combobox.dispatchEvent('tdsChange', {
        selectedItem: option && (option.item || option),
        value: input.value,
        codeValue: (option && option.value) || ''
      });
      lastCommittedValue = input.value;
    }
  }

  function updateInputValue(value: string) {
    // call this function whenever updating the input value because of user action so we can fire the change event
    if (value !== input.value) {
      // why this nonsense? React overrides input value setter, which, in the end, prevents the synthetic onChange event
      // from firing. Going to the native
      const valueDescriptor = typeof HTMLInputElement !== 'undefined' && Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, "value");
      const valueSetter = valueDescriptor && valueDescriptor.set;
      if (valueSetter) {
        valueSetter.call(input, value);
      } else {
        /* istanbul ignore next */
        input.value = value
      }
      lastInputValue = value;
      dispatchChangeEvent(input);
    }
  }

  function updateSelectValue(option: SelectOption) {
    if (select) {
      const optionValue = (option && option.value) || '';
      // why this nonsense? See updateInputValue above
      const valueDescriptor = typeof HTMLSelectElement !== 'undefined' && Object.getOwnPropertyDescriptor(HTMLSelectElement.prototype, "value");
      const valueSetter = valueDescriptor && valueDescriptor.set;
      if (valueSetter) {
        valueSetter.call(select, optionValue);
      } else {
        /* istanbul ignore next */
        select.value = optionValue
      }
      dispatchChangeEvent(select);
    }
  }

  function dispatchChangeEvent(element: HTMLElement) {
    let event: Event;
    /* istanbul ignore else */
    if (typeof Event === 'function') {
      event = new Event('change', { bubbles: true, cancelable: false });
    } else {
      event = document.createEvent('Event');
      event.initEvent('change', true, false);
    }
    element.dispatchEvent(event);
  }

  function updateState(newState: ComboboxStateUpdate) {
    const { restrict } = combobox.config;
    newState = { ...newState };

    if (newState.loading) {
      newState.options = [];
      newState.activeIndex = -1;
    }
    else if (typeof newState.options !== 'undefined') {
      newState.loading = false;
    }

    if (typeof newState.activeIndex === 'undefined' && newState.options) {
      newState.activeIndex = getExactMatchIndex(newState.options);
    }

    // not sure if this would ever be called now
    if (restrict && newState.activeIndex === -1 && input.value.trim()) {
      // always ensure a row is selected
      const options = (typeof newState.options === 'undefined' ? state.options : newState.options) || [];
      if (options.length) {
        newState.activeIndex = 0;
      }
    }
    store.update((s) => {
      return deriveStatusMessages({ ...s, filterText: lastFilterApplied, ...newState })
    });
  }

  // derives other state values based on state values passed here
  function deriveStatusMessages(state: ComboboxState): ComboboxState {
    const { options, loading, filterText } = state;
    const { loadingText, noOptionsText } = combobox.config;
    const optionCount = (options && options.length) || 0;

    let ariaLiveMessage = '';
    let optionsStatus = '';
    let showList = state.showList;

    if (loading) {
      ariaLiveMessage = loadingText || t('optionsLoadingMessage');
      optionsStatus = ariaLiveMessage;
      showList = true;
    } else if (filterText && optionCount === 0 && noOptionsText !== '' && noOptionsText !== 'false') {
      optionsStatus = noOptionsText || t('noOptionsMessage');
      if (optionsStatus) {
        // convert "{value}" to the the input's value
        optionsStatus = optionsStatus.replace(/\{value\}/g, input.value)
      }
    }

    if (showList && !loading) {
      const count = (options && options.length) || 0;
      const msg = count === 0 ? optionsStatus : count === 1 ? t('hasOneOptionMessage') : t('hasOptionsMessage', `${count}`)
      ariaLiveMessage = msg;
    }

    return { ...state, showList, optionsStatus, ariaLiveMessage }
  }

  function ifEnabled(handler: (event: Event) => any) {
    return (event: Event) => {
      const { host } = combobox;
      const hostEnabled = host.getAttribute('aria-disabled') !== 'true';
      const inputEnabled = !input.disabled && input.getAttribute('aria-disabled') !== 'true' && !input.readOnly;
      if (hostEnabled && inputEnabled) {
        handler(event);
      } else if (event.type === 'keydown' && isTypingOrDeleting(event as KeyboardEvent)) {
        event.preventDefault();
      }
    }
  }

  function deriveOptionsList(): SelectOption[] {
    const optionsParent = select || datalist;
    if (optionsParent) {
      const isSelect = optionsParent.matches('select');
      const options = Array.from(optionsParent.querySelectorAll('option'));
      return options
        .filter((option: HTMLOptionElement) => {
          const value = option.value || '';
          return isSelect ? !!value.trim() : true
        })
        .map((option: HTMLOptionElement) => {
          return {
            name: option.textContent.trim() || option.value,
            value: option.value
          }
        });
    }
    return [];
  }


  return {
    unbind,
    updateProperty
  };
}

function setupCombobox(combobox: ComboboxComponent) {
  const { host, config } = combobox;
  const select = host.querySelector('select') as HTMLSelectElement;
  if (select) {
    select.setAttribute('aria-hidden', 'true');
  }
  let input = host.querySelector('input') as HTMLInputElement;
  if (!input) {
    const { inputAttributes, setupInput } = config;
    input = document.createElement('input');
    input.setAttribute('type', 'text');
    applyInputAttributes(input, inputAttributes);
    host.appendChild(input);
    if (select) {
      const selectedOption = select.options[select.selectedIndex];
      if (selectedOption && selectedOption.value) {
        input.value = selectedOption.textContent.trim() || selectedOption.value;
      }
    }
    if (typeof setupInput === 'function') {
      setupInput(input);
    }
  } else {
    input.hidden = false;
  }
  const { placeholder, name } = config;
  if (placeholder) {
    input.placeholder = placeholder;
  }
  if (name) {
    input.name = name;
  }

  if (select && !input.placeholder) {
    const noSelectionOption = select.querySelector('option[value=""], option:not([value])');
    if (noSelectionOption) {
      input.placeholder = noSelectionOption.textContent.trim();
    }
  }

  const autoAttributes = [[ATTR_AUTOCOMPLETE, config.autocomplete || 'off'], ['autocapitalize', 'off'], ['autocorrect', 'off'], ['spellcheck', 'false']];
  autoAttributes.forEach(([attr, value]) => {
    if (!input.getAttribute(attr)) {
      input.setAttribute(attr, value);
    }
  });

  setupInputLabel(input, select, combobox);

  const datalist = input.list || host.querySelector('datalist') as HTMLSelectElement;
  input.removeAttribute('list');
  if (datalist) {
    // this is a hack to make Chrome stop rendering the datalist down arrow
    // setting input::-webkit-calendar-picker-indicator in CSS doesn't seem to work
    const parent = datalist.parentNode;
    const sibling = datalist.nextElementSibling;
    parent.removeChild(datalist);
    parent.insertBefore(datalist, sibling);
  }


  return {
    input,
    datalist,
    select
  }
}

let nextId = 0;
function setupInputLabel(input: HTMLInputElement, select: HTMLSelectElement, combobox: ComboboxComponent) {
  const { config, host } = combobox;

  if (input.getAttribute('aria-label') || input.getAttribute('aria-labelledby') || input.closest('label')) {
    return; // already labelled
  }

  if (input.id && input.ownerDocument.querySelector(`label[for="${input.id}"]`)) {
    return; // already labelled
  }

  let label: HTMLElement;
  let ariaLabel: string;
  let ariaLabelledby: string;

  // pull from select element if it exists
  if (select) {
    label = select.id && select.ownerDocument.querySelector(`label[for="${select.id}"]`);
    ariaLabel = select.getAttribute('aria-label');
    ariaLabelledby = select.getAttribute('aria-labelledby');
  }

  // if no label found, check for a label in the component
  label = label || host.querySelector('label');
  if (label) {
    const labelId = label.id || (label.id = `${label.id}__combobox-label-${++nextId}`);
    input.setAttribute('aria-labelledby', labelId);
    return;
  }

  // apply aria-labelledby if it exists

  ariaLabelledby = ariaLabelledby || config.labelledby;
  if (ariaLabelledby) {
    input.setAttribute('aria-labelledby', ariaLabelledby);
    return;
  }

  // apply aria-label if it exists
  ariaLabel = ariaLabel || config.label;
  if (ariaLabel) {
    input.setAttribute('aria-label', ariaLabel);
    return;
  }

  //No label could be determined
  devConsole.error('TDS Combobox not properly label. Either include an explicit label, set aria-label, set aria-labelledby, or provide a properly labelled input or select element')
}

function applyInputAttributes(input: HTMLInputElement, attributes: any) {
  if (typeof attributes === 'string' && /^\{.+\}$/.test(attributes)) {
    attributes = JSON.parse(attributes);
  }
  if (attributes && typeof attributes === 'object') {
    Object.keys(attributes).forEach(key => {
      input.setAttribute(key, attributes[key]);
    })
  }
}

function simpleOptionsListFilter(options: SelectOption[]): (filterBy: string) => SelectOption[] {
  return (filterBy: string): any[] => {
    filterBy = filterBy && filterBy.toLowerCase().trim();
    const ret = options
      .filter((option: SelectOption) => {
        return filterBy ? option.name.toLowerCase().indexOf(filterBy) > -1 : option;
      });
    return ret;
  }
}

function toSelectOptions(options: any[], optionName: string, optionValue: string): SelectOption[] {
  return (options || []).map((option: any) => {
    return toSelectOption(option, optionName, optionValue);
  });
}

function toSelectOption(option: any, optionName: string, optionValue: string): SelectOption {
  if (typeof option === 'string') {
    return { name: option as string };
  }
  if (option.hasOwnProperty('name') && option.hasOwnProperty('value')) {
    return option as SelectOption;
  }
  const name = optionName ? getObjectValue(option, optionName) : null;
  const value = optionValue ? getObjectValue(option, optionValue) : null;
  return { name, value, item: option };
}