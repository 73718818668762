/**
 * Looks for instances of [data-scroll-shadow="true"] to manage the shadow effect
 * For each scroll panel:
 * - Observes changes to the scrollpanel using MutationObserver
 * - on window.resize events and any observed changes to scrollpanel, positions the shadow
 *
 * The shadow effect is shown only when there are not scrollbars, and their is overflow.
 *
 * To determine if scrollbars exists, the scroll panel's clientWidth is compared to its offsetWidth.
 * If they are the same, then there are no scrollbars
 *
 * The tds-scroll-shadow--x classe is added if there is horizontal overflow; otherwise it is removed
 * The tds-scroll-shadow--y classe is added if there is vertical overflow; otherwise it is removed
 *
 */

import { bindScrollShadow } from './scrollShadowBehavior'; 
import { ScrollShadowComponent } from './ScrollShadowComponent';
import { CSS_NS, NAMESPACE } from '../constants';
import onDOMChanges from '../onDOMChanges';
import { createCustomEvent } from '../customEvent';

const SHADOW_EFFECT_CLASSNAME = `${CSS_NS}scroll-shadow`;
const ON_SCROLL_SHADOW_REMOVED_EVENT = `${NAMESPACE}ScrollShadowRemoved`;
const ENHANCED_FLAG = 'enhancedScrollshadow';

class ScrollShadow implements ScrollShadowComponent {
  scrollpanel: HTMLElement;
  unbind: Function;
  constructor(scrollpanel:HTMLElement) {
    scrollpanel.dataset[ENHANCED_FLAG] = 'true';
    this.scrollpanel = scrollpanel;
    this.unbind = bindScrollShadow(this);
    this._checkDestroy = this._checkDestroy.bind(this);
    document.addEventListener(ON_SCROLL_SHADOW_REMOVED_EVENT, this._checkDestroy);
  }

  destroy() { 
    if (this.scrollpanel) {
      delete this.scrollpanel.dataset[ENHANCED_FLAG];
      this.unbind && this.unbind();
      document.removeEventListener(ON_SCROLL_SHADOW_REMOVED_EVENT, this._checkDestroy);
      delete this.scrollpanel;
      delete this.unbind;
    }
  }

  _checkDestroy(e: CustomEvent) {
    const element = e.detail;
    if (element === this.scrollpanel) {
      this.destroy();
    }
  }

  applyShadowEffect(x: boolean, y: boolean ) {
    const scrollpanel = this.scrollpanel;
    scrollpanel.classList[x ? 'add' : 'remove'](`${SHADOW_EFFECT_CLASSNAME}--x`);
    scrollpanel.classList[y ? 'add' : 'remove'](`${SHADOW_EFFECT_CLASSNAME}--y`);
  }
}

onDOMChanges(`[data-scroll-shadow="true"]`,
  function onScrollShadowAdded(scrollpanel:any) {
    if (scrollpanel.dataset[ENHANCED_FLAG] !== 'true') {
      new ScrollShadow(scrollpanel);
    }
  },
  function onScrollShadowRemoved(element: HTMLElement) {
    document.dispatchEvent(createCustomEvent(ON_SCROLL_SHADOW_REMOVED_EVENT, {detail: element}));
  });


export { ScrollShadow }
