import { store, Store } from "../../utilities/store";

export interface ModalState {
  hidden: boolean,
  transitioning: boolean
}

export function createStore() : Store<ModalState> {
  return store({
    hidden: true,
    transitioning: false
  })
}
