import { DataTableComponent } from './DataTableComponent';
import { bindSorting } from './dataTableSorting';
import { bindRowSelection } from './dataTableRowSelection';
import { bindRowExpansion } from './dataTableRowExpansion';

export function bindDataTable(dataTable: DataTableComponent) {
  const unbindSorting = bindSorting(dataTable);
  const unbindRowSelection = bindRowSelection(dataTable);
  const unbindRowExpansion = bindRowExpansion(dataTable);
  const unbind = () => {
    unbindSorting();
    unbindRowSelection();
    unbindRowExpansion();
  }
  return unbind;
}

export { sortTable } from './dataTableSorting';
