// import { CSS_NS } from '../../shared/constants'
import onDOMChanges from '../../utilities/onDOMChanges';
import { bindAlert, close, DISMISS_CLASS, DISMISS_DATA_ATTR } from './alertBehavior';
import { AlertComponent } from './AlertComponent';
import { AlertState, createStore } from './AlertState';
import { instances } from '../../utilities/instances';
import { Store } from "../../utilities/store";
import { createCustomEvent } from '../../utilities/customEvent';
import { CSS_NS, NAMESPACE } from '../../utilities/constants';

const ENHANCED_FLAG = 'enhancedAlert';
const ENHANCED_DATA_FLAG = 'data-enhanced-alert';
const INSTANCE_KEY = `${NAMESPACE}Alert`


class _AlertInstance implements AlertComponent {
  host: HTMLElement;
  root: HTMLElement;
  store: Store<AlertState>;

  onDestroy: Function[] = [];

  constructor(element: HTMLElement) {
    this.root = this.host = element;
    this.store = createStore();
    element.dataset[ENHANCED_FLAG] = "true";
    const unbind = bindAlert(this);
    this.onStateUpdate = this.onStateUpdate.bind(this);
    const unsubscribe = this.store.subscribe(this.onStateUpdate);
    this.onDestroy = [unbind, unsubscribe];
    instances.set(element, INSTANCE_KEY, this);

    const links: HTMLElement[] = Array.from(element.querySelectorAll(`a.${DISMISS_CLASS}, a${DISMISS_DATA_ATTR}`));
    const buttons: HTMLElement[] = Array.from(element.querySelectorAll(`.${DISMISS_CLASS}, ${DISMISS_DATA_ATTR}`));

    buttons.forEach((button: HTMLElement) => button.hidden = false);
    links.forEach((link: HTMLElement) => link.hidden = true);
  }

  close(val?: string) {
    close(this, val);
  }

  onStateUpdate(state: AlertState) {
    const { root } = this;
    if (state.hidden) {
      const fadeOutCls = `${CSS_NS}alert--fade-out`;
      if (state.transitioning) {
        root.classList.add(fadeOutCls);
      } else {
        root.classList.remove(fadeOutCls);
        root.hidden = true;
      }
    }
  }
  dispatchEvent(eventType: string, eventInit?: any): boolean {
    return this.host.dispatchEvent(createCustomEvent(eventType, eventInit));
  }

  destroy() {
    if (this.root) {
      while (this.onDestroy && this.onDestroy.length) {
        const fn = this.onDestroy.pop();
        fn();
      }
      delete this.root.dataset[ENHANCED_FLAG];
      instances.remove(this.host, INSTANCE_KEY);
      this.root = this.host = this.store = null;
    }
  }
}

class Alert {
  _instance: _AlertInstance;
  constructor(element: HTMLElement) {
    this._instance = <_AlertInstance>instances.get(element, INSTANCE_KEY) || new _AlertInstance(element);
  }

  // public
  close(val: string) {
    return this._instance.close(val);
  }

  destroy() {
    return this._instance.destroy();
  }
}

onDOMChanges(`.${DISMISS_CLASS}, ${DISMISS_DATA_ATTR}`, // <= watch for buttons that close alerts 
  function onDismissableAlertAdded(element: HTMLElement) {
    // find the alert parent of element 
    const ALERT_SELECTOR = `[class*="${CSS_NS}alert-"], [class*="${CSS_NS}inline-alert-"]`;
    const alert: HTMLElement = element.closest(ALERT_SELECTOR)
    if (alert && !alert.dataset[ENHANCED_FLAG]) {
      new Alert(alert)
    }
  }
);

onDOMChanges(`[${ENHANCED_DATA_FLAG}]`,
  null,
  function onAlertRemoved(element: HTMLElement) {
    new Alert(element).destroy();
  }
);

export { Alert }