import onDOMChanges from '../../utilities/onDOMChanges';
import { bindNavigation } from './navigationBehavior';
import { NavigationComponent } from './NavigationComponent';
import { instances } from '../../utilities/instances';
import { NAMESPACE, CSS_NS } from '../../utilities/constants';
import { ToggleIcon } from '../../utilities/toggle-icon/ToggleIcon';

const ENHANCED_FLAG = 'enhancedNavigation';
const INSTANCE_KEY = `${NAMESPACE}Navigation`;
const baseClassName = `${CSS_NS}nav`
const NAVIGATION_SELECTOR = `.${baseClassName}`;
const NAV_CLASSES = {
  nav: baseClassName,
  navList: `${baseClassName}__list`,
  navDropdown: `${baseClassName}__dropdown`,
  navLink: `${baseClassName}__link`,
  navItem: `${baseClassName}__item`,
  navItemText: `${baseClassName}__item-text`,
  navToggle: `${baseClassName}__toggle`,
  navToggleIcon: `${baseClassName}__toggle-icon`,
  navToggleExpandedText: `${baseClassName}__toggle--expanded`,
  navToggleCollapsedText: `${baseClassName}__toggle--collapsed`
}

let nextId = 0;

class _NavigationInstance implements NavigationComponent {
  host: HTMLElement;
  root: HTMLElement;
  unbind: Function;

  constructor(element: HTMLElement) {
    this.root = this.host = element;
    element.dataset[ENHANCED_FLAG] = "true";
    this.unbind = bindNavigation(this);
    enhance(element);
    instances.set(element, INSTANCE_KEY, this);
  }

  get topToggle(): HTMLElement {
    return <HTMLElement>Array.from(this.root.childNodes)
      .find(node => node.nodeType === Node.ELEMENT_NODE && (node as Element).matches('button[aria-expanded]'))
  }

  getToggleElement(event: MouseEvent): HTMLElement {
    return (<HTMLElement>event.target).closest('button[aria-expanded]');
  }

  isToggleExpanded(toggle: HTMLElement): boolean {
    return toggle.getAttribute('aria-expanded') === 'true';
  }

  setToggleExpanded(toggle: HTMLElement, expanded: boolean) {
    toggle.setAttribute('aria-expanded', `${expanded}`);
  }

  isTopLevelDropdownToggle(toggle: HTMLElement): boolean {
    if (toggle === this.topToggle) {
      const navGroup = getNavGroup(toggle);
      return navGroup.matches(`.${NAV_CLASSES.navDropdown}`) &&
        navGroup.parentNode === this.host;
    }
    return false;
  }

  getNavList(toggle: HTMLElement): HTMLElement {
    const navGroup = getNavGroup(toggle);
    const navListSelector = `.${NAV_CLASSES.navList}`;
    return navGroup && (navGroup.matches(navListSelector) ? navGroup : navGroup.querySelector(navListSelector));
  }

  destroy() {
    if (this.unbind) {
      this.unbind();
    }
    if (this.root) {
      delete this.root.dataset[ENHANCED_FLAG];
    }
    instances.remove(this.host, INSTANCE_KEY);
    this.root = this.host = null;
  }
}

function enhance(element: HTMLElement) {
  // find each "nav group" controlled by a nav toggle. A nav group could be a sub nav-list, or a 
  // dropdown that contains a list. Here, the logic assumes the element that immediately 
  // follows nav toggle
  const buttonToggleSelector = `button.${NAV_CLASSES.navToggle}`
  const labelToggleSelector = `label.${NAV_CLASSES.navToggle}`
  const notAToggleElement = `*:not(.${NAV_CLASSES.navToggle}):not([type="checkbox"])`;
  const navGroupsSelector = `${buttonToggleSelector} + ${notAToggleElement}, ${labelToggleSelector} + ${notAToggleElement}`;

  const toggledNavGroups = Array.from(element.querySelectorAll(navGroupsSelector))
  toggledNavGroups.forEach(navGroup => {
    let checkbox: HTMLInputElement, label: HTMLElement, button: HTMLElement;
    const navGroupParent = navGroup.parentNode;
    const siblings = navGroupParent.children;
    for (let i = 0; i < siblings.length; i++) {
      const node = siblings[i]
      if (node.matches(buttonToggleSelector)) {
        button = <HTMLElement>node;
      }
      else if (node.matches('[type="checkbox"]')) {
        checkbox = <HTMLInputElement>node;
      }
      else if (node.matches(labelToggleSelector)) {
        label = <HTMLElement>node;
      }
      else if (node === navGroup) {
        break;
      }
    }

    if (checkbox) {
      navGroupParent.removeChild(checkbox);
    }

    if (label) {
      navGroupParent.removeChild(label);
    }

    if (!button && checkbox) {
      button = createToggleButton(label);
      navGroupParent.insertBefore(button, navGroup);
    }

    if (button) {
      button.hidden = false;
      if (!button.getAttribute('aria-expanded')) {
        button.setAttribute('aria-expanded', `${!!checkbox && checkbox.checked}`);
      }

      if (!button.getAttribute('aria-controls')) {
        if (!navGroup.id) {
          navGroup.id = `${NAV_CLASSES.nav}-group_${++nextId}`;
        }
        button.setAttribute('aria-controls', navGroup.id);
      }
    }
  })
}

function createToggleButton(label: HTMLElement) {
  const button = document.createElement('button');
  button.innerHTML = label.innerHTML;
  const nodes = Array.from(button.querySelectorAll(`.${NAV_CLASSES.navToggleExpandedText}, .${NAV_CLASSES.navToggleCollapsedText}`));
  nodes.forEach(node => node.parentNode.removeChild(node));
  button.className = label.className
  if (label.getAttribute('aria-label')) {
    button.setAttribute('aria-label', label.getAttribute('aria-label'));
  }
  return button;
}

function getNavGroup(toggle: HTMLElement): HTMLElement {
  const navGroupId = toggle.getAttribute('aria-controls');
  return navGroupId && document.getElementById(navGroupId);
}

class Navigation {
  _instance: _NavigationInstance;
  constructor(element: HTMLElement) {
    this._instance = <_NavigationInstance>instances.get(element, INSTANCE_KEY) || new _NavigationInstance(element);
  }

  // public
  // create other proxy methods to the instance

  destroy() {
    return this._instance.destroy();
  }
}

onDOMChanges(`${NAVIGATION_SELECTOR}`,
  function onNavigationAdded(element: HTMLElement) {
    if (!element.dataset[ENHANCED_FLAG]) {
      new Navigation(element);
    }
  },
  function onNavigationRemoved(element: HTMLElement) {
    if (element.dataset[ENHANCED_FLAG] === "true") {
      new Navigation(element).destroy();
    }
  }
);

onDOMChanges(`.${NAV_CLASSES.navLink}, .${NAV_CLASSES.navToggle}:first-child, .${NAV_CLASSES.navItem} > .${NAV_CLASSES.navItemText}`,
  function onNavItemTextAdded(element: HTMLElement) {
    if (!element.dataset.fixedWidth && element.innerText) {
      element.dataset.fixedWidth = element.innerText
    }
  }
);

ToggleIcon.autoEnhance(`.${NAV_CLASSES.navToggleIcon}`);

export { Navigation }