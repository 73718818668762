import { expressions } from "../editable/validators/patterns";

export type NumberFormatterOptions = {
  decimals?: number,
  currency?: 'USD',
  noseparator?: boolean
}

const defaultOptions: NumberFormatterOptions = {
  decimals: 0
}
export class NumberFormatter {
  options: NumberFormatterOptions;

  constructor(options: NumberFormatterOptions = {}) {
    this.options = {...defaultOptions, ...options};
  }

  format(value: number|string, locale?: string) {
    if (!value && value !== 0) {
      return '';
    }
    const {decimals, currency, noseparator} = this.options
    const formatOptions: any = {
      useGrouping: !noseparator,
      maximumFractionDigits: decimals || 0,
      minimumFractionDigits: decimals || 0
    };

    if (currency) {
      formatOptions.style = 'currency';
      formatOptions.currency = currency;
    }

    if (typeof value === 'string') {
      const rx = expressions.decimal;
      if (!rx.test(value)) {
        return value;
      }
      value = parseFloat(value);
    }
    return new Intl.NumberFormat(locale, formatOptions).format(value)
  }
}